// core components
import UserHeader from "components/Headers/UserHeader.js";
import React from "react";
import Geocode from "react-geocode";
import "react-html5video/dist/styles.css";
import SweetAlert2 from "react-sweetalert2";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import Api from "shared/resources/Api";
import api from "shared/resources/Api";
import { neighborhood } from "shared/services/PlacesService";
import { workersByTeam } from "shared/services/WorkerService";

import { cepMask } from "shared/services/Mask";
import { viaCep } from "shared/services/ViaCep";
import InputNumber from "react-input-just-numbers";
import moment from "moment";
import gconf from "shared/resources/Gconf";
import { equipes } from "shared/services/PlacesService";

class ItineraryEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      bairro: "",
      response: "",
      api: api.baseURL,
      messageList: [],
      chatCount: 0,
      counter: 0,
      requesting: false,
      isSuccess: false,
      quarteirao: 0,
      tratamento: "",
      equipe: "",
      equipes: [],
      neighborhood: [],
      workers: [],
      productions: [],
      selectedNeighborhood: "",
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      lat: "",
      lon: "",
      itineraryId: 0,
      selectedWorker: [],
      selectedBairro: [],
      selectedStatus: [],
      selectedProducao: [],
      selectedQt: [],
      selectedClosed: [],
      selectedProductionId: [],
      selectedButtonLabel: [],
      selectedClassLabel: [],
    };

    this.handleSave = this.handleSave.bind(this);
  }

  componentWillMount() {
    if (
      this.props.location != null &&
      this.props.location.state != null &&
      this.props.location.state.selectedItinerary != null
    ) {
      this.setState(
        {
          selectedItinerary: this.props.location.state.selectedItinerary,
          itineraryId: this.props.location.state.selectedItinerary.id,
          loading: false,
        },
        () => this.getProductionsByItineraryId()
      );
    } else {
    }
    neighborhood().then((res) => {
      if (res) {
        this.setState({
          neighborhood: res,
        });
      }
    });
    equipes().then((res) => {
      if (res) {
        this.setState({
          equipes: res,
        });
      }
    });
  }

  fetchEquipes = async () => {
    const response = await fetch(Api.baseURL + "/unidade_saude/equipe", {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        sectors: body,
      });
    }

    return body;
  };

  consultarCep = () => {
    if (this.state.cep && this.state.cep.replace(/\D/g, "").length === 8) {
      viaCep(this.state.cep)
        .then((res) => {
          if (res && !res.erro) {
            const idx = this.state.neighborhood.findIndex(
              (p) => p.nome === res.bairro
            );
            this.setState({
              ...this.state,
              bairro: res.bairro,
              logradouro: res.logradouro,
              selectedNeighborhood:
                idx > -1
                  ? this.state.neighborhood[idx].id
                  : this.state.selectedNeighborhood,
            });
            this.consultarLatLong();
          } else {
            this.setState({
              swal: {
                show: true,
                title: "Atenção",
                text: "Nenhum Endereço localizado.",
                type: "warning",
              },
            });
          }
        })
        .catch((err) => {
          this.setState({
            swal: {
              show: true,
              title: "Atenção",
              text: "Nenhum Endereço localizado.",
              type: "warning",
            },
          });
          console.log(err);
        });
    }
  };

  handleSave(event) {
    if (this.validateItineraryFields()) {
      this.callSaveItinerary();
    }
  }

  validateItineraryFields() {
    /* Validação obrigatoriedade */

    if (this.state.cep === undefined || this.state.cep === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o CEP.",
          type: "warning",
        },
      });

      return false;
    }

    if (
      this.state.cep === undefined ||
      this.state.cep.replace(/\D/g, "").length !== 8
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "CEP inválido",
          type: "warning",
        },
      });

      return false;
    }

    if (this.state.logradouro === undefined || this.state.logradouro === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Logradouro.",
          type: "warning",
        },
      });

      return false;
    }

    if (
      this.state.selectedNeighborhood === undefined ||
      this.state.selectedNeighborhood === ""
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Bairro.",
          type: "warning",
        },
      });

      return false;
    }

    if (this.state.numero === undefined || this.state.numero === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Número.",
          type: "warning",
        },
      });

      return false;
    }

    if (this.state.equipe === undefined || this.state.equipe === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe a Equipe.",
          type: "warning",
        },
      });

      return false;
    }

    if (this.state.tratamento === undefined || this.state.tratamento === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Tratamento.",
          type: "warning",
        },
      });

      return false;
    }

    return true;
  }

  validateProductionFields(index, actionName, userId) {
    /* Validação obrigatoriedade */

    if (
      actionName === "aberto" &&
      (this.state.selectedStatus[index] === undefined ||
        this.state.selectedStatus[index] === "")
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Status da Produção.",
          type: "warning",
        },
      });

      return false;
    }

    if (
      actionName === "aberto" &&
      this.state.selectedStatus[index] === "compareceu" &&
      (this.state.selectedBairro[index] === undefined ||
        this.state.selectedBairro[index] === "")
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Bairro da Produção.",
          type: "warning",
        },
      });

      return false;
    }

    if (
      actionName === "aberto" &&
      this.state.selectedStatus[index] === "compareceu" &&
      (this.state.selectedQt[index] === undefined ||
        this.state.selectedQt[index] === "")
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Quarteirão da Produção.",
          type: "warning",
        },
      });

      return false;
    }

    if (
      this.state.selectedStatus[index] === "compareceu" &&
      (this.state.selectedBairro[index] === undefined ||
        this.state.selectedBairro[index] === "")
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Bairro da Produção.",
          type: "warning",
        },
      });

      return false;
    }

    if (
      this.state.selectedStatus[index] === "compareceu" &&
      (this.state.selectedQt[index] === undefined ||
        this.state.selectedQt[index] === "" ||
        this.state.selectedQt[index] === 0)
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Quarteirão.",
          type: "warning",
        },
      });

      return false;
    }

    if (
      this.state.productions.find((p) => p.usuario === userId).estado !==
        "pendente" &&
      (this.state.selectedClosed[index] === undefined ||
        this.state.selectedClosed[index] === "")
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Fechamento.",
          type: "warning",
        },
      });

      return false;
    }

    if (
      this.state.productions.find((p) => p.usuario === userId).estado !==
        "pendente" &&
      (this.state.selectedProducao[index] === undefined ||
        this.state.selectedProducao[index] === "" ||
        this.state.selectedProducao[index] === 0)
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o valor da Produção.",
          type: "warning",
        },
      });

      return false;
    }

    if (
      this.state.productions.find((p) => p.usuario === userId).estado !==
        "pendente" &&
      this.state.selectedProducao[index] !== undefined &&
      this.state.selectedProducao[index] > 100
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "O Valor da Produção não pode ser maior do que 100.",
          type: "warning",
        },
      });

      return false;
    }

    return true;
  }

  createItineraryBody = () => {
    return JSON.stringify({
      equipe: this.state.equipe,
      tratamento: this.state.tratamento,
      geolocalizacao: {
        lat: this.state.lat,
        lon: this.state.lng,
      },
      bairro: this.state.selectedNeighborhood,
    });
  };

  getReverseGeocoding = () => {
    Geocode.fromLatLng(this.state.lat, this.state.lng).then(
      (response) => {
        var cep = "";
        if (
          response.results !== undefined &&
          response.results.length > 0 &&
          this.state.neighborhood.length > 0 &&
          response.results[0].address_components.length > 0
        ) {
          response.results[0].address_components.forEach((item) => {
            if (
              item.types.find((p) => p.includes("postal_code")) !== undefined
            ) {
              cep = item.short_name;
              console.log(cep);
            }
          });

          if (cep !== "") {
            this.setState(
              {
                requesting: false,
                cep: cep,
              },
              () => this.consultarCep()
            );
          }
        } else {
          this.setState({
            swal: {
              show: true,
              title: "Atenção",
              text: "Não foi possível obter sua localização. Informe o endereço manualmente.",
              type: "warning",
            },
            requesting: false,
          });
        }
      },
      (error) => {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Não foi possível obter sua localização. Informe o endereço manualmente.",
            type: "warning",
          },
          requesting: false,
        });
      }
    );
  };

  getIdBairroByName = () => {
    Geocode.setApiKey(gconf.key);
    this.setState({ requesting: true });

    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    const success = (position) => {
      this.setState({ requesting: true });
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      this.setState(
        {
          lat: latitude,
          lng: longitude,
        },
        () => this.getReverseGeocoding()
      );
    };

    function error() {
      this.setState({
        swal: {
          show: true,
          title: "Erro",
          text: "Não foi possível obter sua localização. Selecione um bairro na lista.",
          type: "error",
        },
        requesting: false,
      });
    }

    navigator.geolocation.getCurrentPosition(success, error, options);

    return 2;
  };

  getProductionsByItineraryId = async () => {
    try {
      const response = await fetch(
        api.baseURL + "/producao?itinerario=" + this.state.itineraryId,
        {
          headers: {
            Authorization: localStorage.getItem("agToken"),
          },
        }
      );
      const body = await response.json();

      if (response.status === 200) {
        this.setState({
          requesting: false,
          productions: body,
        });
        workersByTeam(this.state.selectedItinerary.equipe).then((res) => {
          if (res) {
            this.setState(
              {
                workers: res,
              },
              () => {
                console.log(res, "WORKERS");
              }
            );
          }
        });
      } else {
        this.setState({
          requesting: false,
        });
      }
    } catch (err) {
      // not jumping in here.
      console.log(err);
      this.setState({
        swal: {
          show: true,
          title: "Erro",
          text: "Erro ao consultar Produções para este Itinerário",
          type: "error",
        },
        selectedOs: null,
        requesting: false,
      });
    }
  };

  callSaveItinerary = async () => {
    try {
      const response = await fetch(api.baseURL + "/itinerario", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("agToken"),
        },
        body: JSON.stringify({
          tratamento: this.state.tratamento,
          equipe: this.state.equipe,
          cod_bairro: this.state.selectedNeighborhood,
          geolocalizacao: {
            lat: this.state.lat.toString(),
            lon: this.state.lon.toString(),
          },
          endereco: {
            cep: this.state.cep,
            logradouro: this.state.logradouro,
            bairro: this.state.bairro,
            numero: this.state.numero,
            complemento: this.state.complemento,
          },
        }),
      });
      const body = await response.json();

      if (response.status === 200) {
        this.setState({
          swal: {
            show: true,
            title: "Tudo Certo",
            text: "Itinerário cadastrado com sucesso! Preencha os dados de abertura da Produção.",
            type: "success",
          },
          requesting: false,
          isSuccess: true,
          itineraryId: body.id,
        });
      } else {
        this.setState({
          swal: {
            show: true,
            title: "Erro",
            text: body.msg,
            type: "error",
          },
          selectedOs: null,
          requesting: false,
        });
      }
    } catch (err) {
      // not jumping in here.
      console.log(err);
      this.setState({
        swal: {
          show: true,
          title: "Erro",
          text: "Erro ao cadastrar Itinerário",
          type: "error",
        },
        selectedOs: null,
        requesting: false,
      });
    }
  };

  callSaveProduction = async (index, userId, actionName) => {
    try {

        const response2 = await fetch(
        api.baseURL +
          "/unidade_saude/itinerario" ,
          
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("agToken"),
          },
          body: JSON.stringify({
            
            itinerario: this.state.itineraryId,
          }),
        }
      );





      const response = await fetch(
        api.baseURL +
          "/producao/" +
          this.state.productions.find((p) => p.usuario === userId).id,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("agToken"),
          },
          body: JSON.stringify({
            cod_bairro:
              this.state.selectedStatus[index] === "compareceu"
                ? this.state.selectedBairro[index]
                : 0,
            usuario: this.state.workers[index].id,
            itinerario: this.state.itineraryId,
            producao: this.state.selectedProducao[index],
            fechado: this.state.selectedClosed[index],
            estado: actionName,
            status: this.state.selectedStatus[index],
            quarteirao:
              this.state.selectedQt[index] !== undefined &&
              this.state.selectedQt[index] !== null &&
              this.state.selectedQt[index] !== "" &&
              this.state.selectedStatus[index] === "compareceu"
                ? this.state.selectedQt[index]
                : null,
          }),
        }
      );

    
      const body = await response.json();

      if (response.status === 200) {
        const productions = await this.getProductionsByItineraryId();
        console.log(productions);
        this.setState({
          swal: {
            show: true,
            title: "Tudo Certo",
            text: "Produção enviada com sucesso.",
            type: "success",
          },
          requesting: false,
        });
      } else {
        this.setState({
          swal: {
            show: true,
            title: "Erro",
            text: body.msg,
            type: "error",
          },
          selectedOs: null,
          requesting: false,
        });
      }
    } catch (err) {
      // not jumping in here.
      console.log(err);
      this.setState({
        swal: {
          show: true,
          title: "Erro",
          text: "Erro ao cadastrar Produção",
          type: "error",
        },
        selectedOs: null,
        requesting: false,
      });
    }
  };

  handleSaveProducao = (index, userId, actionName) => {
    if (this.validateProductionFields(index, actionName, userId)) {
      this.callSaveProduction(index, userId, actionName);
    }
  };

  handleTratamento(event) {
    this.setState({ tratamento: event.target.value });
  }

  handleProductionWorkerArray = (index, newValue) => {
    const updatedArray = [...this.state.selectedWorker];
    updatedArray[index] = newValue;
    this.setState({
      selectedWorker: updatedArray,
    });
  };

  handleProductionBairroArray = (index, newValue) => {
    const updatedArray = [...this.state.selectedBairro];
    updatedArray[index] = newValue;
    this.setState({
      selectedBairro: updatedArray,
    });
  };

  handleProductionStatusArray = (index, newValue) => {
    const updatedArray = [...this.state.selectedStatus];
    updatedArray[index] = newValue;
    this.setState({
      selectedStatus: updatedArray,
    });
  };

  handleProductionArray = (index, newValue, userId) => {
    const updatedArray = [...this.state.selectedProducao];
    updatedArray[index] = newValue;

    this.setState({
      selectedProducao: updatedArray,
    });
  };

  handleProductionQtArray = (index, newValue, team) => {
    console.log("");
    if (
      this.state.productions.find(
        (p) => p.usuario === team.id && p.quarteirao != null
      )
    ) {
    }
    const updatedArray = [...this.state.selectedQt];
    updatedArray[index] = newValue;
    this.setState({
      selectedQt: updatedArray,
    });
  };

  handleProductionClosedArray = (index, newValue) => {
    const updatedArray = [...this.state.selectedClosed];
    updatedArray[index] = newValue;
    this.setState({
      selectedClosed: updatedArray,
    });
  };

  handleProductionSuccessArray = (index, newValue) => {
    const updatedArray = [...this.state.selectedProductionSuccess];
    updatedArray[index] = newValue;
    this.setState(
      {
        selectedProductionSuccess: updatedArray,
        swal: {
          show: true,
          title: "Tudo Certo",
          text: "Produção cadastrada com sucesso.",
          type: "success",
        },
        requesting: false,
      },
      () => console.log(this.state.selectedProductionSuccess, "array sucesso")
    );
  };

  handleEquipe(event) {
    var equipe = event.target.value;
    workersByTeam(event.target.value).then((res) => {
      if (res) {
        this.setState(
          {
            workers: res,
            equipe: equipe,
          },
          () => {
            console.log(res, "WORKERS");
          }
        );
      }
    });
  }

  handleActionName(team, index) {
    let estado = this.state.productions.find(
      (p) => p.usuario === team.id
    )?.estado;
    let actionName = "aberto";
    switch (estado) {
      case "pendente":
        actionName = "aberto";
        break;
      case "fechado":
        actionName = "fechado";
        break;
      case "aberto":
        actionName = "Fechar";
        break;
      default:
        break;
    }
    return actionName;
  }

  handleButtonLabel(team, index) {
    let estado = this.state.productions.find(
      (p) => p.usuario === team.id
    )?.estado;
    let label = "Criar";
    switch (estado) {
      case "pendente":
        label = "Enviar";
        break;
      case "fechado":
        label = "Fechado";
        break;
      case "aberto":
        label = "Fechar";
        break;
      default:
        break;
    }
    return label;
  }

  handleButtonLabelArray = (index, newValue) => {
    console.log("");
    const updatedArray = [...this.state.selectedButtonLabel];
    updatedArray[index] = newValue;
    this.setState({
      selectedButtonLabel: updatedArray,
    });
  };

  handleClassLabel(team, index) {
    let estado = this.state.productions.find(
      (p) => p.usuario === team.id
    )?.estado;
    let className = "btn-zoo-alt";
    switch (estado) {
      case "pendente":
        className = "btn-zoo-war";
        break;
      case "fechado":
        className = "btn-zoo-suc";
        break;
      case "aberto":
        className = "btn-zoo-alt";
        break;
      default:
        break;
    }
    // this.handleButtonClassArray(index, className);
    return className;
  }

  handleButtonClassArray = (index, newValue) => {
    const updatedArray = [...this.state.selectedClassLabel];
    updatedArray[index] = newValue;
    this.setState({
      selectedClassLabel: updatedArray,
    });
  };

  blurEndereco = () => {
    this.consultarLatLong();
  };

  consultarLatLong = () => {
    this.setState({ isMapOk: false });
    const addres = `${this.state.logradouro}, ${this.state.numero}, ${this.state.bairro}, ${this.state.cep}`;
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${addres}&key=${gconf.key}`
    )
      .then((response) => response.json())
      .then((res) => {
        if (res && res.status === "OK") {
          console.log(res);
          if (
            res.results.length > 0 &&
            res.results[0].geometry &&
            res.results[0].geometry.location
          ) {
            this.setState({
              ...this.state,
              lat: res.results[0].geometry.location.lat,
              lon: res.results[0].geometry.location.lng,
              isMapOk: true,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          lat: -18.90971,
          lon: -48.261921,
          isMapOk: true,
        });
      });
  };

  renderInputs(value) {
    const inputs = [];

    this.state.workers.map((team, index) =>
      inputs.push(
        <>
          <Row>
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-address">
                  Servidor: {team.nome}
                </label>
              </FormGroup>
            </Col>
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-address">
                Situação Funcional: {
            (() => {
              if (!team.situacao_funcional) {
                return "Ativo na Função";
              }
              switch (team.situacao_funcional.toLowerCase()) {
                case 'abandono':
                  return 'Abandono';
                case 'afastado':
                  return 'Atestado Médico';
                case 'ativo_em_funcao':
                  return 'Ativo na função';
                case 'cedido':
                  return 'Cedido-Outra unidade PMU';
                case 'convocacao_juri_popular':
                  return 'Convocação - Juri Popular';
                case 'desligado':
                  return 'Desligado';
                case 'ferias':
                  return 'Férias';
                case 'justica_eleitoral':
                  return 'Justiça Eleitoral';
                case 'licenca_interesses_pessoais':
                  return 'Licença Interesses Pessoais';
                case 'licenca_maternidade':
                  return 'Licença Maternidade';
                case 'readaptado':
                  return 'Readaptado';
                case 'ativo':
                  return 'Ativo na Função';
                default:
                  return "-";
              }
            })()
          }
                </label>
              </FormGroup>

              {team.situacao_funcional && team.situacao_funcional.trim().toLowerCase() !== 'ativo' && team.fim_inatividade !== null && team.fim_inatividade.trim() !== '' && (
              <FormGroup>
                <label className="form-control-label" htmlFor="input-address">
                  Data Final de Inatividade: {team.fim_inatividade}
                </label>
              </FormGroup>
            )}

            </Col>
          </Row>
          <Row>
            <Col lg="2">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-username">
                  Status
                </label>

                <select
                  value={
                    this.state.productions.find((p) => p.usuario === team.id)
                      ? this.state.productions.find(
                          (p) => p.usuario === team.id
                        ).status
                      : this.state.selectedStatus[index]
                  }
                  disabled={
                    String(team.situacao_funcional).toLowerCase() === "afastado" 
                    || String(team.situacao_funcional).toLowerCase() === "desligado"
                  || String(team.situacao_funcional).toLowerCase() === "licenca_maternidade"
                  || String(team.situacao_funcional).toLowerCase() === "licenca_interesses_pessoais"
                  || String(team.situacao_funcional).toLowerCase() === "ferias"
                  || String(team.situacao_funcional).toLowerCase() === "abandono"
                  || String(team.situacao_funcional).toLowerCase() === "justica_eleitoral"
                    ||
                    (this.state.productions.find((p) => p.usuario === team.id && p.status != null))
                }
                  onChange={(e) =>
                    this.handleProductionStatusArray(index, e.target.value)
                  }
                  className="form-control "
                  id="select-tipo"
                >
                  <option value="">Selecione...</option>
                  <option value="atestado">Atestado</option>
                  <option value="check_in">Check In</option>
                  <option value="compareceu">Compareceu</option>
                  <option value="faltou">Faltou</option>
                  <option value="ferias">Ferias</option>
                </select>
              </FormGroup>
            </Col>
            <Col lg="2">
              <FormGroup>
                <h5 style={{ marginTop: 10 }} className="mb-0">
                  Bairro
                </h5>
                <select
                  value={
                    this.state.productions.find(
                      (p) => p.usuario === team.id && p.bairro_setor != null
                    )
                      ? this.state.productions.find(
                          (p) => p.usuario === team.id
                        ).bairro_setor.cod_bairro
                      : this.state.selectedBairro[index]
                  }
                  disabled={
                    this.state.productions.find(
                      (p) => p.usuario === team.id && p.bairro_setor != null
                    ) || this.state.selectedStatus[index] !== "compareceu"
                  }
                  onChange={(e) =>
                    this.handleProductionBairroArray(index, e.target.value)
                  }
                  className="form-control "
                >
                  <option value="">Selecione...</option>

                  {this.state.neighborhood.map((bairro) => (
                    <option
                      key={bairro.id}
                      value={bairro.id}
                      defaultValue={this.state.selectedBairro}
                    >
                      {bairro.nome}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </Col>
            <Col lg="2">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-address">
                  Quarteirão
                </label>
                <Input
                  className="form-control"
                  id="input-qt"
                  type="quarteirao"
                  maxLength="25"
                  autoComplete="new-qt"
                  required
                  disabled={
                    this.state.productions.find(
                      (p) => p.usuario === team.id && p.quarteirao != null
                    ) || this.state.selectedStatus[index] !== "compareceu"
                  }
                  value={
                    this.state.productions.find(
                      (p) => p.usuario === team.id && p.quarteirao != null
                    )
                      ? this.state.productions.find(
                          (p) => p.usuario === team.id
                        ).quarteirao
                      : this.state.selectedQt[index]
                  }
                  onChange={(e) =>
                    this.handleProductionQtArray(index, e.target.value, team.id)
                  }
                />
              </FormGroup>
            </Col>
            <Col lg="2">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-username">
                  Fechado
                </label>

                <select
                  value={
                    this.state.productions.find(
                      (p) => p.usuario === team.id && p.estado === "fechado"
                    )
                      ? this.state.productions.find(
                          (p) => p.usuario === team.id
                        ).fechado
                      : this.state.selectedClosed[index]
                  }
                  disabled={
                    this.state.productions.find((p) => p.usuario === team.id)
                      ?.estado !== "aberto"
                  }
                  onChange={(e) =>
                    this.handleProductionClosedArray(index, e.target.value)
                  }
                  className="form-control "
                  id="select-fechado"
                >
                  <option value="">Selecione...</option>
                  <option value="nao">Não</option>
                  <option value="sim">Sim</option>
                </select>
              </FormGroup>
            </Col>
            <Col lg="2">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-prod">
                  Produção
                </label>
                <InputNumber
                  onlydigits
                  className="form-control"
                  id="input-producao"
                  disabled={
                    this.state.productions.find((p) => p.usuario === team.id)
                      ?.estado !== "aberto"
                  }
                  inputMode="numeric"
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 3);
                  }}
                  placeholder=""
                  value={
                    this.state.productions.find(
                      (p) => p.usuario === team.id && p.producao != null
                    )
                      ? this.state.productions.find(
                          (p) => p.usuario === team.id
                        ).producao
                      : this.state.selectedProducao[index]
                  }
                  onChange={(e) =>
                    this.handleProductionArray(index, e.target.value)
                  }
                />
              </FormGroup>
            </Col>

            <Col lg="2" style={{ paddingTop: "2.1%" }}>
              <FormGroup>
                <Button
                  className={this.handleClassLabel(team)}
                  color="primary"
                  type="button"
                  disabled={
                    String(team.situacao_funcional).toLowerCase() === "afastado" 
                  || String(team.situacao_funcional).toLowerCase() === "desligado"
                  || String(team.situacao_funcional).toLowerCase() === "licenca_maternidade"
                  || String(team.situacao_funcional).toLowerCase() === "licenca_interesses_pessoais"
                  || String(team.situacao_funcional).toLowerCase() === "ferias"
                  || String(team.situacao_funcional).toLowerCase() === "abandono"
                  || String(team.situacao_funcional).toLowerCase() === "justica_eleitoral"
                }
                  onClick={() => {
                    this.handleSaveProducao(
                      index,
                      team.id,
                      this.state.productions.find((p) => p.usuario === team.id)
                        .estado === "pendente"
                        ? "aberto"
                        : "fechado"
                    );
                  }}
                >
                  {this.handleButtonLabel(team)}
                </Button>
              </FormGroup>
            </Col>
          </Row>
          <hr className="my-4" />
        </>
      )
    );
    return inputs;
  }

  render() {
    return (
      <>
        <UserHeader />
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });
          }}
        />
        {/* Page content */}
        <Container className="main-zoo" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  {this.state.requesting && (
                    <div className="text-center">
                      <Row className="align-items-center">
                        <Col xs="12">
                          <Spinner
                            style={{ width: "3rem", height: "3rem" }}
                            color="primary"
                          />
                        </Col>
                      </Row>
                    </div>
                  )}
                  {!this.state.requesting && (
                    <>
                      <Row className="align-items-center">
                        <Col xs="3">
                          <div className="text-left">
                            <h3 className="mb-0">
                              Itinerário: {this.state.selectedItinerary.id}{" "}
                            </h3>
                            <h4 className="mb-0">
                              Data:{" "}
                              {moment(
                                this.state.selectedItinerary.data_abertura,
                                "YYYY-MM-DD"
                              ).format("DD/MM/YYYY")}{" "}
                            </h4>
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center  mt-2">
                        <Col xs="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              CEP
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-cep"
                              disabled
                              type="text"
                              maxLength={9}
                              inputMode="numeric"
                              required
                              value={this.state.selectedItinerary.cep}
                              onChange={(e) =>
                                this.setState({ cep: cepMask(e.target.value) })
                              }
                              onBlur={() => this.consultarCep()}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="8">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Logradouro
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-endereco"
                              type="text"
                              disabled
                              maxLength={100}
                              required
                              value={this.state.selectedItinerary.logradouro}
                              onChange={(e) =>
                                this.setState({ logradouro: e.target.value })
                              }
                              onBlur={() => this.blurEndereco()}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xs="4">
                          <FormGroup>
                            <h5 style={{ marginTop: 10 }} className="mb-0">
                              Localidade
                            </h5>
                            <select
                              value={
                                this.state.selectedItinerary
                                  .cod_bairro
                              }
                              disabled
                              onChange={(e) =>
                                this.setState({
                                  selectedNeighborhood: e.target.value,
                                })
                              }
                              className="form-control "
                            >
                              <option value="">Selecione...</option>

                              {this.state.neighborhood.map((team) => (
                                <option
                                  key={team.id}
                                  value={team.id}
                                  defaultValue={this.state.selectedNeighborhood}
                                >
                                  {team.nome}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col xs="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Número
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-num"
                              disabled
                              type="text"
                              required
                              maxLength={8}
                              value={this.state.selectedItinerary.numero}
                              onChange={(e) =>
                                this.setState({ numero: e.target.value })
                              }
                              onBlur={() => this.blurEndereco()}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Complemento
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-comp"
                              disabled
                              type="text"
                              maxLength={50}
                              required
                              value={this.state.selectedItinerary.complemento}
                              onChange={(e) =>
                                this.setState({ complemento: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xs="6">
                          {this.state.equipes.length > 0 && (
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Equipe
                            </label>

                            <select
                              value={this.state.selectedItinerary.equipe}
                              disabled
                              onChange={(e) => {
                                this.handleEquipe(e);
                              }}
                              className="form-control "
                              id="select-tipo"
                            >
                              <option value="">Selecione...</option>
                              {this.state.equipes.map((team) => (
                                    <option key={team.nm_equipe} value={team.nm_equipe}>
                                    {team.ds_descricao_equipe.charAt(0).toUpperCase() +
                                      team.ds_descricao_equipe.slice(1).toLowerCase()}
                                  </option>
                                  ))}
                            </select>
                          </FormGroup>
                           )}
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Tratamento
                            </label>

                            <select
                              value={this.state.selectedItinerary.tratamento}
                              disabled
                              onChange={(e) => {
                                this.handleTratamento(e);
                              }}
                              className="form-control "
                              id="select-tipo"
                            >
                              <option value="">Selecione...</option>
                              <option value="primeiro">1º - Tratamento</option>
                              <option value="segundo">2º - Tratamento</option>
                              <option value="terceiro">3º - Tratamento</option>
                              <option value="quarto">4º - Tratamento</option>
                              <option value="quinto">5º - Tratamento</option>
                              <option value="sexto">6º - Tratamento</option>
                              <option value="lia_pesquisa">LIA - Pesquisa</option>
                              <option value="liraa">LIRAa - Pesquisa</option>
                              <option value="monitoramento">Monitoramento</option>
                              <option value="tpve">TPVE - Tratamento</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  )}
                </CardHeader>
                {this.state.itineraryId > 0 && (
                  <CardBody>
                    <Form>
                      <div>
                        <hr className="my-4" />
                        {/* Address */}
                        <h3 className="mb-4">Produção</h3>
                        <>
                          {this.state.workers.length > 0 &&
                            this.state.itineraryId > 0 &&
                            this.renderInputs(4)}
                          {this.state.workers.length === 0 &&
                            this.state.itineraryId > 0 && (
                              <Row className="align-items-center">
                                <Col xs="12">
                                  <div className="text-center">
                                    <Spinner
                                      style={{ width: "3rem", height: "3rem" }}
                                      color="primary"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            )}
                        </>
                      </div>
                      {/* 
                    <hr className="my-4" /> */}

                      {/* <div className="text-center">
                      <Button
                        className="mt-4 btn-zoo"
                        color="primary"
                        type="button"
                        onClick={this.handleSave}
                      >
                        Salvar Produção
                      </Button>
                    </div> */}
                    </Form>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ItineraryEdit;
