import React from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import SweetAlert2 from "react-sweetalert2";
import InputMask from "react-input-mask";
import moment from "moment";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";

import Util from "shared/services/Util";
import { cpfMask } from "shared/services/Mask";
import Api from "shared/resources/Api";
import InputNumber from "react-input-just-numbers";
import { equipes } from "shared/services/PlacesService";

import Header from "components/Headers/Header.js";

export default class AgentEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cpf: "",
      nome: "",
      matricula: "",
      email: "",
      cel: "",
      senha: "",
      senha2: "",
      data_inicio: "",
      data_fim: "",
      ds_inatividade: "",
      logradouro: "",
      numero: "",
      bairro: "",
      complemento: "",
      situacao_funcional: "",
      habilidades_especificas: "",
      formacao: null,
      vinculo: null,
      habilitacao: null,
      tipo: "",
      equipes: [],
      equipe: null,
      api: Api.baseURL,
      isEdicao: false,
      formTitle: "CADASTRAR",
      maskedCpf: "",
    };

    this.handleSave = this.handleSave.bind(this);
  }

  componentWillMount() {
    if (
      this.props.location != null &&
      this.props.location.state != null &&
      this.props.location.state.selectedAgent != null
    ) {
      this.setState(
        {
          selectedAgent: this.props.location.state.selectedAgent,
          agent: this.props.location.state.os,
          loading: false,
          cpf: cpfMask(this.props.location.state.selectedAgent.cpf),
          nome: this.props.location.state.selectedAgent.nome,
          data_inicio: this.props.location.state.selectedAgent.inicio_inatividade,
          data_fim: this.props.location.state.selectedAgent.fim_inatividade,
          ds_inatividade: this.props.location.state.selectedAgent.descricao_inatividade,
          tipo: this.props.location.state.selectedAgent.tipo,
          cel: this.props.location.state.selectedAgent.cel,
          matricula: this.props.location.state.selectedAgent.matricula,
          equipe: this.props.location.state.selectedAgent.equipe,
          formacao: this.props.location.state.selectedAgent.formacao,
          habilitacao: this.props.location.state.selectedAgent.habilitacao,
          vinculo: this.props.location.state.selectedAgent.vinculo,
          habilidades_especificas: this.props.location.state.selectedAgent.habilidades_especificas,
          situacao_funcional: this.props.location.state.selectedAgent.situacao_funcional,
          logradouro: this.props.location.state.selectedAgent.endereco
            .logradouro,
          bairro: this.props.location.state.selectedAgent.endereco.bairro,
          numero: this.props.location.state.selectedAgent.endereco.numero,
          complemento: this.props.location.state.selectedAgent.endereco
            .complemento,
          email: this.props.location.state.selectedAgent.email,
          isEdicao: true,
          formTitle: "EDITAR",
          maskedCpf:
            this.props.location.state.selectedAgent.cpf
              .replace(/\D/g, "")
              .substring(0, 3) +
            "." +
            this.props.location.state.selectedAgent.cpf
              .replace(/\D/g, "")
              .substring(3, 9)
              .replace(/^.{6}/g, "XXX.XXX") +
            "-" +
            this.props.location.state.selectedAgent.cpf
              .replace(/\D/g, "")
              .slice(-2)
              .replace(/^.{2}/g, "XX"),
        },
        () => console.log("state logou end")
      );
    }
    equipes().then((res) => {
      if (res) {
        this.setState({
          equipes: res,
        });
      }
    });
  }

  fetchEquipes = async () => {
    const response = await fetch(Api.baseURL + "/unidade_saude/equipe", {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        sectors: body,
      });
    }

    return body;
  };


  handleSave(event) {
    if (this.validateFields()) {
      this.callSave();
    }
  }

  handleInputChange = (e) => {
    // Use a regular expression to remove non-numeric characters
    const numericValue = e.target.value.replace(/\D/, "");
    // numericValue = numericValue.slice(0, 6);
    
    
    // Update the state with the numeric value
    this.setState({ matricula: numericValue });
  };



  validateFields() {
    /* Validação obrigatoriedade */
    if (this.state.nome === undefined || this.state.nome.length === 0) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Nome Usuário.",
          type: "warning",
        },
      });

      return false;
    }
    if (
      this.state.cpf === undefined ||
      this.state.cpf.replace(/\D/g, "").length === 0
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o CPF.",
          type: "warning",
        },
      });

      return false;
    }
    if (
      this.state.cel === undefined ||
      this.state.cel.replace(/\D/g, "").length === 0
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Celular.",
          type: "warning",
        },
      });

      return false;
    }

    if (
      !this.state.matricula ||
      this.state.matricula === "" ||
      this.state.matricula.replace(/\D/g, "").length === 0
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe a Matrícula.",
          type: "warning",
        },
      });

      return false;
    }

    if (this.state.tipo === undefined || this.state.tipo.length === 0) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe a Função.",
          type: "warning",
        },
      });

      return false;
    }

    if (this.state.equipe === undefined || this.state.equipe === null) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe a equipe.",
          type: "warning",
        },
      });

      return false;
    }

    if (
      this.state.email === undefined ||
      this.state.email === null
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o email.",
          type: "warning",
        },
      });

      return false;
    }

    if (this.state.formacao === undefined || this.state.formacao === null) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe a formacao.",
          type: "warning",
        },
      });

      return false;
    }

    if (this.state.habilitacao === undefined || this.state.habilitacao === null ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe a habilitacao.",
          type: "warning",
        },
      });

      return false;
    }

    if (this.state.situacao_funcional === undefined || this.state.situacao_funcional === null) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe a Situação funcional.",
          type: "warning",
        },
      });

      return false;
    }

    if (this.state.vinculo === undefined || this.state.vinculo === null ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o vinculo.",
          type: "warning",
        },
      });

      return false;
    }


    if (
      this.state.habilidades_especificas === undefined || this.state.habilidades_especificas === null ||
      this.state.habilidades_especificas === ""
     
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe pelo menos 1 Habilidade Específica.",
          type: "warning",
        },
      });

      return false;
    }

    if (
      this.state.logradouro === undefined ||
      this.state.logradouro === ""
      
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o logradouro no Endereço.",
          type: "warning",
        },
      });

      return false;
    }

    if (
      this.state.numero === undefined ||
      this.state.numero === ""
      
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o numero no Endereço.",
          type: "warning",
        },
      });

      return false;
    }

    if (
      this.state.bairro === undefined ||
      this.state.bairro === ""
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o bairro no Endereço.",
          type: "warning",
        },
      });

      return false;
    }


    /* Validação obrigatoriedade */

    /* Validação integridade */
    if (
      this.state.cpf !== "" &&
      !Util.validaCPF(this.state.cpf.replace(/\D/g, ""))
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "CPF inválido",
          type: "warning",
        },
      });

      return false;
    }
    if (
      this.state.email &&
      this.state.email !== "" &&
      !Util.validateEmail(this.state.email)
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "E-mail inválido",
          type: "warning",
        },
      });

      return false;
    }
    if (
      this.state.cel !== "" &&
      this.state.cel.replace(/\D/g, "").length !== 11
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Preencha o celular com DDD e nono dígito.",
          type: "warning",
        },
      });

      return false;
    }

    if (!this.state.isEdicao && this.state.senha === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe a Senha.",
          type: "warning",
        },
      });
      return false;
    }
    if (!this.state.isEdicao && this.state.senha2 === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Confirme a Senha.",
          type: "warning",
        },
      });
      return false;
    }

    if (this.state.senha !== "" && this.state.senha2 !== "") {
      if (this.state.senha !== this.state.senha2) {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "As senhas não são iguais.",
            type: "warning",
          },
        });

        return false;
      }
    }
    /* Validação integridade */

    return true;
  }

  handleTipo(event) {
    this.setState({ tipo: event.target.value }, () =>
      console.log(this.state.tipo)
    );
  }

  handleFormacao(event) {
    this.setState({ formacao: event.target.value }, () =>
      console.log(this.state.formacao)
    );
  }

  handleVinculo(event) {
    this.setState({ vinculo: event.target.value }, () =>
      console.log(this.state.vinculo)
    );
  }

  handleFuncional(event) {
    this.setState({ situacao_funcional: event.target.value }, () =>
      console.log(this.state.situacao_funcional)
    );
  }

  handleHabilitacao(event) {
    this.setState({ habilitacao: event.target.value }, () =>
      console.log(this.state.habilitacao)
    );
  }

  handleEquipe(event) {
    console.log(event.target)
    this.setState({ equipe: event.target.value }, () =>
      console.log(this.state.equipe)
    );
  }

  createBody = () => {
    if (this.state.selectedAgent) {
      return JSON.stringify({
        cpf:
          this.state.cpf !== ""
            ? this.state.cpf.replace(/\D/g, "")
            : this.state.selectedAgent.cpf.replace(/\D/g, ""),
        nome:
          this.state.nome !== ""
            ? this.state.nome
            : this.state.selectedAgent.nome,
        equipe:
          this.state.equipe !== ""
            ? this.state.equipe
            : this.state.selectedAgent.equipe,
        matricula:
          this.state.matricula !== ""
            ? this.state.matricula
            : this.state.selectedAgent.matricula,
        email: this.state.email,
        cel:
          this.state.cel !== ""
            ? this.state.cel.replace(/\D/g, "")
            : this.state.selectedAgent.cel.replace(/\D/g, ""),
        tipo:
          this.state.tipo !== ""
            ? this.state.tipo
            : this.state.selectedAgent.tipo,
        situacao_funcional:
          this.state.situacao_funcional !== ""
            ? this.state.situacao_funcional
            : this.state.selectedAgent.situacao_funcional,

        vinculo:
          this.state.vinculo !== ""
            ? this.state.vinculo
            : this.state.selectedAgent.vinculo,

        formacao:
          this.state.formacao !== ""
            ? this.state.formacao
            : this.state.selectedAgent.formacao,

        habilitacao:
          this.state.habilitacao !== ""
            ? this.state.habilitacao
            : this.state.selectedAgent.habilitacao,

        habilidades_especificas:
          this.state.habilidades_especificas !== ""
            ? this.state.habilidades_especificas
            : this.state.selectedAgent.habilidades_especificas,

        endereco: {
          logradouro: this.state.logradouro.trim(),
          bairro: this.state.bairro,
          numero: this.state.numero,
          complemento: this.state.complemento,
          },
        inicio_inatividade:
          this.state.data_inicio !== ""
            ? this.state.data_inicio
            : " ",
          
        fim_inatividade:
          this.state.data_fim !== ""
            ? this.state.data_fim
            : " ",

        descricao_inatividade:
          this.state.ds_inatividade !== ""
            ? this.state.ds_inatividade
            : " ",
        
      });
    } else {
      return JSON.stringify({
        cpf: this.state.cpf.replace(/\D/g, ""),
        nome: this.state.nome.trim(),
        matricula: this.state.matricula,
        email: this.state.email,
        cel: this.state.cel.replace(/\D/g, ""),
        tipo: this.state.tipo,
        equipe: this.state.equipe ? this.state.equipe : null,
        senha: this.state.senha,
        habilidades_especificas: this.state.habilidades_especificas ? this.state.habilidades_especificas : null,
        formacao: this.state.formacao ? this.state.formacao : null,
        vinculo: this.state.vinculo ? this.state.vinculo: null,
        habilitacao: this.state.habilitacao ? this.state.habilitacao : null,
        situacao_funcional: this.state.situacao_funcional ? this.state.situacao_funcional : null,
        endereco: {
          logradouro: this.state.logradouro.trim(),
          bairro: this.state.bairro.trim(),
          numero: this.state.numero,
          complemento: this.state.complemento.trim(),
        },
      });
    }
  };

  callSave = async () => {
    console.log('loggg', this.createBody())
    const response = await fetch(
      Api.baseURL +
      "/usuario/" +
      (this.state.selectedAgent
        ? this.state.selectedAgent.cpf.replace(/\D/g, "")
        : ""),
      {
        method: this.state.selectedAgent ? "PUT" : "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("agToken"),
        },
        body: this.createBody(),
      }
    );

    const body = await response.json();

    if (response.status === 200 && body.sucesso === true) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: this.state.selectedAgent
            ? "Edição realizada com sucesso!"
            : "Cadastro realizado com sucesso!",
          type: "success",
        },
        isSuccess: true,
      });
    } else if (body.sucesso === false) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: body.erro,
          type: "warning",
        },
      });
      console.log(body.erro);
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: this.state.selectedAgent
            ? "Erro ao editar usuário. "
            : "Erro ao cadastrar usuário.",
          type: "warning",
        },
      });
      console.log(body.erro);
    }

    return body;
  };

  render() {
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });
            if (this.state.isSuccess) {
              this.props.history.push("/admin/agent");
            }
          }}
        />
        <Header />
        {/* Page content */}
        <Container className="mb-7 mt-5 " fluid>
          <Row className="mb-3">
            <Col className="order-xl-1">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      {this.state.formTitle} Servidor
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="9">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-nome"
                            >
                              Nome Usuário *
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-nome"
                              type="text"
                              required
                              value={this.state.nome}
                              onChange={(e) =>
                                this.setState({ nome: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-cpf"
                            >
                              CPF *
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-cpf"
                              inputMode="numeric"
                              type="text"
                              required
                              disabled={this.state.isEdicao}
                              value={
                                this.state.isEdicao
                                  ? this.state.maskedCpf
                                  : this.state.cpf
                              }
                              onChange={(e) =>
                                this.setState({ cpf: cpfMask(e.target.value) })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Celular *
                            </label>
                            <InputMask
                              mask="(99) 99999-9999"
                              type="text"
                              name="cel"
                              inputMode="numeric"
                              id="cel"
                              className="form-control"
                              value={this.state.cel}
                              onChange={(e) =>
                                this.setState({ cel: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Matrícula *
                            </label>
                            <Input
                              onlydigits
                              className="form-control"
                              id="input-matricula"
                              inputMode="numeric"
                              // onInput={(e) => {
                              //   e.target.value = Math.max(
                              //     0,
                              //     parseInt(e.target.value)
                              //   )
                              //     .toString()
                              //     .slice(0, 6);
                              // }}
                              placeholder=""
                              value={this.state.matricula}
                              onChange={this.handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Função *
                            </label>

                            <select
                              value={this.state.tipo}
                              onChange={(e) => {
                                this.handleTipo(e);
                              }}
                              className="form-control "
                              id="select-tipo"
                            >
                              <option value="">selecione...</option>
                              <option value="agente">Agente</option>
                              <option value="coordenador">Coordenador</option>
                              <option value="operador">Operador</option>
                              <option value="supervisaoentual">Inserção de Informações</option>
                              <option value="supervisao_area">Supervisor de Área</option>
                              <option value="supervisao_turma">Supervisor de Turma</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          {/* <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Equipe
                            </label>

                            <select
                              value={this.state.equipe}
                              onChange={(e) => {
                                this.handleEquipe(e);
                              }}
                              className="form-control "
                              id="select-tipo"
                            >
                              <option value="">selecione...</option>
                              <option value="equipe_01">Equipe 1</option>
                              <option value="equipe_02">Equipe 2</option>
                              <option value="equipe_03">Equipe 3</option>
                              <option value="equipe_04">Equipe 4</option>
                              <option value="equipe_05">Equipe 5</option>
                              <option value="equipe_06">Equipe 6</option>
                              <option value="equipe_07">Equipe 7</option>
                              <option value="equipe_08">Equipe 8</option>
                              <option value="equipe_09">Equipe 9</option>
                              <option value="equipe_10">Equipe 10</option>
                              <option value="equipe_11">Equipe 11</option>
                              <option value="equipe_12">Equipe 12</option>
                              <option value="equipe_13">Equipe 13</option>
                              <option value="equipe_14">Equipe 14</option>
                              <option value="equipe_15">Equipe 15</option>
                              <option value="equipe_16">Equipe 16</option>
                              <option value="equipe_17">Equipe 17</option>
                              <option value="equipe_18">Equipe 18</option>
                              <option value="equipe_19">Equipe 19</option>
                              <option value="equipe_20">Equipe 20</option>
                              <option value="equipe_21">Equipe 21</option>
                              <option value="equipe_22">Equipe 22</option>
                              <option value="equipe_23">Equipe 23</option>
                              <option value="equipe_24">Equipe 24</option>
                              <option value="equipe_25">Equipe 25</option>
                              <option value="equipe_26">Equipe 26</option>
                              <option value="equipe_27">Equipe 27</option>
                              <option value="equipe_28">Equipe 28</option>
                              <option value="equipe_29">Equipe 29</option>
                              <option value="equipe_30">Equipe 30</option>
                              <option value="acoes">Ações</option>
                              <option value="administrativo">Administrativo</option>
                              <option value="app_drone_mob">App Drone Mob</option>
                              <option value="fog">Fog</option>
                              <option value="material">Material</option>
                              <option value="miraporanga">Miraporanga</option>
                              <option value="tapuirama">Tapuirama</option>
                              <option value="mobilizacao">Mobilização</option>
                              <option value="motorista">Motorista</option>
                              <option value="ovitrampas">Ovitrampas</option>
                              <option value="pe_a">PE_A</option>
                              <option value="pe_a_adm">PE_A_ADM</option>
                              <option value="pe_b">PE_B</option>
                              <option value="pe_b_adm">PE_B_ADM</option>
                              <option value="pe_c">PE_C</option>
                              <option value="pe_c_adm">PE_C_ADM</option>
                              <option value="pe_d">PE_D</option>
                              <option value="pe_d_adm">PE_D_ADM</option>
                              <option value="pe_e">PE_E</option>
                              <option value="pe_e_adm">PE_E_ADM</option>
                              <option value="pe_f">PE_F</option>
                              <option value="pe_f_adm">PE_F_ADM</option>
                              <option value="pe_g">PE_G</option>
                              <option value="pe_g_adm">PE_G_ADM</option>
                              <option value="pedras">Pedras</option>
                              <option value="pneu">Pneu</option>
                              <option value="raio">Raio</option>
                              <option value="supervisao_area">Supervisão Área</option>
                              <option value="supervisao_turma">Supervisão Turma</option>
                              <option value="supervisao_geral">Supervisão Geral</option>
                             
                            </select>
                          </FormGroup> */}
                          <FormGroup>
                            {this.state.equipes.length > 0 && (
                              <div>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-dtnascimento"
                                >
                                  Equipe
                                </label>

                                <select
                                  value={this.state.equipe}
                                  // onChange={(e) =>
                                  //   this.setState({
                                  //     equipe: e.target.value,
                                  //   })
                                  // }
                                  onChange={(e) => {
                                    this.handleEquipe(e);
                                  }}
                                  className="form-control "
                                >
                                  <option value="">Selecione...</option>
                              {this.state.equipes.map((team) => (
                                <option key={team.nm_equipe} value={team.nm_equipe}>
                                  {team.ds_descricao_equipe.charAt(0).toUpperCase() +
                                    team.ds_descricao_equipe.slice(1).toLowerCase()}
                                </option>
                              ))}
                                </select>
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              E-mail
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              type="email"
                              autoComplete="new-email"
                              required
                              value={this.state.email}
                              onChange={(e) =>
                                this.setState({ email: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Formação
                            </label>

                            <select
                              value={this.state.formacao}
                              onChange={(e) => {
                                this.handleFormacao(e);
                              }}
                              className="form-control "
                              id="select-tipo"
                            >
                              <option value="">selecione...</option>
                              <option value="ensino_fundamental">Fundamental</option>
                              <option value="ensino_medio">Médio</option>
                              <option value="tecnico">Técnico</option>
                              <option value="superior">Superior</option>
                              <option value="pos_graduacao">Pós Graduação</option>
                              <option value="mestrado">Mestrado</option>
                              <option value="doutorado">Doutorado</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Habilitação
                            </label>

                            <select
                              value={this.state.habilitacao}
                              onChange={(e) => {
                                this.handleHabilitacao(e);
                              }}
                              className="form-control "
                              id="select-tipo"
                            >
                              <option value="">selecione...</option>
                              <option value="a">A</option>
                              <option value="b">B</option>
                              <option value="c">C</option>
                              <option value="d">D</option>
                              <option value="e">E</option>
                              <option value="ab">AB</option>
                              <option value="ac">AC</option>
                              <option value="ad">AD</option>
                              <option value="ae">AE</option>
                              <option value="nao_possui">Não possui</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Situação Funcional
                            </label>

                            <select
                              value={this.state.situacao_funcional}
                              onChange={(e) => {
                                this.handleFuncional(e);
                              }}
                              className="form-control "
                              id="select-tipo"
                            >
                              <option value="">selecione...</option>
                              <option value="abandono">Abandono</option>
                              <option value="afastado">Atestado Médico</option>
                              <option value="ativo">Ativo na função</option>
                              <option value="cedido">Cedido-Outra unidade PMU</option>
                              <option value="convocacao_juri_popular">Convocação - Juri Popular</option>
                              <option value="desligado">Desligado</option>
                              <option value="ferias">Férias</option>
                              <option value="justica_eleitoral">Justiça Eleitoral</option>
                              <option value="licenca_interesses_pessoais">Licença Interesses Pessoais</option>
                              <option value="licenca_maternidade">Licença Maternidade</option>
                              <option value="readaptado">Readaptado</option>
                              
                            </select>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Vínculo
                            </label>

                            <select
                              value={this.state.vinculo}
                              onChange={(e) => {
                                this.handleVinculo(e);
                              }}
                              className="form-control "
                              id="select-tipo"
                            >
                              <option value="">selecione...</option>
                              <option value="cedido">Cedido</option>
                              <option value="contratado">Contratado</option>
                              <option value="efetivo">Efetivo</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-dtnascimento"
                            >
                              Habilidades Específicas
                                </label>
                            <Input
                              id="input-conteudo"
                              maxLength={100}
                              value={this.state.habilidades_especificas}
                              onChange={(e) =>
                                this.setState({
                                  habilidades_especificas: e.target.value,
                                })
                              }
                              rows="6"
                              type="textarea"
                              className="form-control-alternative"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="5">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-endereco"
                            >
                              Endereço
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-endereco"
                              type="text"
                              required
                              value={this.state.logradouro}
                              onChange={(e) =>
                                this.setState({ logradouro: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-num"
                            >
                              Nº
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-num"
                              type="text"
                              required
                              value={this.state.numero}
                              onChange={(e) =>
                                this.setState({ numero: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-bairro"
                            >
                              Bairro
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-bairro"
                              type="text"
                              required
                              value={this.state.bairro}
                              onChange={(e) =>
                                this.setState({ bairro: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-comp"
                            >
                              Complemento
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-comp"
                              type="text"
                              required
                              value={this.state.complemento}
                              onChange={(e) =>
                                this.setState({ complemento: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                         
                      </Row>
                      {!this.state.isEdicao && (
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-senha"
                              >
                                Senha *
                              </label>

                              <Input
                                className="form-control-alternative"
                                id="input-senha"
                                type="password"
                                autoComplete="new-password"
                                required
                                value={this.state.senha}
                                onChange={(e) =>
                                  this.setState({ senha: e.target.value })
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-senha2"
                              >
                                Repetir Senha *
                              </label>

                              <Input
                                className="form-control-alternative"
                                id="input-senha2"
                                type="password"
                                autoComplete="new-password2"
                                required
                                value={this.state.senha2}
                                onChange={(e) =>
                                  this.setState({ senha2: e.target.value })
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                      {this.state.isEdicao && (
                        <>
                        <h6 className="heading-small text-muted mb-4">
                            Inatividade
                          </h6>

                          <Row>
                        <Col lg="5">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Data Inicial da Inatividade
                            </label>
                            
                             <InputMask
                             mask="99/99/9999"
                             type="text"
                              name="data_abertura_inicio"
                              inputMode="numeric"
                              id="data_abertura_inicio"
                              className="form-control"
                              value={this.state.data_inicio}
                              onChange={(e) =>
                                this.setState({
                                  data_inicio: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="5">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Data Final da Inatividade
                            </label>
                            <InputMask
                              mask="99/99/9999"
                              type="text"
                              name="data_fim"
                              inputMode="numeric"
                              id="data_fim"
                              className="form-control"
                              value={this.state.data_fim}
                              onChange={(e) =>
                                this.setState({
                                  data_fim: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        </Row>
                        <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-dtnascimento"
                            >
                              Descrição da Inatividade
                                </label>
                            <Input
                              id="input-conteudo"
                              maxLength={80}
                              value={this.state.ds_inatividade}
                              onChange={(e) =>
                                this.setState({
                                  ds_inatividade: e.target.value,
                                })
                              }
                              rows="6"
                              type="textarea"
                              className="form-control-alternative"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row></Row>
                        </>
                        )}
                    </div>
                    <div className="text-center">
                      <Row>
                        <Col className="order-xl-1" xl="12">
                          <Button
                            className="mt-4 btn-zoo"
                            color="primary"
                            type="button"
                            onClick={this.handleSave}
                          >
                            <b>
                              {this.state.isEdicao
                                ? "Salvar Alterações"
                                : "Cadastrar"}
                            </b>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
