import React from "react";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Container,
  Row,
  Col,
  Spinner,
  Input
} from "reactstrap";

import SweetAlert2 from "react-sweetalert2";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import InputMask from 'react-input-mask';
import moment from 'moment';
import { equipes } from "shared/services/PlacesService";
import ReactExport from "react-data-export";
import ExcelFile from "../../shared/ExcelPlugin/components/ExcelFile"
import Header from "components/Headers/Header.js";

import Api from "shared/resources/Api";
import { sectors, neighborhood } from "shared/services/PlacesService";
import InputNumber from "react-input-just-numbers";
import Util from "shared/services/Util";

const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export default class ItineraryList extends React.Component {
  /* Dados Grid */
  columns = [
    {
      name: "Itinerário",
      maxWidth: "50px",
      selector: "id",
      sortable: true,
    },
    {
      name: 'Equipe',
      selector: 'equipe',
      sortable: true,
      cell: (row) => (
        <div>
          {row.equipe
            ? Util.snakeToPascal(Util.formatEquipe(row.equipe))
            : '-'}
        </div>
      ),
    },
    {
      name: "Tratamento",
      selector: "tratamento",
      cell: (row) => {
        const tratamentoValue = row.tratamento;
        let displayedText = "";
    
        switch (tratamentoValue.toLowerCase()) {
          case "primeiro":
            displayedText = "1º - Tratamento";
            break;
          case "segundo":
            displayedText = "2º - Tratamento";
            break;
          case "terceiro":
            displayedText = "3º - Tratamento";
            break;
          case "quarto":
            displayedText = "4º - Tratamento";
            break;
          case "quinto":
            displayedText = "5º - Tratamento";
            break;
          case "sexto":
            displayedText = "6º - Tratamento";
            break;
          case "lia_pesquisa":
            displayedText = "LIA - Pesquisa";
            break;
          case "liraa":
            displayedText = "LIRAa - Pesquisa";
            break;
          case "monitoramento":
            displayedText = "Monitoramento";
            break;
          case "tpve":
            displayedText = "TPVE - Tratamento";
            break;
          default:
            displayedText = "-";
            break;
        }
    
        return <div>{displayedText}</div>;
      },
    },
    {
      name: 'Localidade',
      selector: 'bairro',
      sortable: true,
      cell: (row) => (
        <div>
          {row.bairro
            ? row.bairro
            : '-'}
        </div>
      ),
    },
    {
      name: 'Status',
      selector: 'fechado',
      sortable: true,
      cell: (row) => (
        <div>
          {row.fechado.toLowerCase() === 'sim'
            ? 'Fechado'
            : 'Aberto'}
        </div>
      ),
    },
    {
      name: 'Data de Abertura',
      selector: 'data_abertura',
      sortable: true,
      cell: (row) => (
        <div>{moment(moment(row.data_abertura)).format("DD/MM/YYYY")}</div>
      ),
    },
    {
      name: 'Média Produção',
      selector: 'total_producao',
      sortable: true,
      cell: (row) => (
        <div>
        {row.total_producao
          ? row.total_producao
          : '0'}
      </div>
      ),
    },
    {
      name: "",
      center: true,
      cell: (row) => (
        <div>
          <Button
            type="button"
            className="btn-acao "
            title="Editar"
            onClick={() => {
              this.props.history.push("/admin/itinerary-edit", {
                selectedItinerary: row,
              });
            }}
          >
            <FontAwesomeIcon icon={faEdit} className="edit" />
          </Button>
          {localStorage.getItem("perm") === "coordenador" && (
            <Button
              type="button"
              className="btn-acao "
              title="Remover"
              onClick={() => {
                this.handleDelete(row);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="remove" />
            </Button>
          )}
        </div>
      ),
    },
  ];
  defaultComponentOptions = {
    rowsPerPageText: "Registros por Página:",
    rangeSeparatorText: "de",
    noRowsPerPage: false,
  };
  customStyles = {
    headCells: {
      style: {
        fontWeight: 700,
        fontSize: 15,
        color: "#000",
      },
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      response: "",
      id: "",
      equipe: "",
      data_abertura: "",
      data_fechamento: "",
      data_abertura_inicio: moment().subtract(1, 'months').format("DD/MM/YYYY"),
      data_abertura_fim: moment().format("DD/MM/YYYY"),
      tratamento: "",
      api: Api.baseURL,
      requesting: false,
      neighborhood: [],
      sectors: [],
      equipes: [],
      selectedSector: null,
      selectedNeighborhood: null,
      fechado: "",
      status: ""
    };
  }
  componentWillMount() {


    localStorage.setItem("excel", "Itinerario");


    document.addEventListener("keydown", (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        this.handleSubmit(event);
      }
    });

    // if (localStorage.getItem("griditi")) {

    //   var obj = JSON.parse(localStorage.getItem("griditi"));
    //   this.setState({
    //     response: obj,
    //   })
    // }

    neighborhood().then((res) => {
      if (res) {
        this.setState({
          neighborhood: res,
        });
      }
    });

    sectors().then((res) => {
      if (res) {
        this.setState({
          sectors: res,
        });
      }
    });
    equipes().then((res) => {
      if (res) {
        this.setState({
          equipes: res,
        });
      }
    });
  }

  fetchEquipes = async () => {
    const response = await fetch(Api.baseURL + "/unidade_saude/equipe", {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        sectors: body,
      });
    }

    return body;
  };


  fetchSectors = async () => {
    const response = await fetch(Api.baseURL + "/setor", {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        sectors: body,
      });
    }

    return body;
  };



  handleInputChange = (e) => {
    // Use a regular expression to remove non-numeric characters
    const numericValue = e.target.value.replace(/\D/, "");
    // numericValue = numericValue.slice(0, 6);
    
    
    // Update the state with the numeric value
    this.setState({ id: numericValue });
  };


  handleTratamento(event) {
    this.setState({ tratamento: event.target.value })
  }

  fetchNeighborhood = async () => {
    const response = await fetch(Api.baseURL + "/bairro", {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        neighborhood: body,
      });
    }

    return body;
  };

  validate() {
    var dateFormat = 'DD/MM/YYYY';


    if (
      this.state.data_abertura_inicio !== undefined &&
      this.state.data_abertura_inicio !== ''
    ) {
      if (
        !moment(this.state.data_abertura_inicio, dateFormat, true).isValid()
      ) {
        this.setState({
          swal: {
            show: true,
            title: 'Atenção',
            text: 'Data de abertura inicial inválida.',
            type: 'error',
          },
        });
        return false;
      } else {
        this.dataIni = moment(
          moment(this.state.data_abertura_inicio, 'DD/MM/YYYY')
        ).format('YYYY-MM-DD');
      }
    }

    if (
      this.state.data_abertura_fim !== undefined &&
      this.state.data_abertura_fim !== ''
    ) {
      if (!moment(this.state.data_abertura_fim, dateFormat, true).isValid()) {
        this.setState({
          swal: {
            show: true,
            title: 'Atenção',
            text: 'Data de abertura final inválida.',
            type: 'error',
          },
        });
        return false;
      } else {
        this.dataFim = moment(
          moment(this.state.data_abertura_fim, 'DD/MM/YYYY')
        ).format('YYYY-MM-DD');
      }
    }

    if (this.dataIni !== undefined && this.dataFim !== undefined) {
      var date1 = moment(this.state.data_publicacao_inicio, 'DD/MM/YYYY');
      var date2 = moment(this.state.data_publicacao_fim, 'DD/MM/YYYY');
      var diff = date2.diff(date1);

      console.log(diff);
      if (diff < 0) {
        this.setState({
          swal: {
            show: true,
            title: 'Atenção',
            text: 'Data de Início de abertura menor que Data Fim de abertura.',
            type: 'error',
          },
        });
        return false;
      }
    }

    if (this.state.data_abertura_inicio === '') {
      this.dataIni = undefined;
    }

    if (this.state.data_abertura_fim === '') {
      this.dataFim = undefined;
    }



    if (
      this.state.data_fechamento_inicio !== undefined &&
      this.state.data_fechamento_inicio !== ''
    ) {
      if (
        !moment(this.state.data_fechamento_inicio, dateFormat, true).isValid()
      ) {
        this.setState({
          swal: {
            show: true,
            title: 'Atenção',
            text: 'Data de fechamento inicial inválida.',
            type: 'error',
          },
        });
        return false;
      } else {
        this.dataIniFechamento = moment(
          moment(this.state.data_fechamento_inicio, 'DD/MM/YYYY')
        ).format('YYYY-MM-DD');
      }
    }

    if (
      this.state.data_fechamento_fim !== undefined &&
      this.state.data_fechamento_fim !== ''
    ) {
      if (!moment(this.state.data_fechamento_fim, dateFormat, true).isValid()) {
        this.setState({
          swal: {
            show: true,
            title: 'Atenção',
            text: 'Data de fechamento final inválida.',
            type: 'error',
          },
        });
        return false;
      } else {
        this.dataFimFechamento = moment(
          moment(this.state.data_fechamento_fim, 'DD/MM/YYYY')
        ).format('YYYY-MM-DD');
      }
    }

    if (this.dataIniFechamento !== undefined && this.dataFimFechamento !== undefined) {
      var date3 = moment(this.state.data_fechamento_inicio, 'DD/MM/YYYY');
      var date4 = moment(this.state.data_fechamento_fim, 'DD/MM/YYYY');
      var diff2 = date4.diff(date3);

      console.log(diff2);
      if (diff2 < 0) {
        this.setState({
          swal: {
            show: true,
            title: 'Atenção',
            text: 'Data de Fim do fechamento menor que Data Início de fechamento.',
            type: 'error',
          },
        });
        return false;
      }
    }

    if (this.state.data_fechamento_inicio === '') {
      this.dataIniFechamento = undefined;
    }

    if (this.state.data_fechamento_fim === '') {
      this.dataFimFechamento = undefined;
    }

    return true;
  }


  handleSubmit = (event) => {
    if (this.validate()) {
      this.setState({ response: "", requesting: true });

      this.fetchItinerary()
        .then((res) => this.setState({ response: res }, () => {
          localStorage.setItem("griditi", JSON.stringify(res));
        }))
        .catch((err) => {
          console.log(err);
          this.setState({
            swal: {
              show: true,
              title: "Atenção",
              text:
                "Não foram encontrados itinerários para os parâmetros informados.",
              type: "error",
            },
            requesting: false,
          });
        });

      event.preventDefault();
    }
  };

  fetchItinerary = async () => {
    let host = this.state.api + "/itinerario/grid?";

    let params = {
      tratamento: this.state.tratamento,
      id: this.state.id,
      fechado: this.state.status,
      equipe: this.state.equipe,
      data_abertura_inicio: this.dataIni !== undefined ? this.dataIni : '',
      data_abertura_fim: this.dataFim !== undefined ? this.dataFim : '',
      data_fechamento_inicio: this.dataIniFechamento !== undefined ? this.dataIniFechamento : '',
      data_fechamento_fim: this.dataFimFechamento !== undefined ? this.dataFimFechamento : '',
      cod_bairro: this.state.selectedNeighborhood ? this.state.selectedNeighborhood : ""
    };
    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    const response = await fetch(host + query, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    let bytes = new Blob([JSON.stringify(body)]).size
    let megabytes = bytes / (1024 * 1024);

    if (body.length > 0) {
      if (megabytes < 4.5) {

        this.setState({
          agent: body,
          requesting: false,
        });
      } else {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Sua pesquisa trouxe " + body.length + " resultados ultrapassando o limite de consultas, por isso serão apresentados apenas os 1000 primeiros.",
            type: "warning",
          },
        });
        this.setState({
          os: body.slice(0,1000),
          requesting: false,
        });
        return body.slice(0,1000)
      }
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text:
            "Não foram encontrados itinerários para os parâmetros informados.",
          type: "error",
        },
        requesting: false,
      });
    }

    return body;
  };
  handleDelete = (element) => {
    this.setState({
      swal: {
        show: true,
        title: "Atenção",
        text: "Deseja realizar a exclusão deste registro?",
        type: "error",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "Cancelar",
      },
      trieshop: element,
      isConfirmation: true,
    });
  };
  handleDeleteItinerary(d) {
    this.callDel(d)
      .then()
      .catch((err) => console.log(err));
  }

  callDel = async (d) => {
    const response = await fetch(Api.baseURL + "/itinerario/" + d.id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: "Itinerário deletado com sucesso!",
          type: "success",
        },
        response: "",
        tratamento: "",
        status: "",
        situacao: "",
        selectedNeighborhood: "",
        lado: "",
        quarteirao: "",
        id: "",
        equipe: "",
        data_abertura_inicio: moment().subtract(1, 'months').format("DD/MM/YYYY"),
        data_abertura_fim: moment().format("DD/MM/YYYY"),
        data_fechamento_inicio: "",
        data_fechamento_fim: ""
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Erro ao deletar itinerário.",
          type: "error",
        },
      });

      throw Error(body.message);
    }

    return body;
  };

  handleEquipe(event) {
    this.setState({ equipe: event.target.value }, () =>
      console.log(this.state.equipe)
    );
  }

  render() {
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });

            if (this.state.isConfirmation && !result.dismiss) {
              this.setState({
                isConfirmation: false,
              });
              this.handleDeleteItinerary(this.state.trieshop);
            }
          }}
        />

        <Header />
        {/* Page content */}
        <Container className="mb-7 mt-5 " fluid>
          <Row className="mb-3">
            <Col className="order-xl-1">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Consulta Itinerário
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Itinerário
                            </label>
                            <Input
                              onlydigits
                              className="form-control"
                              id="input-id"
                              inputMode="numeric"
                              // onInput={(e) => {
                              //   e.target.value = Math.max(
                              //     0,
                              //     parseInt(e.target.value)
                              //   )
                              //     .toString()
                              //     .slice(0, 6);
                              // }}
                              placeholder=""
                              value={this.state.id}
                              // onChange={(e) =>
                              //   this.setState({ id: e.target.value })
                              // }
                              onChange={this.handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                        <FormGroup>
                            {this.state.equipes.length > 0 && (
                              <div>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-dtnascimento"
                                >
                                  Equipe
                                </label>

                                <select
                                  value={this.state.equipe}
                                  onChange={(e) =>
                                    this.setState({
                                      equipe: e.target.value,
                                    })
                                  }
                                  className="form-control "
                                >
                                  <option defaultValue="" value="">
                                    {" "}
                                    Selecione...{" "}
                                  </option>
                                  {this.state.equipes.map((team) => (
                                    <option key={team.nm_equipe} value={team.nm_equipe}>
                                    {team.ds_descricao_equipe.charAt(0).toUpperCase() +
                                      team.ds_descricao_equipe.slice(1).toLowerCase()}
                                  </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          </FormGroup>
                          {/* <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Equipe
                            </label>

                            <select
                              value={this.state.equipe}
                              onChange={(e) => {
                                this.handleEquipe(e);
                              }}
                              className="form-control "
                              id="select-tipo"
                            >
                              <option value="">selecione...</option>
                              <option value="equipe_01">Equipe 1</option>
                              <option value="equipe_02">Equipe 2</option>
                              <option value="equipe_03">Equipe 3</option>
                              <option value="equipe_04">Equipe 4</option>
                              <option value="equipe_05">Equipe 5</option>
                              <option value="equipe_06">Equipe 6</option>
                              <option value="equipe_07">Equipe 7</option>
                              <option value="equipe_08">Equipe 8</option>
                              <option value="equipe_09">Equipe 9</option>
                              <option value="equipe_10">Equipe 10</option>
                              <option value="equipe_11">Equipe 11</option>
                              <option value="equipe_12">Equipe 12</option>
                              <option value="equipe_13">Equipe 13</option>
                              <option value="equipe_14">Equipe 14</option>
                              <option value="equipe_15">Equipe 15</option>
                              <option value="equipe_16">Equipe 16</option>
                              <option value="equipe_17">Equipe 17</option>
                              <option value="equipe_18">Equipe 18</option>
                              <option value="equipe_19">Equipe 19</option>
                              <option value="equipe_20">Equipe 20</option>
                              <option value="equipe_21">Equipe 21</option>
                              <option value="equipe_22">Equipe 22</option>
                              <option value="equipe_23">Equipe 23</option>
                              <option value="equipe_24">Equipe 24</option>
                              <option value="equipe_25">Equipe 25</option>
                              <option value="equipe_26">Equipe 26</option>
                              <option value="equipe_27">Equipe 27</option>
                              <option value="equipe_28">Equipe 28</option>
                              <option value="equipe_29">Equipe 29</option>
                              <option value="equipe_30">Equipe 30</option>
                              <option value="acoes">Ações</option>
                              <option value="administrativo">Administrativo</option>
                              <option value="app_drone_mob">App Drone Mob</option>
                              <option value="fog">Fog</option>
                              <option value="material">Material</option>
                              <option value="miraporanga">Miraporanga</option>
                              <option value="tapuirama">Tapuirama</option>
                              <option value="mobilizacao">Mobilização</option>
                              <option value="motorista">Motorista</option>
                              <option value="ovitrampas">Ovitrampas</option>
                              <option value="pe_a">PE_A</option>
                              <option value="pe_a_adm">PE_A_ADM</option>
                              <option value="pe_b">PE_B</option>
                              <option value="pe_b_adm">PE_B_ADM</option>
                              <option value="pe_c">PE_C</option>
                              <option value="pe_c_adm">PE_C_ADM</option>
                              <option value="pe_d">PE_D</option>
                              <option value="pe_d_adm">PE_D_ADM</option>
                              <option value="pe_e">PE_E</option>
                              <option value="pe_e_adm">PE_E_ADM</option>
                              <option value="pe_f">PE_F</option>
                              <option value="pe_f_adm">PE_F_ADM</option>
                              <option value="pe_g">PE_G</option>
                              <option value="pe_g_adm">PE_G_ADM</option>
                              <option value="pedras">Pedras</option>
                              <option value="pneu">Pneu</option>
                              <option value="raio">Raio</option>
                              <option value="supervisao_area">Supervisão Área</option>
                              <option value="supervisao_turma">Supervisão Turma</option>
                              <option value="supervisao_geral">Supervisão Geral</option>
                            </select>
                          </FormGroup> */}
                        </Col>
                        <Col lg="2">
                          <FormGroup>
                            {this.state.neighborhood.length > 0 && (
                              <div>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-dtnascimento"
                                >
                                  Localidade
                                </label>

                                <select
                                  value={this.state.selectedNeighborhood}
                                  onChange={(e) =>
                                    this.setState({
                                      selectedNeighborhood: e.target.value,
                                    })
                                  }
                                  className="form-control "
                                >
                                  <option defaultValue="" value="">
                                    {" "}
                                    Selecione...{" "}
                                  </option>

                                  {this.state.neighborhood.map((team) => (
                                    <option
                                      key={team.id}
                                      value={team.id}
                                      defaultValue={
                                        this.state.selectedNeighborhood
                                      }
                                    >
                                      {team.nome}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Status
                            </label>

                            <select
                              value={this.state.status}
                              onChange={(e) =>
                                this.setState({ status: e.target.value })
                              }
                              className="form-control "
                              id="select-tipo"
                            >
                              <option defaultValue="" value="">
                                {" "}
                                Selecione...{" "}
                              </option>

                              <option value="nao">Aberto</option>
                              <option value="sim">Fechado</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Modalidade
                            </label>

                            <select
                              value={this.state.tratamento}
                              onChange={(e) => {
                                this.handleTratamento(e);
                              }}
                              className="form-control "
                              id="select-tipo"
                            >
                              <option value="">selecione...</option>
                              <option value="primeiro">1º - Tratamento</option>
                              <option value="segundo">2º - Tratamento</option>
                              <option value="terceiro">3º - Tratamento</option>
                              <option value="quarto">4º - Tratamento</option>
                              <option value="quinto">5º - Tratamento</option>
                              <option value="sexto">6º - Tratamento</option>
                              <option value="lia_pesquisa">LIA - Pesquisa</option>
                              <option value="liraa">LIRAa - Pesquisa</option>
                              <option value="monitoramento">Monitoramento</option>
                              <option value="tpve">TPVE - Tratamento</option>
                            </select>
                          </FormGroup>

                        </Col>{" "}
                      </Row>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className='form-control-label'
                              htmlFor='input-first-name'
                            >
                              Data Abertura Inicial
                            </label>
                            <InputMask
                              mask='99/99/9999'
                              type='text'
                              inputMode='numeric'
                              name='data_abertura_inicio'
                              id='data_abertura_inicio'
                              className='form-control'
                              value={this.state.data_abertura_inicio}
                              onChange={(e) =>
                                this.setState({
                                  data_abertura_inicio: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>{" "}
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className='form-control-label'
                              htmlFor='input-first-name'
                            >
                              Data Abertura Final
                            </label>
                            <InputMask
                              mask='99/99/9999'
                              type='text'
                              inputMode='numeric'
                              name='data_abertura_fim'
                              id='data_abertura_fim'
                              className='form-control'
                              value={this.state.data_abertura_fim}
                              onChange={(e) =>
                                this.setState({
                                  data_abertura_fim: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>{" "}
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className='form-control-label'
                              htmlFor='input-first-name'
                            >
                              Data Fechamento Inicial
                            </label>
                            <InputMask
                              mask='99/99/9999'
                              type='text'
                              inputMode='numeric'
                              name='data_fechamento_inicio'
                              id='data_fechamento_inicio'
                              className='form-control'
                              value={this.state.data_fechamento_inicio}
                              onChange={(e) =>
                                this.setState({
                                  data_fechamento_inicio: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>{" "}
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className='form-control-label'
                              htmlFor='input-first-name'
                            >
                              Data Fechamento Final
                            </label>
                            <InputMask
                              mask='99/99/9999'
                              type='text'
                              inputMode='numeric'
                              name='data_fechamento_fim'
                              id='data_fechamento_fim'
                              className='form-control'
                              value={this.state.data_fechamento_fim}
                              onChange={(e) =>
                                this.setState({
                                  data_fechamento_fim: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>{" "}


                      </Row>
                    </div>
                    <div className="text-center">
                      <Row>
                        <Col className="order-xl-1" xl="12">
                          {" "}
                          <Button
                            className="mt-4 btn-zoo-alt"
                            color="primary"
                            type="button"
                            onClick={this.handleSubmit}
                          >
                            <b>Pesquisar</b>
                          </Button>
                          {localStorage.getItem("perm") !== "agente" && localStorage.getItem("perm") !== "operador" && (
                            <Button
                              className="mt-4 btn-zoo"
                              color="primary"
                              type="button"
                              onClick={() => {
                                this.props.history.push("/admin/itinerary-new");
                              }}
                            >
                              <b>Cadastrar</b>
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {this.state.response && !this.state.requesting && (
           <Row className="text-left" style={{ paddingBottom:"2%" }}>
                        <Col className="order-xl-1" xl="12">

                <ExcelFile>
                <ExcelSheet data={this.state.response} name="Itinerario">
                <ExcelColumn label="Id" 
                value={(row) => " "+row.id}
                cell={(props) => <span style={{ textAlign: 'left' }}>{props.value}</span>}
                 />
                <ExcelColumn
                    label="Equipe"
                    value={(rowData) => rowData.equipe ? rowData.equipe : "-" }
                     style={{whiteSpace: 'nowrap', wrapText: true }}
                    width={150} 
                    maxWidth={150}
                  />
                  <ExcelColumn
                    label="Tratamento"
                    value={(row) => {
                      const origemValue = row.tratamento;
                      let displayedText = "";

                      switch (origemValue.toLowerCase()) {
                        case "primeiro":
                          displayedText = "1º - Tratamento";
                          break;
                        case "segundo":
                          displayedText = "2º - Tratamento";
                          break;
                        case "terceiro":
                          displayedText = "3º - Tratamento";
                          break;
                        case "quarto":
                          displayedText = "4º - Tratamento";
                          break;
                        case "quinto":
                          displayedText = "5º - Tratamento";
                          break;
                        case "sexto":
                          displayedText = "6º - Tratamento";
                          break;
                        case "lia_pesquisa":
                          displayedText = "LIA - Pesquisa";
                          break;
                        case "liraa":
                          displayedText = "LIRAa - Pesquisa";
                          break;
                        case "monitoramento":
                          displayedText = "Monitoramento";
                          break;
                        case "tpve":
                          displayedText = "TPVE - Tratamento";
                          break;
                        default:
                          displayedText = "-";
                          break;
                      }

                      return displayedText;
                    }}
                  />
                  <ExcelColumn label="Localidade" 
                value={(row) => " "+row.bairro}
                cell={(props) => <span style={{ textAlign: 'left' }}>{props.value}</span>}
                 />
             
                 <ExcelColumn label="status" 
                value={(row) => row.fechado === 'sim'
                ? 'Fechado'
                : 'Aberto'}
                cell={(props) => <span style={{ textAlign: 'left' }}>{props.value}</span>}
                 />
                 <ExcelColumn label="data_abertura" 
                value={(row) => row.data_abertura ? moment(moment(row.data_abertura)).format("DD/MM/YYYY") :""}
                cell={(props) => <span style={{ textAlign: 'left' }}>{props.value}</span>}
                 />
                 <ExcelColumn label="Média Produção" 
                value={(row) => row.total_producao? row.total_producao : 0}
                cell={(props) => <span style={{ textAlign: 'left' }}>{props.value}</span>}
                 />
                  
                 
                </ExcelSheet>         
              </ExcelFile>
                        
         </Col>
        </Row>
     )}


          {this.state.response && !this.state.requesting && (
            <DataTable
              noHeader={true}
              pagination={true}
              striped={true}
              columns={this.columns}
              responsive={true}
              highlightOnHover={true}
              data={this.state.response}
              paginationComponentOptions={this.defaultComponentOptions}
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
              customStyles={this.customStyles}
              progressPending={this.state.response === undefined}
              noDataComponent="Nenhum registro encontrado."
              paginationResetDefaultPage={this.state.response.length > 0}
            />
          )}
          {this.state.requesting && (
            <div className="text-center">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                color="primary"
              />
            </div>
          )}
        </Container>
      </>
    );
  }
}
