import React from "react";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import SweetAlert2 from "react-sweetalert2";
import DataTable from "react-data-table-component";

import Header from "components/Headers/Header.js";

import Api from "shared/resources/Api";
import { sectors, neighborhood } from "shared/services/PlacesService";

export default class OwnerList extends React.Component {
  /* Dados Grid */
  columns = [

    {
      name: "Cód. Pessoa",
      selector: "cd_pessoa",
    },
    {
      name: "Nome Proprietário",
      minWidth: "250px",
      selector: "nm_pessoa",
    },
    {
      name: "Nr. Telefone",
      selector: "numfon",
      cell: (row) => (
        <div>
          {row.numfon && row.numfon != null ?  row.numfon : '-'}
        </div>
      ),
    },
    {
      name: "Nr. Celular",
      selector: "numcel",
      cell: (row) => (
        <div>
          {row.numcel && row.numcel != null ?  row.numcel : '-'}
        </div>
      ),
    },
    {
      name: "Tipo Endereço",
      selector: "tplog_pessoa",
      cell: (row) => (
        <div>
          {row.tplog_pessoa && row.tplog_pessoa != null ?  row.tplog_pessoa : '-'}
        </div>
      ),
    },
    {
      name: "Endereço",
      selector: "log_pes",
      minWidth: "250px",
      cell: (row) => (
        <div>
          {row.tplog_pessoa}{' '}{row.log_pes}
        </div>
      ),
    },
    {
      name: "Número",
      minWidth: "30px",
      selector: "nr_no_logradouro",
    },
    {
      name: "Localidade",
      selector: "nm_bairro_cidade",
      minWidth: "250px",
    },
    {
      name: "Complemento",
      selector: "cd_logradouro",
      cell: (row) => (
        <div>
          {row.cd_logradouro && row.cd_logradouro != null ?  row.cd_logradouro : '-'}
        </div>
      ),
    }
  ];
  defaultComponentOptions = {
    rowsPerPageText: "Registros por Página:",
    rangeSeparatorText: "de",
    noRowsPerPage: false,
  };
  customStyles = {
    headCells: {
      style: {
        fontWeight: 700,
        fontSize: 15,
        color: "#000",
      },
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      response: "",
      nome: "",
      logradouro: "",
      tipo_logradouro: "",
      complemento: "",
      tel: "",
      numero: "",
      api: Api.baseURL,
      id: "",
      bairro: "",
      requesting: false,
      neighborhood: [],
      sectors: [],
      selectedSector: null,
      selectedNeighborhood: null,
    };
  }
  componentWillMount() {
    document.addEventListener("keydown", (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        this.handleSubmit(event);
      }
    });

    neighborhood().then((res) => {
      if (res) {
        this.setState({
          neighborhood: res,
        });
      }
    });

    sectors().then((res) => {
      if (res) {
        this.setState({
          sectors: res,
        });
      }
    });
  }

  fetchSectors = async () => {
    const response = await fetch(Api.baseURL + "/setor", {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        sectors: body,
      });
    }

    return body;
  };

  fetchNeighborhood = async () => {
    const response = await fetch(Api.baseURL + "/bairro", {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        neighborhood: body,
      });
    }

    return body;
  };

  handleSubmit = (event) => {

    if (
      this.state.logradouro === undefined ||
      this.state.logradouro === "" ||
      this.state.logradouro === null
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Logradouro.",
          type: "warning",
        },
      });

      return false;
    }

    // if (
    //   this.state.bairro === undefined ||
    //   this.state.bairro === "" ||
    //   this.state.bairro === null
    // ) {
    //   this.setState({
    //     swal: {
    //       show: true,
    //       title: "Atenção",
    //       text: "Informe a Localidade.",
    //       type: "warning",
    //     },
    //   });

    //   return false;
    // }
    this.setState({ requesting: true });
    this.fetchOwner()
      .then((res) => this.setState({ response: res }))
      .catch((err) => {
        console.log(err);
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text:
              "Não foram encontrados proprietários para os parâmetros informados.",
            type: "error",
          },
          requesting: false,
        });
      });

    event.preventDefault();
  };

  fetchOwner = async () => {
    let host = this.state.api + "/siat_proprietario?";

    let params = {
      endereco: this.state.logradouro,
      numero: this.state.numero,
      complemento: this.state.complemento,
      bairro: this.state.bairro
    };
    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    const response = await fetch(host + query, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    if (body.length > 0) {
      this.setState({
        agent: body,
        requesting: false,
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text:
            "Não foram encontrados proprietários para os parâmetros informados.",
          type: "error",
        },
        requesting: false,
      });
    }
    return body;
  };
  handleDelete = (element) => {
    this.setState({
      swal: {
        show: true,
        title: "Atenção",
        text: "Deseja realizar a exclusão deste registro?",
        type: "error",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "Cancelar",
      },
      trieshop: element,
      isConfirmation: true,
    });
  };
  handleDeleteTireShop(d) {
    this.callDel(d)
      .then()
      .catch((err) => console.log(err));
  }

  callDel = async (d) => {
    const response = await fetch(Api.baseURL + "/borracharia/" + d.id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: "Borracharia deletada com sucesso!",
          type: "success",
        },
        response: "",
        nome: "",
        logradouro: "",
        tel: "",
        selectedNeighborhood: "",
        selectedSector: "",
        id: ""
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Erro ao deletar borracharia.",
          type: "error",
        },
      });

      throw Error(body.message);
    }

    return body;
  };

  render() {
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });

            if (this.state.isConfirmation && !result.dismiss) {
              this.setState({
                isConfirmation: false,
              });
              this.handleDeleteTireShop(this.state.trieshop);
            }
          }}
        />

        <Header />
        {/* Page content */}
        <Container className="mb-7 mt-5 " fluid>
          <Row className="mb-3">
            <Col className="order-xl-1">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Consulta Proprietário
                    </h6>
                    <div className="pl-lg-4">
                    <label
                              className="form-control-label-alternative"
                              htmlFor="input-logradouro"
                            >
                              Digite o logradouro sem informar o tipo. Exemplo:  Rondon Pacheco
                            </label>
                      <Row>
                      {/* <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label mt-3"
                              htmlFor="input-logradouro"
                            >
                              Tipo Logradouro
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-logradouro"
                              placeholder="RUA"
                              type="text"
                              required
                              value={this.state.tipo_logradouro}
                              onChange={(e) =>
                                this.setState({ tipo_logradouro: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>{" "} */}
                        <Col lg="10">
                          <FormGroup>
                            <label
                              className="form-control-label mt-3"
                              htmlFor="input-logradouro"
                            >
                              Logradouro *
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-logradouro"
                              placeholder=""
                              type="text"
                              required
                              value={this.state.logradouro}
                              onChange={(e) =>
                                this.setState({ logradouro: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>{" "}
                      </Row>

                      <Row>
                      <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-logradouro"
                            >
                              Localidade
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-logradouro"
                              type="text"
                              required
                              maxLength={50}
                              value={this.state.bairro}
                              onChange={(e) =>
                                this.setState({ bairro: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>{" "}

                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-logradouro"
                            >
                              Número
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-logradouro"
                              type="text"
                              required
                              value={this.state.numero}
                              onChange={(e) =>
                                this.setState({ numero: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>{" "}

                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Complemento
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-complemento"
                              type="text"
                              required
                              value={this.state.complemento}
                              onChange={(e) =>
                                this.setState({ complemento: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className="text-center">
                      <Row>
                        <Col className="order-xl-1" xl="12">
                          {" "}
                          <Button
                            className="mt-4 btn-zoo-alt"
                            color="primary"
                            type="button"
                            onClick={this.handleSubmit}
                          >
                            <b>Pesquisar</b>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.response && !this.state.requesting && (
            <DataTable
              noHeader={true}
              pagination={true}
              striped={true}
              columns={this.columns}
              responsive={true}
              highlightOnHover={true}
              data={this.state.response}
              paginationComponentOptions={this.defaultComponentOptions}
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
              customStyles={this.customStyles}
              progressPending={this.state.response === undefined}
              noDataComponent="Nenhum registro encontrado."
              paginationResetDefaultPage={this.state.response.length > 0}
            />
          )}
          {this.state.requesting && (
            <div className="text-center">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                color="primary"
              />
            </div>
          )}
        </Container>
      </>
    );
  }
}
