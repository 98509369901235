/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SweetAlert2 from "react-sweetalert2";
import BeautyStars from "beauty-stars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import InputNumber from "react-input-just-numbers";
import moment from "moment";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
import "react-datepicker/dist/react-datepicker.css";
import Api from "shared/resources/Api";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
  Collapse,
} from "reactstrap";

import {
  addResponseMessage,
  addUserMessage,
  dropMessages,
} from "react-chat-widget";
import "react-chat-widget/lib/styles.css";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import api from "shared/resources/Api";
import InputMask from "react-input-mask";
import DataTable from "react-data-table-component";
import ImageUploader from "react-images-upload";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import { equipes } from "shared/services/PlacesService";

class TicketNew extends React.Component {
  columns = [
    {
      name: "Endereço",
      selector: "endereco",
      sortable: true,
    },
    {
      name: "Bairro",
      selector: "bairro",
      sortable: true,
      cell: (row) => <div>{row.bairro ? row.bairro : "-"}</div>,
    },
    {
      name: "Ação",
      cell: (row) => (
        <div>
          <Button
            type="button"
            className="btn-acao "
            title="Escolher"
            onClick={() => {
              this.handleSelectedAddress(row);
            }}
          >
            <FontAwesomeIcon icon={faEdit} className="edit" />
            Escolher
          </Button>
        </div>
      ),
    },
  ];
  defaultComponentOptions = {
    rowsPerPageText: "Registros por Página:",
    rangeSeparatorText: "de",
    noRowsPerPage: false,
  };
  customStyles = {
    // cells: {
    //   style: {
    //     backgroundColor: "#F7FAFC"
    //   },
    // },
    table: {
      style: {
        backgroundColor: "#F7FAFC",
      },
    },
    headCells: {
      style: {
        fontWeight: 700,
        fontSize: 15,
        color: "#000",
        backgroundColor: "#F7FAFC",
      },
    },
  };

  

  constructor(props) {
    super(props);
    registerLocale("pt", pt);
    this.state = {
      cep: "",
      nome: "",
      responseAddress: "",
      selectedAddress: "",
      cpf: "",
      tel: "",
      status: "",
      data_nascimento: "",
      logradouro: "",
      bairro: "",
      numero: "",
      complemento: "",
      response: "",
      api: api.baseURL,
      citizen: [],
      agents: [],
      messageList: [],
      chatCount: 0,
      counter: 0,
      requesting: false,
      isSuccess: false,
      selectedAgent: 0,
      checkPneu: false,
      tipo: "solicitacao",
      origem: "",
      equipe: "",
      equipes: [],
      quarteirao: null,
      agendamento: "",
      lado: "",
      pictures: [],
      selectedFile: "",
      video: "",
      descricao: "",
      showMedia: false,
      isSaving: false,
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleShowExame = this.handleShowExame.bind(this);
    this.handleShowObjeto = this.handleShowObjeto.bind(this);
    this.handleShowPneu = this.handleShowPneu.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleCheck2 = this.handleCheck2.bind(this);
    this.handleCheckAtendimento = this.handleCheckAtendimento.bind(this);
    this.handleCheckViajou = this.handleCheckViajou.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  fileSelectedHandler = (e) => {
    this.setState({ files: [...this.state.files, ...e.target.files] });
  };

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
      pictures: pictureDataURLs,
      pictureFiles: pictureFiles,
    });
  }

  handleImage = (imageList, addUpdateIndex) => {
    this.setState({
      images: imageList,
    });
  };

  componentDidMount() {
    this.fetchAgents();
    dropMessages();

    if (
      this.state.chats !== null &&
      this.state.chats !== undefined &&
      this.state.chats.length > 0
    ) {
      if (this.state.counter === 0) {
        dropMessages(); // clears any messages left in state
        this.loadChats();
      }
    }
    equipes().then((res) => {
      if (res) {
        this.setState({
          equipes: res,
        });
      }
    });
  }

  fetchEquipes = async () => {
    const response = await fetch(Api.baseURL + "/unidade_saude/equipe", {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        sectors: body,
      });
    }

    return body;
  };


  handleSelectedAddress = (row) => {
    this.setState({
      selectedAddress: row,
      logradouro: row.endereco,
      bairro: row.bairro,
    });
  };

  blurEndereco = () => {
    this.consultarLatLong();
  };

  loadChats() {
    this.setState(
      {
        chatCount: this.props.location.state.chats.length,
        counter: 1,
      },
      () => {
        this.state.chats.map((item) => {
          item.cidadao
            ? addResponseMessage(item.msg)
            : addUserMessage(item.msg);
          return true;
        });
      }
    );
  }

  fetchAgents = async () => {
    const response = await fetch(api.baseURL + "/usuario/agente", {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        agents: body,
      });
    }

    return body;
  };

  handleShowPneu() {
    let counter = 0;

    if (
      this.state.pneu_bicicleta !== undefined &&
      this.state.pneu_bicicleta !== null
    ) {
      counter++;
    }

    if (this.state.pneu_moto !== undefined && this.state.pneu_moto !== null) {
      counter++;
    }

    if (this.state.pneu_carro !== undefined && this.state.pneu_carro !== null) {
      counter++;
    }

    if (
      this.state.pneu_caminhao !== undefined &&
      this.state.pneu_caminhao !== null
    ) {
      counter++;
    }

    if (
      this.state.pneu_camionete !== undefined &&
      this.state.pneu_camionete !== null
    ) {
      counter++;
    }

    if (counter > 0) {
      return true;
    }

    return false;
  }

  handleShowObjeto() {
    let counter = 0;

    if (this.state.lona !== undefined && this.state.lona !== null) {
      counter++;
    }

    if (this.state.eletro !== undefined && this.state.eletro !== null) {
      counter++;
    }

    if (this.state.lata !== undefined && this.state.lata !== null) {
      counter++;
    }

    if (this.state.garrafa !== undefined && this.state.garrafa !== null) {
      counter++;
    }

    if (this.state.prato !== undefined && this.state.prato !== null) {
      counter++;
    }

    if (counter > 0) {
      return true;
    }

    return false;
  }

  handleShowExame() {
    let counter = 0;

    if (
      this.state.exame_chicungunha !== undefined &&
      this.state.exame_chicungunha !== null
    ) {
      counter++;
    }

    if (
      this.state.exame_dengue !== undefined &&
      this.state.exame_dengue !== null
    ) {
      counter++;
    }

    if (
      this.state.exame_febre_amarela !== undefined &&
      this.state.exame_febre_amarela !== null
    ) {
      counter++;
    }

    if (this.state.exame_zika !== undefined && this.state.exame_zika !== null) {
      counter++;
    }

    if (counter > 0) {
      return true;
    }

    return false;
  }

  validateFields() {
    if (
      this.state.tel !== "" &&
      this.state.tel.replace(/\D/g, "").length !== 11
    ) {
      alert("Preencha o Celular com DDD e nono Dígito.");
      return false;
    }

    return true;
  }

  handleNewUserMessage = (newMessage) => {
    this.callChat(newMessage);
  };

  callChat = async (newMessage) => {
    const response = await fetch(
      api.baseURL + "/os/" + this.state.id + "/chat",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("agToken"),
        },
        body: JSON.stringify({
          msg: newMessage,
        }),
      }
    );

    const body = await response.json();

    if (response.status === 200) {
      console.log("resposta ok");
    } else {
      alert("Erro ao realizar o Cadastro de Comentario da OS.");
      throw Error(body.message);
    }

    return body;
  };

   handleSave = async(event) => {
    if (this.state.isSaving) {
      return;
    }
    console.log(this.state.origem)
    // this.setState({ isSaving: true });

    if (this.state.origem === "" ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe a Origem.",
          type: "warning",
        },
      });
      return false;
    }

    if (
      this.state.logradouro === undefined ||
      this.state.logradouro.length === 0
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Logradouro.",
          type: "warning",
        },
      });

      return false;
    }

    if (
      this.state.bairro === undefined ||
      this.state.bairro === "" ||
      this.state.bairro === null
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Bairro.",
          type: "warning",
        },
      });

      return false;
    }

    if (this.state.numero === undefined || this.state.numero.length === 0) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Número.",
          type: "warning",
        },
      });

      return false;
    }

    if (this.state.pictures.length > 5) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Número Máximo de Fotos Atingido.",
          type: "warning",
        },
      });
      return false;
    }

    if (
      this.state.tipo_de_imovel === undefined ||
      this.state.tipo_de_imovel.length === 0
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Tipo de Solicitação.",
          type: "warning",
        },
      });

      return false;
    }

    
    //this.callSave();
    this.setState({ isSaving: true });
    const result =  await  this.callSave();
    console.log("result",result)

    
    if (result === false){
      console.log("as?")
      this.setState({ isSaving: false });
      return false;
    }
    // localStorage.setItem('show', "false");

    this.setState({ requestingOS: true,isSaving: false });
  }

  callSave = async () => {
    var os = {
      tipo: this.state.tipo,
      origem: this.state.origem,
      equipe: this.state.equipe,
      tipo_de_imovel: this.state.tipo_de_imovel,
      descricao: this.state.descricao,
      quarteirao: this.state.quarteirao,
      lado: this.state.lado,
      vistoria: this.state.vistoria,
      pneu_bicicleta: this.state.pneu_bicicleta,
      pneu_moto: this.state.pneu_moto,
      pneu_carro: this.state.pneu_carro,
      pneu_camionete: this.state.pneu_camionete,
      pneu_caminhao: this.state.pneu_caminhao,
      prato: this.state.prato,
      lona: this.state.lona,
      garrafa: this.state.garrafa,
      lata: this.state.lata,
      eletro: this.state.eletro,
      via_publica_bueiro: this.state.via_publica_bueiro,
      via_publica_desnivel: this.state.via_publica_desnivel,
      via_publica_canteiro: this.state.via_publica_canteiro,
      via_publica_canaleta: this.state.via_publica_canaleta,
      via_publica_ponto_de_onibus: this.state.via_publica_ponto_de_onibus,
      agendamento:
        this.state.agendamento !== "" &&
        this.state.agendamento !== "Invalid Date"
          ? moment(this.state.agendamento).format("YYYY-MM-DDTHH:mm")
          : null,
      endereco: {
        logradouro: this.state.logradouro.trim(),
        bairro: this.state.bairro,
        numero: this.state.numero,
        complemento: this.state.complemento,
        endereco:
          this.state.logradouro +
          ", " +
          this.state.numero +
          ", " +
          this.state.bairro,
      },
    };

    var data = new FormData();
    data.append("os", JSON.stringify(os));

    // console.log(this.state.pictureFiles.length,'files')
    // console.log(this.state.pictures.length,'pictures')

    if (this.state.pictures != null && this.state.pictures.length === 1) {
      data.append("foto1", this.state.pictures[0]);
    }

    if (this.state.pictures != null && this.state.pictures.length === 2) {
      data.append("foto1", this.state.pictures[0]);
      data.append("foto2", this.state.pictures[1]);
    }

    if (this.state.pictures != null && this.state.pictures.length === 3) {
      data.append("foto1", this.state.pictures[0]);
      data.append("foto2", this.state.pictures[1]);
      data.append("foto3", this.state.pictures[2]);
    }

    if (this.state.pictures != null && this.state.pictures.length === 4) {
      data.append("foto1", this.state.pictures[0]);
      data.append("foto2", this.state.pictures[1]);
      data.append("foto3", this.state.pictures[2]);
      data.append("foto4", this.state.pictures[3]);
    }

    if (this.state.pictures != null && this.state.pictures.length === 5) {
      data.append("foto1", this.state.pictures[0]);
      data.append("foto2", this.state.pictures[1]);
      data.append("foto3", this.state.pictures[2]);
      data.append("foto4", this.state.pictures[3]);
      data.append("foto5", this.state.pictures[4]);
    }

    if (this.state.selectedFile !== "") {
      data.append("video", this.state.selectedFile);
    }

    const response = await fetch(api.baseURL + "/os/solicitacao", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
      body: data,
    });
    const body = await response.json();

    if(body){
      if(body.id !== undefined){
      if (localStorage.getItem("params")) {
        var para = JSON.parse(localStorage.getItem("params"));
      const newParams = { ...para, id: body.id? body.id : "" };
      localStorage.setItem('params', JSON.stringify(newParams));
      localStorage.setItem('page', 1);
      }


    }
    }

    

   

    if (response.status === 200) {
      if(body){
        if(body.id !== undefined){
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: "Chamado cadastrado com sucesso!"+body.id !== undefined? " Numero da ordem gerada: "+body.id : "",
          type: "success",
        },
        requesting: false,
        isSuccess: true,
      });
      localStorage.setItem('show', "false");
    } else {

      this.setState({
        swal: {
          show: true,
          title: "Erro",
          text: "Já existe O.S para esse endereço",
          type: "error",
        },
        selectedOs: null,
        requesting: false,
      });
      return false;
    }
  }
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Erro",
          text: "Erro ao cadastrar O.S",
          type: "error",
        },
        selectedOs: null,
        requesting: false,
      });
      //throw Error(body.message);
      return false;
    }

    return body;
  };

  handleSiat = (event) => {
    if (
      this.state.logradouro === undefined ||
      this.state.logradouro.length === 0
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Logradouro.",
          type: "warning",
        },
      });

      return false;
    } else {
      if (this.state.selectedAddress === "") {
        this.setState({ requesting: true });

        this.fetchSiat()
          .then((res) => this.setState({ responseAddress: res }))
          .catch((err) => {
            console.log(err);
            this.setState({
              swal: {
                show: true,
                title: "Atenção",
                text: "Não foram encontrados endereços para os parâmetros informados.",
                type: "error",
              },
              requesting: false,
            });
          });
      } else {
        this.setState({
          selectedAddress: "",
          logradouro: "",
          bairro: "",
          responseAddress: "",
        });
      }
    }

    event.preventDefault();
  };

  // On file select (from the pop up)
  onFileChange = (event) => {
    // Update the state
    this.setState(
      {
        selectedFile: event.target.files[0],
        video: URL.createObjectURL(event.target.files[0]),
      },
      () => console.log("testevid", this.state.video)
    );
  };

  removeItem(index) {
    // Just so you don't need to bind `this`
    const reducedArr = [...this.state.pictures];

    reducedArr.splice(index, 1);

    this.setState({
      pictures: reducedArr,
    });
  }

  fetchSiat = async () => {
    let host = this.state.api + "/siat_enderecos?";

    let params = {
      endereco: this.state.logradouro,
      bairro: this.state.bairro ? this.state.bairro : "",
    };

    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    const response = await fetch(host + query, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    if (body.length > 0) {
      this.setState({
        requesting: false,
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Não foram encontrados endereços para os parâmetros informados.",
          type: "error",
        },
        requesting: false,
      });
    }
    return body;
  };

  handleCheck(e) {
    this.setState({
      checked: e.target.checked,
    });
  }

  handleCheck2(e) {
    this.setState({
      checked2: e.target.checked,
    });
  }

  handleCheckAtendimento(e) {
    this.setState({
      checkedAtendimento: e.target.checked,
    });
  }

  handleCheckViajou(e) {
    this.setState({
      checkedViajou: e.target.checked,
    });
  }

  render() {
    return (
      <>
        <UserHeader />
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });
            if (this.state.isSuccess) {
              this.props.history.push("/admin/ticket");
            }
          }}
        />
        {/* Page content */}
        <Container className="main-zoo" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="12">
                      <h3 className="mb-0">Cadastrar O.S</h3>
                      <FormGroup>
                        <h5 style={{ marginTop: 10 }} className="mb-0">
                          Origem *
                        </h5>
                        <select
                          value={this.state.origem}
                          onChange={(e) =>
                            this.setState({ origem: e.target.value })
                          }
                          className="form-control "
                          id="select-tipo"
                        >
                          <option defaultValue="" value="">
                            {" "}
                            Selecione...{" "}
                          </option>
                          <option value="drones">Monitoramento</option>
                                  <option value="email">E-mail</option>
                                  <option value="faleconosco">
                                    Fale Conosco
                                  </option>
                                  <option value="municepe">Municipe</option>
                                  <option value="origem_tecnica">
                                    Origem Técnica
                                  </option>
                                  <option value="ouvidoria">Ouvidoria</option>
                                  <option value="sim">SIM</option>
                                  <option value="telefone">Telefone</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col xs="12">
                    <FormGroup>
                            {this.state.equipes.length > 0 && (
                              <div>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-dtnascimento"
                                >
                                  Equipe
                                </label>

                                <select
                                  value={this.state.equipe}
                                  onChange={(e) =>
                                    this.setState({
                                      equipe: e.target.value,
                                    })
                                  }
                                  className="form-control "
                                >
                                  <option defaultValue="" value="">
                                    {" "}
                                    Selecione...{" "}
                                  </option>
                                  {this.state.equipes.map((team) => (
                                    <option key={team.nm_equipe} value={team.nm_equipe}>
                                    {team.ds_descricao_equipe.charAt(0).toUpperCase() +
                                      team.ds_descricao_equipe.slice(1).toLowerCase()}
                                  </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          </FormGroup>
                      {/* <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Equipe
                        </label>

                        <select
                          value={this.state.equipe}
                          onChange={(e) =>
                            this.setState({ equipe: e.target.value })
                          }
                          className="form-control "
                          id="select-tipo"
                        >
                          <option value="">selecione...</option>
                          <option value="equipe_01">Equipe 1</option>
                          <option value="equipe_02">Equipe 2</option>
                          <option value="equipe_03">Equipe 3</option>
                          <option value="equipe_04">Equipe 4</option>
                          <option value="equipe_05">Equipe 5</option>
                          <option value="equipe_06">Equipe 6</option>
                          <option value="equipe_07">Equipe 7</option>
                          <option value="equipe_08">Equipe 8</option>
                          <option value="equipe_09">Equipe 9</option>
                          <option value="equipe_10">Equipe 10</option>
                          <option value="equipe_11">Equipe 11</option>
                          <option value="equipe_12">Equipe 12</option>
                          <option value="equipe_13">Equipe 13</option>
                          <option value="equipe_14">Equipe 14</option>
                          <option value="equipe_15">Equipe 15</option>
                          <option value="equipe_16">Equipe 16</option>
                          <option value="equipe_17">Equipe 17</option>
                          <option value="equipe_18">Equipe 18</option>
                          <option value="equipe_19">Equipe 19</option>
                          <option value="equipe_20">Equipe 20</option>
                          <option value="equipe_21">Equipe 21</option>
                          <option value="equipe_22">Equipe 22</option>
                          <option value="equipe_23">Equipe 23</option>
                          <option value="equipe_24">Equipe 24</option>
                          <option value="equipe_25">Equipe 25</option>
                          <option value="equipe_26">Equipe 26</option>
                          <option value="equipe_27">Equipe 27</option>
                          <option value="equipe_28">Equipe 28</option>
                          <option value="equipe_29">Equipe 29</option>
                          <option value="equipe_30">Equipe 30</option>
                          <option value="acoes">Ações</option>
                          <option value="administrativo">Administrativo</option>
                          <option value="app_drone_mob">App Drone Mob</option>
                          <option value="fog">Fog</option>
                          <option value="material">Material</option>
                          <option value="miraporanga">Miraporanga</option>
                          <option value="tapuirama">Tapuirama</option>
                          <option value="mobilizacao">Mobilização</option>
                          <option value="motorista">Motorista</option>
                          <option value="ovitrampas">Ovitrampas</option>
                          <option value="pe_a">PE_A</option>
                          <option value="pe_a_adm">PE_A_ADM</option>
                          <option value="pe_b">PE_B</option>
                          <option value="pe_b_adm">PE_B_ADM</option>
                          <option value="pe_c">PE_C</option>
                          <option value="pe_c_adm">PE_C_ADM</option>
                          <option value="pe_d">PE_D</option>
                          <option value="pe_d_adm">PE_D_ADM</option>
                          <option value="pe_e">PE_E</option>
                          <option value="pe_e_adm">PE_E_ADM</option>
                          <option value="pe_f">PE_F</option>
                          <option value="pe_f_adm">PE_F_ADM</option>
                          <option value="pe_g">PE_G</option>
                          <option value="pe_g_adm">PE_G_ADM</option>
                          <option value="pedras">Pedras</option>
                          <option value="pneu">Pneu</option>
                          <option value="raio">Raio</option>
                          <option value="supervisao_area">
                            Supervisão Área
                          </option>
                          <option value="supervisao_turma">
                            Supervisão Turma
                          </option>
                          <option value="supervisao_geral">
                            Supervisão Geral
                          </option>
                        </select>
                      </FormGroup> */}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {!this.state.requestingOS && (
                    <Form>
                      <div>
                        <hr className="my-4" />
                        {/* Address */}
                        <h6 className="heading-small text-muted mb-4">
                          Endereço da O.S
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-address"
                                >
                                  Logradouro *
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-address"
                                  disabled={this.state.selectedAddress !== ""}
                                  placeholder=""
                                  type="text"
                                  value={this.state.logradouro}
                                  onChange={(e) =>
                                    this.setState({
                                      logradouro: e.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-bairro"
                                >
                                  Bairro *
                                </label>

                                <Input
                                  className="form-control-alternative"
                                  id="input-lado"
                                  type="text"
                                  maxLength="30"
                                  disabled={this.state.selectedAddress !== ""}
                                  value={this.state.bairro}
                                  onChange={(e) =>
                                    this.setState({ bairro: e.target.value })
                                  }
                                />
                              </FormGroup>
                            </Col>

                            <Col md="2">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-city"
                                >
                                  Número *
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-number"
                                  placeholder=""
                                  type="text"
                                  value={this.state.numero}
                                  onChange={(e) =>
                                    this.setState({ numero: e.target.value })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-complement"
                                >
                                  Complemento
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-complement"
                                  placeholder=""
                                  type="text"
                                  maxLength={20}
                                  value={this.state.complemento}
                                  onChange={(e) =>
                                    this.setState({
                                      complemento: e.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-comp"
                                >
                                  Quarteirão
                                </label>

                                <InputNumber
                                  onlydigits
                                  className="form-control"
                                  id="input-quarteirao"
                                  inputMode="numeric"
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseInt(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 4);
                                  }}
                                  placeholder=""
                                  value={this.state.quarteirao}
                                  onChange={(e) =>
                                    this.setState({
                                      quarteirao: e.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-comp"
                                >
                                  Lado
                                </label>

                                <Input
                                  className="form-control-alternative"
                                  id="input-comp"
                                  type="text"
                                  required
                                  maxLength={4}
                                  value={this.state.lado}
                                  onChange={(e) =>
                                    this.setState({ lado: e.target.value })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md="3">
                              <FormGroup>
                                <div className="text-left mt-4 pt-2">
                                  <Button
                                    className={
                                      this.state.selectedAddress === ""
                                        ? "btn-zoo-alt"
                                        : "btn-zoo"
                                    }
                                    color="primary"
                                    type="button"
                                    onClick={this.handleSiat}
                                  >
                                    {this.state.selectedAddress === ""
                                      ? "Consultar Endereço"
                                      : "Alterar Endereço"}
                                  </Button>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>

                          {this.state.responseAddress &&
                            !this.state.requesting &&
                            this.state.selectedAddress === "" && (
                              <DataTable
                                noHeader={true}
                                pagination={true}
                                striped={true}
                                columns={this.columns}
                                sortable={true}
                                responsive={true}
                                highlightOnHover={true}
                                data={this.state.responseAddress}
                                noDataComponent="Nenhum registro encontrado."
                                paginationComponentOptions={
                                  this.defaultComponentOptions
                                }
                                customStyles={this.customStyles}
                              />
                            )}
                        </div>
                        {this.state.requesting && (
                          <div className="text-center">
                            <Spinner
                              style={{ width: "3rem", height: "3rem" }}
                              color="primary"
                            />
                          </div>
                        )}
                      </div>

                      {this.state.tipo === "solicitacao" && (
                        <div>
                          <hr className="my-4" />
                          {/* Address */}
                          <h6 className="heading-small text-muted mb-4">
                            Informações da O.S
                          </h6>
                          <div className="pl-lg-4">
                            <Row>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-address"
                                  >
                                    Tipo de Solicitação *
                                  </label>

                                  <select
                                    value={this.state.tipo_de_imovel}
                                    onChange={(e) =>
                                      this.setState({
                                        tipo_de_imovel: e.target.value,
                                      })
                                    }
                                    className="form-control "
                                    id="select-tipo"
                                  >
                                    <option defaultValue="" value="">
                                      {" "}
                                      Selecione...{" "}
                                    </option>
                                    <option value="caixa_dagua">
                                    Caixas d'água
                                  </option>
                                  <option value="piscinas">
                                    Cadastrar Piscina
                                  </option>
                                  <option value="focos">Focos do Mosquito</option>
                                  <option value="imobiliarias">
                                    Imobiliárias
                                  </option>
                                  <option value="imovel_abandonado">
                                    Imóvel abandonado
                                  </option>
                                  <option value="obras">Canteiros de Obra</option>
                                  <option value="peixes">Tratamento Biológico</option>
                                  <option value="recolhimento">
                                    Recolhimento
                                  </option>
                                  <option value="via_publica">
                                    Via Publica
                                  </option>
                                  </select>
                                </FormGroup>
                              </Col>
                            </Row>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Agendamento
                            </label>
                            <Row>
                              <Col lg="4">
                                <FormGroup>
                                  <DatePicker
                                    showTimeSelect
                                    locale={pt}
                                    timeCaption="Hora"
                                    className="form-control"
                                    selected={this.state.agendamento}
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    onChange={(date) =>
                                      this.setState({
                                        agendamento: date,
                                      })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="12">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-last-dtnascimento"
                                  >
                                    Descrição
                                  </label>
                                  <Input
                                    id="input-conteudo"
                                    maxLength={1000}
                                    value={this.state.descricao}
                                    onChange={(e) =>
                                      this.setState({
                                        descricao: e.target.value,
                                      })
                                    }
                                    rows="6"
                                    type="textarea"
                                    className="form-control-alternative"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      )}

                      {this.state.tipo_de_imovel === "pneu" && (
                        <div>
                          <hr className="my-4" />

                          {/* <h6 className="heading-small text-muted mb-4">
                        Recolhimento - Pneus
                      </h6> */}
                          <div className="custom-control custom-control-alternative custom-checkbox">
                            <input
                              className="custom-control-input"
                              id=" customCheckLogin"
                              type="checkbox"
                              checked={this.state.checked}
                              onChange={this.handleCheck}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor=" customCheckLogin"
                            >
                              <h6 className="heading-small text-muted mb-4">
                                {" "}
                                Recolhimento - Pneus
                              </h6>
                            </label>
                          </div>
                          {this.state.checked && (
                            <div className="pl-lg-4">
                              <Row>
                                <Col lg="2">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-nome"
                                    >
                                      Bicicleta
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      id="pneu_bicicleta"
                                      placeholder=""
                                      type="numeric"
                                      inputMode="numeric"
                                      maxLength={6}
                                      value={this.state.pneu_bicicleta}
                                      onChange={(e) =>
                                        this.setState({
                                          pneu_bicicleta: e.target.value,
                                        })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="2">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-nome"
                                    >
                                      Moto
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      id="pneu_moto"
                                      placeholder=""
                                      type="numeric"
                                      inputMode="numeric"
                                      maxLength={6}
                                      value={this.state.pneu_moto}
                                      onChange={(e) =>
                                        this.setState({
                                          pneu_moto: e.target.value,
                                        })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="2">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-nome"
                                    >
                                      Carro
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      id="input-carro"
                                      placeholder=""
                                      type="numeric"
                                      inputMode="numeric"
                                      maxLength={6}
                                      value={this.state.pneu_carro}
                                      onChange={(e) =>
                                        this.setState({
                                          pneu_carro: e.target.value,
                                        })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="3">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-nome"
                                    >
                                      Caminhão
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      id="input-nome"
                                      placeholder=""
                                      type="numeric"
                                      inputMode="numeric"
                                      maxLength={6}
                                      value={this.state.pneu_caminhao}
                                      onChange={(e) =>
                                        this.setState({
                                          pneu_caminhao: e.target.value,
                                        })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="3">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-nome"
                                    >
                                      Caminhonete
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      id="input-pneu_camionete"
                                      placeholder=""
                                      type="numeric"
                                      inputMode="numeric"
                                      maxLength={6}
                                      value={this.state.pneu_camionete}
                                      onChange={(e) =>
                                        this.setState({
                                          pneu_camionete: e.target.value,
                                        })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </div>
                      )}

                      {this.state.tipo_de_imovel === "objetos" && (
                        <div>
                          <hr className="my-4" />
                          {/* Address */}
                          <div className="custom-control custom-control-alternative custom-checkbox">
                            <input
                              className="custom-control-input"
                              id=" customCheckLogin2"
                              type="checkbox"
                              checked={this.state.checked2}
                              onChange={this.handleCheck2}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor=" customCheckLogin2"
                            >
                              <h6 className="heading-small text-muted mb-4">
                                {" "}
                                Recolhimento - Objetos
                              </h6>
                            </label>
                          </div>
                          {this.state.checked2 && (
                            <div className="pl-lg-4">
                              <Row>
                                <Col lg="2">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-nome"
                                    >
                                      Pratos
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      id="prato"
                                      placeholder=""
                                      type="numeric"
                                      inputMode="numeric"
                                      maxLength={6}
                                      value={this.state.prato}
                                      onChange={(e) =>
                                        this.setState({ prato: e.target.value })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="2">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-garrafa"
                                    >
                                      Garrafa
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      id="garrafa"
                                      placeholder=""
                                      type="numeric"
                                      inputMode="numeric"
                                      maxLength={6}
                                      value={this.state.garrafa}
                                      onChange={(e) =>
                                        this.setState({
                                          garrafa: e.target.value,
                                        })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="2">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-nome"
                                    >
                                      Lata
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      id="lata"
                                      placeholder=""
                                      type="numeric"
                                      inputMode="numeric"
                                      maxLength={6}
                                      value={this.state.lata}
                                      onChange={(e) =>
                                        this.setState({ lata: e.target.value })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="3">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="lona"
                                    >
                                      Lona
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      id="lona"
                                      placeholder=""
                                      type="numeric"
                                      inputMode="numeric"
                                      maxLength={6}
                                      value={this.state.lona}
                                      onChange={(e) =>
                                        this.setState({ lona: e.target.value })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="3">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="eletro"
                                    >
                                      Eletrodomésticos
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      id="eletro"
                                      placeholder=""
                                      type="numeric"
                                      inputMode="numeric"
                                      maxLength={6}
                                      value={this.state.eletro}
                                      onChange={(e) =>
                                        this.setState({
                                          eletro: e.target.value,
                                        })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </div>
                      )}

                      {this.state.tipo_de_imovel === "peixes" && (
                        <div>
                          <h6 className="heading-small text-muted mb-4">
                            Dados do Proprietário
                          </h6>
                          <div className="pl-lg-4">
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-nome"
                                  >
                                    Nome
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-nome"
                                    placeholder=""
                                    type="nome"
                                    value={this.state.nome}
                                    onChange={(e) =>
                                      this.setState({ nome: e.target.value })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-first-cel"
                                  >
                                    Celular
                                  </label>
                                  <InputMask
                                    mask="(99) 99999-9999"
                                    type="text"
                                    name="cel"
                                    id="cel"
                                    className="form-control"
                                    value={this.state.tel}
                                    onChange={(e) =>
                                      this.setState({ tel: e.target.value })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      )}

                      {this.state.tipo_de_imovel === "via_publica" && (
                        <div>
                          <hr className="my-4" />
                          <h6 className="heading-small text-muted mb-4">
                            Via Pública
                          </h6>
                          <div className="pl-lg-4">
                            <Row>
                              <Col md="12">
                                <FormGroup>
                                  <div className="custom-control custom-checkbox mb-3">
                                    <input
                                      className="custom-control-input"
                                      checked={this.state.via_publica_bueiro}
                                      id="customCheckBueiro"
                                      type="checkbox"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="customCheckBueiro"
                                    >
                                      Bueiro
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox mb-3">
                                    <input
                                      className="custom-control-input"
                                      checked={this.state.via_publica_desnivel}
                                      id="customCheckDesnivel"
                                      type="checkbox"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="customCheckDesnivel"
                                    >
                                      Desnivel
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox mb-3">
                                    <input
                                      className="custom-control-input"
                                      checked={this.state.via_publica_canteiro}
                                      id="customCheckCanteiro"
                                      type="checkbox"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="customCheckCanteiro"
                                    >
                                      Canteiro
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox mb-3">
                                    <input
                                      className="custom-control-input"
                                      checked={this.state.via_publica_canaleta}
                                      id="customCheckCanaleta"
                                      type="checkbox"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="customCheckCanaleta"
                                    >
                                      Canaleta
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox mb-3">
                                    <input
                                      className="custom-control-input"
                                      checked={
                                        this.state.via_publica_ponto_de_onibus
                                      }
                                      id="customCheckPonto"
                                      type="checkbox"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="customCheckPonto"
                                    >
                                      Ponto de ônibus
                                    </label>
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      )}

                      {this.state.tipo === "suspeita" && (
                        <div>
                          <hr className="my-4" />
                          <h6 className="heading-small text-muted mb-4">
                            Foi feito exame?
                          </h6>
                          <div className="pl-lg-4">
                            <Row>
                              <Col md="12">
                                <FormGroup>
                                  <div className="custom-control custom-checkbox mb-3">
                                    <input
                                      className="custom-control-input"
                                      checked={this.state.exame_dengue}
                                      id="customCheck4"
                                      type="checkbox"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="customCheck4"
                                    >
                                      Dengue
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox mb-3">
                                    <input
                                      className="custom-control-input"
                                      checked={this.state.exame_zika}
                                      id="customCheck4"
                                      type="checkbox"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="customCheck4"
                                    >
                                      Zica
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox mb-3">
                                    <input
                                      className="custom-control-input"
                                      checked={this.state.exame_chicungunha}
                                      id="customCheck4"
                                      type="checkbox"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="customCheck4"
                                    >
                                      Chikungunya
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox mb-3">
                                    <input
                                      className="custom-control-input"
                                      checked={this.state.exame_febre_amarela}
                                      id="customCheck4"
                                      type="checkbox"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="customCheck4"
                                    >
                                      Febre Amarela
                                    </label>
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                          <hr className="my-4" />
                        </div>
                      )}
                      {/* 
                    {this.state.tipo === "solicitacao" &&
                      this.state.tipo_de_imovel !== "via_publica" && (
                        <div>
                          <hr className="my-4" />
                          <h6 className="heading-small text-muted mb-4">
                            Vistoria
                          </h6>
                          <div className="pl-lg-4">
                            <Row>
                              <Col md="12">
                                <FormGroup>
                                  <div className="custom-control custom-checkbox mb-3">
                                    <input
                                      className="custom-control-input"
                                      checked={this.state.vistoria}
                                      id="customCheck4"
                                      type="checkbox"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="customCheck4"
                                    >
                                      Realizada
                                    </label>
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      )} */}

                      {this.state.avaliacao !== null &&
                        this.state.avaliacao !== undefined && (
                          <div>
                            <hr className="my-4" />
                            <h6 className="heading-small text-muted mb-4">
                              Avaliação
                            </h6>
                            <div className="pl-lg-4">
                              <Row>
                                <Col md="12">
                                  <FormGroup>
                                    <div className="custom-control text-left">
                                      <BeautyStars
                                        value={this.state.avaliacao.nota}
                                        size={"30px"}
                                        maxStars={5}
                                        inactiveColor="Black"
                                        activeColor="#fdbe37"
                                        editable="false"
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        )}

                      {this.state.tipo === "solicitacao" && (
                        <div>
                          <hr className="my-4" />
                          {/* Address */}
                          <h6 className="heading-small text-muted mb-4">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                this.setState({
                                  showMedia: !this.state.showMedia,
                                })
                              }
                            >
                              Fotos & Vídeo
                            </a>
                          </h6>
                          <div className="pl-lg-4">
                            <Collapse isOpen={this.state.showMedia}>
                              <Row>
                                <Col lg="12">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-email"
                                    >
                                      Foto (1 a 5)
                                    </label>
                                    <ImageUploader
                                      withIcon={false}
                                      withLabel={false}
                                      buttonText=""
                                      multiple
                                      onChange={this.onDrop}
                                      singleImage={false}
                                      imgExtension={[".jpg", ".jpeg", "png"]}
                                      fileSizeError=" O arquivo deve ter até 5mb"
                                      fileTypeError=" Extensão do arquivo não suportada"
                                      maxFileSize={8888888}
                                      buttonClassName={
                                        this.state.pictures.length !== 5
                                          ? "zoo-feed-photo"
                                          : "zoo-feed-photo-full"
                                      }
                                    />{" "}
                                  </FormGroup>
                                </Col>
                              </Row>

                              {this.state.pictures.length > 0 && (
                                <Row>
                                  {this.state.pictures.map((picture, index) => (
                                    <Col lg="4">
                                      <FormGroup>
                                        <Button
                                          type="button"
                                          className="btn-media"
                                          title=""
                                          onClick={() => this.removeItem(index)}
                                        >
                                          <FontAwesomeIcon
                                            icon={faTimesCircle}
                                            className="edit"
                                          />
                                        </Button>
                                        <img
                                          src={picture}
                                          class="img-fluid"
                                          alt="Responsive"
                                        />
                                      </FormGroup>
                                    </Col>
                                  ))}
                                </Row>
                              )}

                              <Row>
                                <Col lg="12">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-email"
                                    >
                                      Vídeo (1)
                                    </label>
                                    <div className="zoo-video-container">
                                      <input
                                        className="zoo-feed-video"
                                        type="file"
                                        onChange={this.onFileChange}
                                      />
                                    </div>

                                    {this.state.selectedFile !== "" && (
                                      <>
                                        <Video
                                          controls={[
                                            "PlayPause",
                                            "Seek",
                                            "Time",
                                            "Volume",
                                            "Fullscreen",
                                          ]}
                                          onCanPlayThrough={() => {
                                            // Do stuff
                                          }}
                                        >
                                          <source
                                            src={this.state.video}
                                            type="video/webm"
                                          />
                                          <track
                                            label="English"
                                            kind="subtitles"
                                            srcLang="en"
                                            src="http://source.vtt"
                                            default
                                          />
                                        </Video>

                                        <Button
                                          type="button"
                                          className="btn-media"
                                          title=""
                                          onClick={(e) =>
                                            this.setState({
                                              selectedFile: "",
                                              video: "",
                                            })
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faTimesCircle}
                                            className="edit"
                                          />
                                        </Button>
                                      </>
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Collapse>
                          </div>
                        </div>
                      )}

                      <hr className="my-4" />

                      <div className="text-center">
                        <Button
                          className="mt-4 btn-zoo"
                          color="primary"
                          type="button"
                          onClick={this.handleSave}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  )}
                  {this.state.requestingOS && (
                    <div className="text-center">
                      <Spinner
                        style={{ width: "3rem", height: "3rem" }}
                        color="primary"
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default TicketNew;
