// core components
import UserHeader from "components/Headers/UserHeader.js";
import React from "react";
import Geocode from "react-geocode";
import "react-html5video/dist/styles.css";
import SweetAlert2 from "react-sweetalert2";
// reactstrap components
import {
  Button,
  Card,
  CardBody, CardHeader,
  Col, Container, Form, FormGroup,
  Input,
  Row,
  Spinner
} from "reactstrap";
import api from "shared/resources/Api";
import Api from "shared/resources/Api";
import gconf from "shared/resources/Gconf";
import { cepMask } from "shared/services/Mask";
import { neighborhood } from "shared/services/PlacesService";
import { viaCep } from "shared/services/ViaCep";
import { workersByTeam } from "shared/services/WorkerService";
import { equipes } from "shared/services/PlacesService";



class ItineraryNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      status: "",
      bairro: "",
      response: "",
      api: api.baseURL,
      messageList: [],
      chatCount: 0,
      counter: 0,
      requesting: false,
      isSuccess: false,
      quarteirao: 0,
      tratamento: "",
      equipe: "",
      neighborhood: [],
      workers: [],
      productions: [],
      selectedNeighborhood: "",
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      equipes: [],
      lat: "",
      lon: "",
      itineraryId: 0,
      selectedWorker: [],
      selectedBairro: [],
      selectedStatus: [],
      selectedProducao: [],
      selectedQt: [],
      selectedClosed: []
    };

    this.handleSave = this.handleSave.bind(this);

  }

  componentWillMount() {

    neighborhood().then((res) => {
      if (res) {
        this.setState({
          neighborhood: res,
        });
      }
    });

    equipes().then((res) => {
      if (res) {
        this.setState({
          equipes: res,
        });
      }
    });

  }

  fetchEquipes = async () => {
    const response = await fetch(Api.baseURL + "/unidade_saude/equipe", {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        sectors: body,
      });
    }

    return body;
  };


  consultarCep = () => {
    if (this.state.cep && this.state.cep.replace(/\D/g, "").length === 8) {
      viaCep(this.state.cep)
        .then((res) => {
          if (res && !res.erro) {
            const idx = this.state.neighborhood.findIndex(
              (p) => p.nome === res.bairro
            );
            this.setState({
              ...this.state,
              bairro: res.bairro,
              logradouro: res.logradouro.trim(),
              selectedNeighborhood:
                idx > -1
                  ? this.state.neighborhood[idx].id
                  : this.state.selectedNeighborhood,
            });
            this.consultarLatLong();
          } else {
            this.setState({
              swal: {
                show: true,
                title: "Atenção",
                text: "Nenhum Endereço localizado.",
                type: "warning",
              },
            });
          }
        })
        .catch((err) => {
          this.setState({
            swal: {
              show: true,
              title: "Atenção",
              text: "Nenhum Endereço localizado.",
              type: "warning",
            },
          });
          console.log(err);
        });
    }
  };

  handleSave(event) {
    if (this.validateItineraryFields()) {
      this.callSaveItinerary();
    }
  }

  validateItineraryFields() {
    /* Validação obrigatoriedade */


    if (this.state.cep === undefined || this.state.cep === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o CEP.",
          type: "warning",
        },
      });

      return false;
    }

    if (
      this.state.cep === undefined ||
      this.state.cep.replace(/\D/g, "").length !== 8
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "CEP inválido",
          type: "warning",
        },
      });

      return false;
    }

    if (this.state.logradouro === undefined || this.state.logradouro === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Logradouro.",
          type: "warning",
        },
      });

      return false;
    }

    if (this.state.selectedNeighborhood === undefined || this.state.selectedNeighborhood === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe a Localidade.",
          type: "warning",
        },
      });

      return false;
    }

    if (this.state.numero === undefined || this.state.numero === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Número.",
          type: "warning",
        },
      });

      return false;
    }


    if (this.state.equipe === undefined || this.state.equipe === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe a Equipe.",
          type: "warning",
        },
      });

      return false;
    }

    if (this.state.tratamento === undefined || this.state.tratamento === "") {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Tratamento.",
          type: "warning",
        },
      });

      return false;
    }

    return true;
  }


  validateProductionFields(index) {
    /* Validação obrigatoriedade */


    if (this.state.selectedStatus[index] === "" || this.state.selectedStatus[index] === undefined) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Status da Produção.",
          type: "warning",
        },
      });

      return false;
    }

    if (this.state.selectedStatus[index] === "compareceu" && (this.state.selectedBairro[index] === undefined || this.state.selectedBairro[index] === "")) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe A localidade da Produção.",
          type: "warning",
        },
      });

      return false;
    }

    if (this.state.selectedStatus[index] === "compareceu" && (this.state.selectedQt[index] === undefined || this.state.selectedQt[index] === "" || this.state.selectedQt[index] === 0)) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Quarteirão.",
          type: "warning",
        },
      });

      return false;
    }

    return true;
  }


  createItineraryBody = () => {

    return JSON.stringify({

      equipe: this.state.equipe,
      tratamento: this.state.tratamento,
      geolocalizacao: {
        lat: this.state.lat,
        lon: this.state.lng
      },
      bairro: this.state.selectedNeighborhood
    });

  };




  getReverseGeocoding = () => {
    Geocode.fromLatLng(this.state.lat, this.state.lng).then(

      response => {
        var cep = '';
        if (response.results !== undefined && response.results.length > 0 && this.state.neighborhood.length > 0 && response.results[0].address_components.length > 0) {
          response.results[0].address_components.forEach((item) => {
            if (item.types.find(p => p.includes('postal_code')) !== undefined) {
              cep = item.short_name
              console.log(cep)
            }
          });

          if (cep !== '') {

            this.setState({
              requesting: false,
              cep: cep
            }, () => this.consultarCep());
          }
        } else {
          this.setState({
            swal: {
              show: true,
              title: "Atenção",
              text: "Não foi possível obter sua localização. Informe o endereço manualmente.",
              type: "warning",
            },
            requesting: false,
          });
        }
      },
      error => {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Não foi possível obter sua localização. Informe o endereço manualmente.",
            type: "warning",
          },
          requesting: false,
        });
      }
    )

  }

  getIdBairroByName = () => {

    Geocode.setApiKey(gconf.key);
    this.setState({ requesting: true })

    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };

    const success = position => {
      this.setState({ requesting: true })
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      this.setState({
        lat: latitude,
        lng: longitude
      }, () => this.getReverseGeocoding());
    };

    function error() {
      this.setState({
        swal: {
          show: true,
          title: "Erro",
          text: "Não foi possível obter sua localização. Selecione um bairro na lista.",
          type: "error",
        },
        requesting: false,
      });
    }

    navigator.geolocation.getCurrentPosition(success, error, options);

    return 2;
  }

  getProductionsByItineraryId = async () => {


    try {
      const response = await fetch(api.baseURL + "/producao?itinerario=" + this.state.itineraryId, {
        headers: {
          Authorization: localStorage.getItem("agToken"),
        },
      });
      const body = await response.json();

      if (response.status === 200) {
        console.log('success productions', body)
        this.setState({
          requesting: false,
          productions: body
        });
      } else {
        this.setState({
          requesting: false,
        });
      }

    }
    catch (err) {
      // not jumping in here.
      console.log(err)
      this.setState({
        swal: {
          show: true,
          title: "Erro",
          text: "Erro ao consultar Produções para este Itinerário",
          type: "error",
        },
        selectedOs: null,
        requesting: false,
      });
    }
  };


  callSaveItinerary = async () => {

    try {
      const response = await fetch(api.baseURL + "/itinerario", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("agToken"),
        },
        body: JSON.stringify({
          tratamento: this.state.tratamento,
          equipe: this.state.equipe,
          cod_bairro: this.state.selectedNeighborhood,
          geolocalizacao: {
            lat: this.state.lat.toString(),
            lon: this.state.lon.toString()
          },
          endereco: {
            cep: this.state.cep,
            logradouro: this.state.logradouro.trim(),
            bairro: this.state.bairro,
            numero: this.state.numero,
            complemento: this.state.complemento
          },
        })
      });
      const body = await response.json();
      console.log('success itinerary', JSON.stringify(body))
      if (response.status === 200) {
        this.setState({
          swal: {
            show: true,
            title: "Tudo Certo",
            text: "Itinerário cadastrado com sucesso! Preencha os dados de Produção.",
            type: "success",
          },
          requesting: false,
          isSuccess: true,
          itineraryId: body.id
        }, () => this.getProductionsByItineraryId());
      } else {
        this.setState({
          swal: {
            show: true,
            title: "Erro",
            text: body.msg,
            type: "error",
          },
          selectedOs: null,
          requesting: false,
        });
      }

    }
    catch (err) {
      // not jumping in here.
      console.log(err)
      this.setState({
        swal: {
          show: true,
          title: "Erro",
          text: "Erro ao cadastrar Itinerário",
          type: "error",
        },
        selectedOs: null,
        requesting: false,
      });
    }

  };


  callSaveProduction = async (index, userId) => {


    try {



      const response2 = await fetch(
        api.baseURL +
          "/unidade_saude/itinerario" ,
          
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("agToken"),
          },
          body: JSON.stringify({
            
            itinerario: this.state.itineraryId,
          }),
        }
      );








      const response = await fetch(api.baseURL + "/producao/" + this.state.productions.find(p => p.usuario === userId).id, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("agToken"),
        },
        body: JSON.stringify({
          cod_bairro: this.state.selectedStatus[index] === 'compareceu' ? this.state.selectedBairro[index] : 0,
          usuario: this.state.workers[index].id,
          itinerario: this.state.itineraryId,
          producao: 0,
          estado: this.state.selectedStatus[index] === 'compareceu' ? 'aberto' : 'fechado',
          fechado: "nao",
          status: this.state.selectedStatus[index],
          quarteirao: (this.state.selectedQt[index] !== undefined && this.state.selectedQt[index] !== null && this.state.selectedQt[index] !== "" && this.state.selectedStatus[index] === 'compareceu') ? this.state.selectedQt[index] : null,
        })
      });

      const body = await response.json();

      if (response.status === 200) {
        const productions = await this.getProductionsByItineraryId();
        console.log(productions)
        this.setState({
          swal: {
            show: true,
            title: "Tudo Certo",
            text: "Produção enviada com sucesso.",
            type: "success",
          },
          requesting: false
        });

      } else {
        this.setState({
          swal: {
            show: true,
            title: "Erro",
            text: body.msg,
            type: "error",
          },
          selectedOs: null,
          requesting: false,
        });
      }

    }
    catch (err) {
      // not jumping in here.
      console.log(err)
      this.setState({
        swal: {
          show: true,
          title: "Erro",
          text: "Erro ao cadastrar Produção",
          type: "error",
        },
        selectedOs: null,
        requesting: false,
      });
    }
  };




  handleSaveProducao = (index, userId) => {

    if (this.validateProductionFields(index)) {
      this.callSaveProduction(index, userId);
    }
  };


  handleTratamento(event) {
    this.setState({ tratamento: event.target.value })
  }

  handleProductionWorkerArray = (index, newValue) => {
    const updatedArray = [...this.state.selectedWorker];
    updatedArray[index] = newValue;
    this.setState({
      selectedWorker: updatedArray,
    });
  };

  handleProductionBairroArray = (index, newValue) => {
    const updatedArray = [...this.state.selectedBairro];
    updatedArray[index] = newValue;
    this.setState({
      selectedBairro: updatedArray,
    });
  };

  handleProductionStatusArray = (index, newValue) => {
    const updatedArray = [...this.state.selectedStatus];
    updatedArray[index] = newValue;
    this.setState({
      selectedStatus: updatedArray,
    });
  };


  handleProductionArray = (index, newValue) => {
    const updatedArray = [...this.state.selectedProducao];
    updatedArray[index] = newValue;
    this.setState({
      selectedProducao: updatedArray,
    });
  };

  handleProductionQtArray = (index, newValue) => {

    const updatedArray = [...this.state.selectedQt];
    updatedArray[index] = newValue;
    this.setState({
      selectedQt: updatedArray,
    });
  };

  handleProductionClosedArray = (index, newValue) => {
    const updatedArray = [...this.state.selectedClosed];
    updatedArray[index] = newValue;
    this.setState({
      selectedClosed: updatedArray,
    });
  };

  handleProductionSuccessArray = (index, newValue) => {
    const updatedArray = [...this.state.selectedProductionSuccess];
    updatedArray[index] = newValue;
    this.setState({
      selectedProductionSuccess: updatedArray,
      swal: {
        show: true,
        title: "Tudo Certo",
        text: "Produção cadastrada com sucesso.",
        type: "success",
      },
      requesting: false,
    }, () => console.log(this.state.selectedProductionSuccess, 'array sucesso'));
  };

  handleEquipe(event) {
    var equipe = event.target.value;
    workersByTeam(event.target.value).then((res) => {
      if (res) {
        this.setState({
          workers: res,
          equipe: equipe
        }, () => { this.handleNoMembersTeam() });
      }
    })
  }

  handleNoMembersTeam(event) {
    if (!this.state.workers.length > 0) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Não existem servidores alocados nesta equipe.",
          type: "warning",
        },
        requesting: false,
      });
    }
  }



  blurEndereco = () => {
    this.consultarLatLong();
  };

  consultarLatLong = () => {
    this.setState({ isMapOk: false });
    const addres = `${this.state.logradouro}, ${this.state.numero}, ${this.state.bairro}, ${this.state.cep}`;
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${addres}&key=${gconf.key}`
    )
      .then((response) => response.json())
      .then((res) => {
        if (res && res.status === "OK") {
          console.log(res);
          if (
            res.results.length > 0 &&
            res.results[0].geometry &&
            res.results[0].geometry.location
          ) {
            this.setState({
              ...this.state,
              lat: res.results[0].geometry.location.lat,
              lon: res.results[0].geometry.location.lng,
              isMapOk: true,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          lat: -18.90971,
          lon: -48.261921,
          isMapOk: true,
        });
      });
  };



  renderInputs(value) {
    const inputs = [];

    this.state.workers.map((team, index) => (

      inputs.push(

        <>
          <Row>
            <Col lg="12">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-address"
                >
                  Servidor: {team.nome}
                </label>
              </FormGroup>
            </Col>
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-address">
                Situação Funcional: {
            (() => {
              if (!team.situacao_funcional) {
                return "Ativo na Função";
              }
              switch (team.situacao_funcional.toLowerCase()) {
                case 'abandono':
                  return 'Abandono';
                case 'afastado':
                  return 'Atestado Médico';
                case 'ativo_em_funcao':
                  return 'Ativo na função';
                case 'cedido':
                  return 'Cedido-Outra unidade PMU';
                case 'convocacao_juri_popular':
                  return 'Convocação - Juri Popular';
                case 'desligado':
                  return 'Desligado';
                case 'ferias':
                  return 'Férias';
                case 'justica_eleitoral':
                  return 'Justiça Eleitoral';
                case 'licenca_interesses_pessoais':
                  return 'Licença Interesses Pessoais';
                case 'licenca_maternidade':
                  return 'Licença Maternidade';
                case 'readaptado':
                  return 'Readaptado';
                case 'ativo':
                  return 'Ativo na Função';
                default:
                  return "-";
              }
            })()
          }
                </label>
              </FormGroup>

              {team.situacao_funcional && team.situacao_funcional.trim().toLowerCase() !== 'ativo' && team.fim_inatividade !== null && team.fim_inatividade.trim() !== '' && (
              <FormGroup>
                <label className="form-control-label" htmlFor="input-address">
                  Data Final de Inatividade: {team.fim_inatividade}
                </label>
              </FormGroup>
            )}

            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-username"
                >
                  Status
                            </label>

                <select
                  value={this.state.selectedStatus[index]}
                  disabled={
                    String(team.situacao_funcional).toLowerCase() === "afastado" 
                    || String(team.situacao_funcional).toLowerCase() === "desligado"
                  || String(team.situacao_funcional).toLowerCase() === "licenca_maternidade"
                  || String(team.situacao_funcional).toLowerCase() === "licenca_interesses_pessoais"
                  || String(team.situacao_funcional).toLowerCase() === "ferias"
                  || String(team.situacao_funcional).toLowerCase() === "abandono"
                  || String(team.situacao_funcional).toLowerCase() === "justica_eleitoral"
                    ||
                    this.state.productions.find(p => p.usuario === team.id && p.estado !== 'pendente')}




                  onChange={e => this.handleProductionStatusArray(index, e.target.value)}
                  className="form-control "
                  id="select-tipo"
                >
                  <option value="">Selecione...</option>
                  <option value="atestado">Atestado</option>
                  <option value="check_in">Check In</option>
                  <option value="compareceu">Compareceu</option>
                  <option value="faltou">Faltou</option>
                  <option value="ferias">Ferias</option>
                </select>
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <h5 style={{ marginTop: 10 }} className="mb-0">
                  Localidade
                        </h5>
                <select
                  value={this.state.selectedBairro[index]}
                  disabled={this.state.selectedStatus[index] !== "compareceu" || this.state.productions.find(p => p.usuario === team.id).estado !== 'pendente'}
                  onChange={e => this.handleProductionBairroArray(index, e.target.value)}
                  className="form-control "
                >
                  <option value="">Selecione...</option>

                  {this.state.neighborhood.map((bairro) => (
                    <option
                      key={bairro.id}
                      value={bairro.id}
                      defaultValue={
                        this.state.selectedBairro
                      }
                    >
                      {bairro.nome}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-address"
                >
                  Quarteirão
</label>

                <Input
                  className="form-control"
                  id="input-qt"
                  type="quarteirao"
                  maxLength="20"
                  autoComplete="new-qt"
                  required
                  disabled={this.state.selectedStatus[index] !== "compareceu" || this.state.productions.find(p => p.usuario === team.id).estado !== 'pendente'}
                  value={this.state.selectedQt[index]}
                  onChange={e => this.handleProductionQtArray(index, e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg="3" style={{ paddingTop: '2.1%' }}>
              <FormGroup>
                <Button
                  className={this.state.productions.find(p => p.usuario === team.id && p.status != null) ? 'btn-zoo-suc' : 'btn-zoo-alt'}
                  color="primary"
                  type="button"
                  disabled={
                    String(team.situacao_funcional).toLowerCase() === "afastado" 
                  || String(team.situacao_funcional).toLowerCase() === "desligado"
                  || String(team.situacao_funcional).toLowerCase() === "licenca_maternidade"
                  || String(team.situacao_funcional).toLowerCase() === "licenca_interesses_pessoais"
                  || String(team.situacao_funcional).toLowerCase() === "ferias"
                  || String(team.situacao_funcional).toLowerCase() === "abandono"
                  || String(team.situacao_funcional).toLowerCase() === "justica_eleitoral"
                }
                  onClick={() => {
                    this.handleSaveProducao(index, team.id);
                  }}
                >
                  {this.state.productions.find(p => p.usuario === team.id && p.status != null) ? 'Enviado' : 'Enviar'}
                </Button>
              </FormGroup>
            </Col>
          </Row>
          <hr className="my-4" />
        </>
      )
    )
    )
    return inputs;
  }

  render() {
    return (
      <>
        <UserHeader />
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });
          }}
        />
        {/* Page content */}
        <Container className="main-zoo" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">

                <CardHeader className="bg-white border-0">
                  {this.state.requesting && (
                    <div className="text-center">
                      <Row className="align-items-center">
                        <Col xs="12">
                          <Spinner
                            style={{ width: "3rem", height: "3rem" }}
                            color="primary"
                          />
                        </Col>

                      </Row>
                    </div>

                  )}
                  {!this.state.requesting && (
                    <>
                      <Row className="align-items-center">
                        <Col xs="3">
                          <div className="text-left">
                            <h3 className="mb-0">Novo Itinerário</h3>
                            <Button
                              className="mt-3 btn-zoo-alt"
                              color="primary"
                              disabled={this.state.itineraryId > 0}
                              type="button"
                              onClick={() => {
                                this.getIdBairroByName();
                              }}
                            >
                              Obter Localização
                      </Button>
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center  mt-2">
                        <Col xs="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              CEP
</label>
                            <Input
                              className="form-control-alternative"
                              id="input-cep"
                              type="text"
                              disabled={this.state.itineraryId > 0}
                              maxLength={9}
                              inputMode="numeric"
                              required
                              value={this.state.cep}
                              onChange={(e) =>
                                this.setState({ cep: cepMask(e.target.value) })
                              }
                              onBlur={() => this.consultarCep()}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="8">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Logradouro
</label>
                            <Input
                              className="form-control-alternative"
                              id="input-endereco"
                              type="text"
                              disabled={this.state.itineraryId > 0}
                              maxLength={100}
                              required
                              value={this.state.logradouro}
                              onChange={(e) =>
                                this.setState({ logradouro: e.target.value })
                              }
                              onBlur={() => this.blurEndereco()}
                            />
                          </FormGroup>
                        </Col>

                      </Row>
                      <Row className="align-items-center">
                        <Col xs="4">
                          <FormGroup>
                            <h5 style={{ marginTop: 10 }} className="mb-0">
                              Localidade
                        </h5>
                            <select
                              value={this.state.selectedNeighborhood}
                              disabled={this.state.itineraryId > 0}
                              onChange={(e) =>
                                this.setState({
                                  selectedNeighborhood: e.target.value,
                                })
                              }
                              className="form-control "
                            >
                              <option value="">Selecione...</option>

                              {this.state.neighborhood.map((team) => (
                                <option
                                  key={team.id}
                                  value={team.id}
                                  defaultValue={
                                    this.state.selectedNeighborhood
                                  }
                                >
                                  {team.nome}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col xs="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Número
</label>

                            <Input
                              className="form-control-alternative"
                              id="input-num"
                              type="text"
                              disabled={this.state.itineraryId > 0}
                              required
                              maxLength={8}
                              value={this.state.numero}
                              onChange={(e) =>
                                this.setState({ numero: e.target.value })
                              }
                              onBlur={() => this.blurEndereco()}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Complemento
</label>
                            <Input
                              className="form-control-alternative"
                              id="input-comp"
                              type="text"
                              disabled={this.state.itineraryId > 0}
                              maxLength={50}
                              required
                              value={this.state.complemento}
                              onChange={(e) =>
                                this.setState({ complemento: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col xs="6">
                        <FormGroup>
                            {this.state.equipes.length > 0 && (
                              <div>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-dtnascimento"
                                >
                                  Equipe
                                </label>

                                <select
                                  value={this.state.equipe}
                                  onChange={(e) => {
                                    this.handleEquipe(e);
                                  }}
                                  className="form-control "
                                >
                                  <option defaultValue="" value="">
                                    {" "}
                                    Selecione...{" "}
                                  </option>
                                  {this.state.equipes.map((team) => (
                                    <option key={team.nm_equipe} value={team.nm_equipe}>
                                    {team.ds_descricao_equipe.charAt(0).toUpperCase() +
                                      team.ds_descricao_equipe.slice(1).toLowerCase()}
                                  </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          </FormGroup>
                          {/* <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Equipe
                            </label>

                            <select
                              value={this.state.equipe}
                              disabled={this.state.itineraryId > 0}
                              onChange={(e) => {
                                this.handleEquipe(e);
                              }}
                              className="form-control "
                              id="select-tipo"
                            >
                              <option value="">Selecione...</option>
                              <option value="equipe_01">Equipe 1</option>
                              <option value="equipe_02">Equipe 2</option>
                              <option value="equipe_03">Equipe 3</option>
                              <option value="equipe_04">Equipe 4</option>
                              <option value="equipe_05">Equipe 5</option>
                              <option value="equipe_06">Equipe 6</option>
                              <option value="equipe_07">Equipe 7</option>
                              <option value="equipe_08">Equipe 8</option>
                              <option value="equipe_09">Equipe 9</option>
                              <option value="equipe_10">Equipe 10</option>
                              <option value="equipe_11">Equipe 11</option>
                              <option value="equipe_12">Equipe 12</option>
                              <option value="equipe_13">Equipe 13</option>
                              <option value="equipe_14">Equipe 14</option>
                              <option value="equipe_15">Equipe 15</option>
                              <option value="equipe_16">Equipe 16</option>
                              <option value="equipe_17">Equipe 17</option>
                              <option value="equipe_18">Equipe 18</option>
                              <option value="equipe_19">Equipe 19</option>
                              <option value="equipe_20">Equipe 20</option>
                              <option value="equipe_21">Equipe 21</option>
                              <option value="equipe_22">Equipe 22</option>
                              <option value="equipe_23">Equipe 23</option>
                              <option value="equipe_24">Equipe 24</option>
                              <option value="equipe_25">Equipe 25</option>
                              <option value="equipe_26">Equipe 26</option>
                              <option value="equipe_27">Equipe 27</option>
                              <option value="equipe_28">Equipe 28</option>
                              <option value="equipe_29">Equipe 29</option>
                              <option value="equipe_30">Equipe 30</option>
                              <option value="acoes">Ações</option>
                              <option value="administrativo">Administrativo</option>
                              <option value="app_drone_mob">App Drone Mob</option>
                              <option value="fog">Fog</option>
                              <option value="material">Material</option>
                              <option value="miraporanga">Miraporanga</option>
                              <option value="tapuirama">Tapuirama</option>
                              <option value="mobilizacao">Mobilização</option>
                              <option value="motorista">Motorista</option>
                              <option value="ovitrampas">Ovitrampas</option>
                              <option value="pe_a">PE_A</option>
                              <option value="pe_a_adm">PE_A_ADM</option>
                              <option value="pe_b">PE_B</option>
                              <option value="pe_b_adm">PE_B_ADM</option>
                              <option value="pe_c">PE_C</option>
                              <option value="pe_c_adm">PE_C_ADM</option>
                              <option value="pe_d">PE_D</option>
                              <option value="pe_d_adm">PE_D_ADM</option>
                              <option value="pe_e">PE_E</option>
                              <option value="pe_e_adm">PE_E_ADM</option>
                              <option value="pe_f">PE_F</option>
                              <option value="pe_f_adm">PE_F_ADM</option>
                              <option value="pe_g">PE_G</option>
                              <option value="pe_g_adm">PE_G_ADM</option>
                              <option value="pedras">Pedras</option>
                              <option value="pneu">Pneu</option>
                              <option value="raio">Raio</option>
                              <option value="supervisao_area">Supervisão Área</option>
                              <option value="supervisao_turma">Supervisão Turma</option>
                              <option value="supervisao_geral">Supervisão Geral</option>
                            </select>
                          </FormGroup> */}
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"

                            >
                              Tratamento
                            </label>

                            <select
                              value={this.state.tratamento}
                              disabled={this.state.itineraryId > 0}
                              onChange={(e) => {
                                this.handleTratamento(e);
                              }}
                              className="form-control "
                              id="select-tipo"
                            >
                             <option value="">selecione...</option>
                              <option value="primeiro">1º - Tratamento</option>
                              <option value="segundo">2º - Tratamento</option>
                              <option value="terceiro">3º - Tratamento</option>
                              <option value="quarto">4º - Tratamento</option>
                              <option value="quinto">5º - Tratamento</option>
                              <option value="sexto">6º - Tratamento</option>
                              <option value="lia_pesquisa">LIA - Pesquisa</option>
                              <option value="liraa">LIRAa - Pesquisa</option>
                              <option value="monitoramento">Monitoramento</option>
                              <option value="tpve">TPVE - Tratamento</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                      {this.state.workers.length > 0 && this.state.itineraryId === 0 && (
                        <Row className="align-items-center">
                          <Col xs="12">

                            <div className="text-center">
                              <Button
                                className="mt-4 btn-zoo"
                                color="primary"
                                type="button"
                                onClick={() => {
                                  this.handleSave();
                                }}
                              >
                                Cadastrar Itinerário
</Button>
                            </div>
                          </Col>

                        </Row>
                      )}

                    </>
                  )}

                </CardHeader>
                {this.state.itineraryId > 0 && (
                  <CardBody>
                    <Form>

                      <div>
                        <hr className="my-4" />
                        {/* Address */}
                        <h3 className="mb-4">
                          Produção
                        </h3>
                        <>
                          {this.state.workers.length > 0 && this.state.itineraryId > 0 && (
                            this.renderInputs(4)
                          )}
                          {this.state.workers.length === 0 && this.state.itineraryId > 0 && (
                            <Row className="align-items-center">
                              <Col xs="12">

                                <div className="text-center">
                                  <h3 className="mb-4">
                                    Não existem servidores alocados nesta Equipe.
                        </h3>
                                </div>
                              </Col>

                            </Row>
                          )}
                        </>
                      </div>
                      {/* 
                    <hr className="my-4" /> */}

                      {/* <div className="text-center">
                      <Button
                        className="mt-4 btn-zoo"
                        color="primary"
                        type="button"
                        onClick={this.handleSave}
                      >
                        Salvar Produção
                      </Button>
                    </div> */}
                    </Form>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ItineraryNew;
