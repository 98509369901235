/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SweetAlert2 from "react-sweetalert2";
import BeautyStars from "beauty-stars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
  Collapse,
} from "reactstrap";

import {
  Widget,
  addResponseMessage,
  addUserMessage,
  dropMessages,
} from "react-chat-widget";
import "react-chat-widget/lib/styles.css";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import api from "shared/resources/Api";
import { cpfMask } from "shared/services/Mask";
import InputMask from "react-input-mask";
import moment from "moment";
import Util from "shared/services/Util";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import ReactAudioPlayer from "react-audio-player";
import InputNumber from "react-input-just-numbers";
import DataTable from "react-data-table-component";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

class TicketEdit extends React.Component {
  columns = [
    {
      name: "Endereço",
      selector: "endereco",
      sortable: true,
    },
    {
      name: "Bairro",
      selector: "bairro",
      sortable: true,
      cell: (row) => <div>{row.bairro ? row.bairro : "-"}</div>,
    },
    {
      name: "Ação",
      cell: (row) => (
        <div>
          <Button
            type="button"
            className="btn-acao "
            title="Escolher"
            onClick={() => {
              this.handleSelectedAddress(row);
            }}
          >
            <FontAwesomeIcon icon={faEdit} className="edit" />
            Escolher
          </Button>
        </div>
      ),
    },
  ];
  defaultComponentOptions = {
    rowsPerPageText: "Registros por Página:",
    rangeSeparatorText: "de",
    noRowsPerPage: false,
  };
  customStyles = {
    // cells: {
    //   style: {
    //     backgroundColor: "#F7FAFC"
    //   },
    // },
    table: {
      style: {
        backgroundColor: "#F7FAFC",
      },
    },
    headCells: {
      style: {
        fontWeight: 700,
        fontSize: 15,
        color: "#000",
        backgroundColor: "#F7FAFC",
      },
    },
  };
  constructor(props) {
    super(props);
    registerLocale("pt", pt);
    this.state = {
      nome: "",
      cpf: "",
      tel: "",
      status: "",
      data_nascimento: "",
      responseAddress: "",
      selectedAddress: "",
      logradouro: "",
      bairro: "",
      numero: "",
      complemento: "",
      response: "",
      api: api.baseURL,
      citizen: [],
      agents: [],
      messageList: [],
      chatCount: 0,
      counter: 0,
      requesting: false,
      isSuccess: false,
      selectedAgent: 0,
      tipo: "solicitacao",
      showFoto: false,
      showVideo: false,
      showAudio: false,
      obs: "",
      quarteirao: "",
      dt_iac_visita: "",
      ds_iac_removido_solucionado: "",
      ds_iac_descaracterizado: "",
      ds_iac_tratado: "",
      ds_iac_nao_tratado_morador: "",
      ds_iac_mao_tratado: "",
      ds_iac_observacao: "",
      oid_fornecedor_servico: "",
      ds_poi: "",
      ds_volume_estimado_litros: "",
      ds_pastilhas_naturalar_dt: "",
      ds_granulado_bti: "",
      
      
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleShowExame = this.handleShowExame.bind(this);
    this.handleShowObjeto = this.handleShowObjeto.bind(this);
    this.handleShowPneu = this.handleShowPneu.bind(this);
  }

  componentWillMount() {
    if (
      this.props.location != null &&
      this.props.location.state != null &&
      this.props.location.state.selectedOs != null
    ) {
      const selectedOs = this.props.location.state.selectedOs;
      let dt_iac_visita = null;
      if (selectedOs.dt_iac_visita && selectedOs.dt_iac_visita !== "" && selectedOs.dt_iac_visita !== "Invalid Date") {
        const date = moment(selectedOs.dt_iac_visita, moment.ISO_8601, true);
        if (date.isValid()) {
          dt_iac_visita = date.toDate();
        }
      }
      this.setState({
        selectedOs: selectedOs,
        complemento: this.props.location.state.selectedOs.complemento,
        logradouro: this.props.location.state.selectedOs.logradouro.trim(),
        bairro: this.props.location.state.selectedOs.bairro,
        horario_criacao: this.props.location.state.selectedOs.horario_criacao,
        data_abertura: this.props.location.state.selectedOs.data_abertura,
        descricao: this.props.location.state.selectedOs.descricao,
        numero: this.props.location.state.selectedOs.numero,
        quarteirao: this.props.location.state.selectedOs.quarteirao,
        lado: this.props.location.state.selectedOs.lado,
        os: this.props.location.state.os,
        loading: false,
        selectedAgent: this.props.location.state.selectedOs.agente
          ? this.props.location.state.selectedOs.agente.id
          : 0,
        selectedStatus: this.props.location.state.selectedOs.status,
        obs:
          this.props.location.state.selectedOs.obs_ultima_acao !== undefined &&
          this.props.location.state.selectedOs.obs_ultima_acao !== null
            ? this.props.location.state.selectedOs.obs_ultima_acao
            : "",
        dt_iac_visita: dt_iac_visita,
        ds_iac_removido_solucionado: this.props.location.state.selectedOs.ds_iac_removido_solucionado,
        ds_iac_descaracterizado: this.props.location.state.selectedOs.ds_iac_descaracterizado,
        ds_iac_tratado: this.props.location.state.selectedOs.ds_iac_tratado,
        ds_iac_nao_tratado_morador: this.props.location.state.selectedOs.ds_iac_nao_tratado_morador,
        ds_iac_mao_tratado: this.props.location.state.selectedOs.ds_iac_mao_tratado,
        ds_iac_observacao: this.props.location.state.selectedOs.ds_iac_observacao,
        oid_fornecedor_servico: this.props.location.state.selectedOs.oid_fornecedor_servico,
        ds_poi: this.props.location.state.selectedOs.ds_poi,
        ds_volume_estimado_litros: this.props.location.state.selectedOs.ds_volume_estimado_litros,
        ds_pastilhas_naturalar_dt: this.props.location.state.selectedOs.ds_pastilhas_naturalar_dt,
        ds_granulado_bti: this.props.location.state.selectedOs.ds_granulado_bti,
      });
    } else {
    }






  }

  componentDidMount() {
    this.fetchAgents();
   

    if (
      this.props.location != null &&
      this.props.location.state != null &&
      this.props.location.state.selectedOs != null
    ) {
      try {
      let endpoint = "os/historico";

      let host = this.state.api + `/${endpoint}?status=&id=${this.state.selectedOs.id}`;
      const respons2 = fetch(host, {
        headers: {
          Authorization: localStorage.getItem("agToken"),
        },
      })
      .then((respons2) => {
        if (!respons2.ok) {
          throw new Error("Network response was not ok");
        }
        return respons2.json();
      })
      .then((body) => {
        
        if (body.length > 0){
          
          this.setState({
            response2: body,
          });



        
      }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });


    
      // Do something with the parsed object
    } catch (e) {
      console.error("Invalid JSON string:", e);
    }


    }


    if (
      this.state.selectedOs.chats !== null &&
      this.state.selectedOs.chats !== undefined &&
      this.state.selectedOs.chats.length > 0
    ) {
      if (this.state.counter === 0) {
        dropMessages(); // clears any messages left in state
        this.loadChats();
      }
    }





  }

  fetchData = () => {
    const { selectedOs } = this.props.location.state || {};
    if (selectedOs) {
      const endpoint = "os/historico";
      const host = `${this.state.api}/${endpoint}?status=&id=${selectedOs.id}`;
      const headers = {
        Authorization: localStorage.getItem("agToken"),
      };
      const signal = new AbortController().signal; // create an AbortController signal
      const options = { headers, signal };
      this.fetchPromise = fetch(host, options)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((body) => {
          if (body.length > 0) {
            const sortedBody = body.sort((a, b) => {
              const aDate = new Date(a.dt_criacao);
              const bDate = new Date(b.dt_criacao);
              console.log('aDate:', aDate, 'bDate:', bDate);
              return bDate - aDate; // descending order
            });


            this.setState({
              response2: sortedBody,
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  loadChats() {
    this.setState(
      {
        chatCount: this.props.location.state.selectedOs.chats.length,
        counter: 1,
      },
      () => {
        this.state.selectedOs.chats.map((item) => {
          item.cidadao
            ? addResponseMessage(item.msg)
            : addUserMessage(item.msg);
          return true;
        });
      }
    );
  }

  

  handleSelectedAddress = (row) => {
  
    this.setState({
      selectedAddress: row,
      logradouro: row.endereco,
      bairro: row.bairro,
    });
  };

  fetchAgents = async () => {
    const response = await fetch(api.baseURL + "/usuario/agente", {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        agents: body,
      });
    }

    return body;
  };

  handleShowPneu() {
    let counter = 0;

    if (
      this.state.selectedOs.pneu_bicicleta !== undefined &&
      this.state.selectedOs.pneu_bicicleta !== null
    ) {
      counter++;
    }

    if (
      this.state.selectedOs.pneu_moto !== undefined &&
      this.state.selectedOs.pneu_moto !== null
    ) {
      counter++;
    }

    if (
      this.state.selectedOs.pneu_carro !== undefined &&
      this.state.selectedOs.pneu_carro !== null
    ) {
      counter++;
    }

    if (
      this.state.selectedOs.pneu_caminhao !== undefined &&
      this.state.selectedOs.pneu_caminhao !== null
    ) {
      counter++;
    }

    if (
      this.state.selectedOs.pneu_camionete !== undefined &&
      this.state.selectedOs.pneu_camionete !== null
    ) {
      counter++;
    }

    if (counter > 0) {
      return true;
    }

    return false;
  }

  handleShowObjeto() {
    let counter = 0;

    if (
      this.state.selectedOs.lona !== undefined &&
      this.state.selectedOs.lona !== null
    ) {
      counter++;
    }

    if (
      this.state.selectedOs.eletro !== undefined &&
      this.state.selectedOs.eletro !== null
    ) {
      counter++;
    }

    if (
      this.state.selectedOs.lata !== undefined &&
      this.state.selectedOs.lata !== null
    ) {
      counter++;
    }

    if (
      this.state.selectedOs.garrafa !== undefined &&
      this.state.selectedOs.garrafa !== null
    ) {
      counter++;
    }

    if (
      this.state.selectedOs.prato !== undefined &&
      this.state.selectedOs.prato !== null
    ) {
      counter++;
    }

    if (counter > 0) {
      return true;
    }

    return false;
  }

  handleShowExame() {
    let counter = 0;

    if (
      this.state.selectedOs.exame_chicungunha !== undefined &&
      this.state.selectedOs.exame_chicungunha !== null
    ) {
      counter++;
    }

    if (
      this.state.selectedOs.exame_dengue !== undefined &&
      this.state.selectedOs.exame_dengue !== null
    ) {
      counter++;
    }

    if (
      this.state.selectedOs.exame_febre_amarela !== undefined &&
      this.state.selectedOs.exame_febre_amarela !== null
    ) {
      counter++;
    }

    if (
      this.state.selectedOs.exame_zika !== undefined &&
      this.state.selectedOs.exame_zika !== null
    ) {
      counter++;
    }

    if (counter > 0) {
      return true;
    }

    return false;
  }

  validateFields() {
    if (
      this.state.tel !== "" &&
      this.state.tel.replace(/\D/g, "").length !== 11
    ) {
      alert("Preencha o Celular com DDD e nono Dígito.");
      return false;
    }

    return true;
  }

  handleNewUserMessage = (newMessage) => {
    this.callChat(newMessage);
     if (this.state.selectedOs.cidadao.device_id){
    this.callOne();
    }
  };

  handleSiat = (event) => {
    if (
      this.state.logradouro === undefined ||
      this.state.logradouro.length === 0
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Logradouro.",
          type: "warning",
        },
      });

      return false;
    } else {
      if (this.state.selectedAddress === "") {
        this.setState({ requesting: true });

        this.fetchSiat()
          .then((res) => this.setState({ responseAddress: res }))
          .catch((err) => {
            console.log(err);
            this.setState({
              swal: {
                show: true,
                title: "Atenção",
                text: "Não foram encontrados endereços para os parâmetros informados.",
                type: "error",
              },
              requesting: false,
            });
          });
      } else {
        this.setState({
          selectedAddress: "",
          logradouro: "",
          bairro: "",
          responseAddress: "",
        });
      }
    }

    event.preventDefault();
  };

  fetchSiat = async () => {
    let host = this.state.api + "/siat_enderecos?";

    let params = {
      endereco: this.state.logradouro,
      bairro: this.state.bairro ? this.state.bairro : "",
    };

    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    const response = await fetch(host + query, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    if (body.length > 0) {
      this.setState({
        requesting: false,
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Não foram encontrados endereços para os parâmetros informados.",
          type: "error",
        },
        requesting: false,
      });
    }
    return body;
  };

  callChat = async (newMessage) => {
    const response = await fetch(
      api.baseURL + "/os/" + this.state.selectedOs.id + "/chat",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("agToken"),
        },
        body: JSON.stringify({
          msg: newMessage,
        }),
      }
    );

   

    const body = await response.json();

    if (response.status === 200) {
    } else {
      alert("Erro ao realizar o Cadastro de Comentario da OS.");
      throw Error(body.message);
    }

    return body;
  };

  callOne = () => { // Removed async since we're not using await
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Basic NzMwZWZjMDQtNzIwMS00ZDY5LWE2ODUtNzk0ODE3Yjk0NGZj",
    };
  
    const data = {
      include_subscription_ids: [
        this.state.selectedOs.cidadao.device_id
      ],
      target_channel: "push",
      headings: {
        en: "Nova Conversa"
      },
      contents: {
        en: "Sua O.S foi respondida, acesse aqui:"
      },
      external_id : this.state.selectedOs.cidadao.device_id,
      url: this.state.selectedOs.tipo === "suspeita" ? "udisemdengue://suspeita?" + this.state.selectedOs.id : "udisemdengue://os?" + this.state.selectedOs.id,
      app_id: "08406467-84e6-40b3-a66d-ce7a1072d9ca"
    };
  
    fetch(
      "https://onesignal.com/api/v1/notifications",
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      }
    );
  };


  handleSave(event) {
    if (
      this.state.logradouro === undefined ||
      this.state.logradouro.length === 0
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Logradouro.",
          type: "warning",
        },
      });

      return false;
    }

    if (
      this.state.bairro === undefined ||
      this.state.bairro === "" ||
      this.state.bairro === null
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Bairro.",
          type: "warning",
        },
      });

      return false;
    }

    if (this.state.numero === undefined || this.state.numero.length === 0) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Número.",
          type: "warning",
        },
      });

      return false;
    }

    // if (
    //   this.state.selectedOs.status === "andamento" &&
    //   this.state.selectedStatus === "aberto"
    // ) {
    //   this.setState({
    //     swal: {
    //       show: true,
    //       title: "Atenção",
    //       text: "Não é permitido alterar o status da O.S de 'andamento' para 'aberto'.",
    //       type: "warning",
    //     },
    //     isSuccess: false,
    //   });
    //   return false;
    // }
    // console.log(this.props.location.state.selectedOs)
    // console.log(this.state.selectedOs)
    // console.log(this.state.selectedAgent,this.state.selectedOs.agente?.id)

    if (
      this.state.selectedOs.status === "andamento" &&
      this.state.selectedStatus === "aberto" &&
      this.state.selectedAgent !== this.state.selectedOs.agente?.id
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Para alterar o status da O.S de 'andamento' para 'aberto' é preciso manter o Agente.",
          type: "warning",
        },
        isSuccess: false,
      });
      return false;
    }

    if (
      this.state.selectedOs.status === "fechado" &&
      this.state.selectedStatus === "fechado"
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Não é permitido alterar o O.S sem alterar o Status.",
          type: "warning",
        },
        isSuccess: false,
      });
      return false;
    }

    if (
      this.state.selectedOs.status === "fechado" &&
      (this.state.selectedStatus === "andamento" ||
        this.state.selectedStatus === "aberto") &&
      this.state.obs === ""
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Para reabrir uma O.S informe a justificativa no campo Observações.",
          type: "warning",
        },
        isSuccess: false,
      });
      return false;
    }

    // if (
    //   this.state.selectedOs.status === "aberto" &&
    //   this.state.selectedStatus === "fechado" &&
    //   this.state.obs === ""
    // ) {
    //   this.setState({
    //     swal: {
    //       show: true,
    //       title: "Atenção",
    //       text: "Preencha o campo Observações.",
    //       type: "warning",
    //     },
    //     isSuccess: false,
    //   });
    //   return false;
    // }

    if (
      this.state.selectedStatus === "aberto" &&
      this.state.selectedOs.status === "aberto"
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Altere o Status da O.S para prosseguir.",
          type: "warning",
        },
        isSuccess: false,
      });
      return false;
    }

    if (
      this.state.selectedStatus === "andamento" &&
      (this.state.selectedAgent === null || this.state.selectedAgent === 0)
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Atribua um agente na O.S para prosseguir.",
          type: "warning",
        },
        isSuccess: false,
      });
      return false;
    }
    //fechado -> aberto
    // if (
    //   this.state.selectedStatus === "aberto" &&
    //   this.state.selectedOs.status === "fechado" &&
    //   this.state.obs === ""
    // ) {
    //   this.setState({
    //     swal: {
    //       show: true,
    //       title: "Atenção",
    //       text: "Altere o Status da O.S para prosseguir.",
    //       type: "warning",
    //     },
    //     isSuccess: false,
    //   });
    //   return false;
    // }
    //fechado -> aberto
    // if (
    //   this.state.selectedStatus === "aberto" &&
    //   this.state.selectedOs.status === "fechado" &&
    //   this.state.obs === ""
    // ) {
    //   this.setState({
    //     swal: {
    //       show: true,
    //       title: "Atenção",
    //       text: "Altere o Status da O.S para prosseguir.",
    //       type: "warning",
    //     },
    //     isSuccess: false,
    //   });
    //   return false;
    // }

    this.setState({ requesting: true });
    // this.calteste();
    this.callSave();
  }
  // calteste = async () => {
  //   console.log("teste");
  //   const response = await fetch(api.baseURL + "/itinerario", {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: localStorage.getItem("agToken"),
  //     },
  //   });

  //   console.log(
  //     "🚀 ~ file: TicketEdit.js:674 ~ calteste= ~ response:",
  //     response
  //   );
  // };

  callSave = async () => {
    console.log(this.state.selectedStatus, "status");
    const response = await fetch(
      api.baseURL + "/os/" + this.state.selectedOs.id,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("agToken"),
        },
        body: JSON.stringify({
          status: this.state.selectedStatus,
          id_agente:
            this.state.selectedStatus === "andamento" || this.state.selectedStatus === "aberto" ||  this.state.selectedStatus === "fechado"
              ? this.state.selectedAgent
              : 0,
          os_legada: this.state.os_legada,
          obs: this.state.obs,
          //obs: " ",
          complemento:
            this.state.complemento !== null &&
            this.state.complemento !== undefined
              ? this.state.complemento
              : "",
          bairro: this.state.bairro,
          numero: this.state.numero,
          logradouro: this.state.logradouro.trim(),
          descricao:
            this.state.descricao !== null && this.state.descricao !== undefined
              ? this.state.descricao
              : "",
          quarteirao:
            this.state.quarteirao !== null &&
            this.state.quarteirao !== undefined
              ? this.state.quarteirao
              : 0,
          lado:
            this.state.lado !== null && this.state.lado !== undefined
              ? this.state.lado
              : "",
          dt_iac_visita: this.state.dt_iac_visita &&
            this.state.dt_iac_visita !== "" &&
            this.state.dt_iac_visita !== "Invalid Date"
          ? moment(this.state.dt_iac_visita).format("YYYY-MM-DDTHH:mm")
          : "",
          ds_iac_removido_solucionado:
            this.state.ds_iac_removido_solucionado !== null && this.state.ds_iac_removido_solucionado !== undefined
              ? this.state.ds_iac_removido_solucionado
              : "",
          ds_iac_descaracterizado:
            this.state.ds_iac_descaracterizado !== null && this.state.ds_iac_descaracterizado !== undefined
              ? this.state.ds_iac_descaracterizado
              : "",
          ds_iac_tratado:
            this.state.ds_iac_tratado !== null && this.state.ds_iac_tratado !== undefined
              ? this.state.ds_iac_tratado
              : "",
          ds_iac_nao_tratado_morador:
            this.state.ds_iac_nao_tratado_morador !== null && this.state.ds_iac_nao_tratado_morador !== undefined
              ? this.state.ds_iac_nao_tratado_morador
              : "",
          ds_iac_observacao:
            this.state.ds_iac_observacao !== null && this.state.ds_iac_observacao !== undefined
              ? this.state.ds_iac_observacao
              : "",
          
        }),
      }
    );

    const body = await response.json();
    

    if (response.status === 200) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: "Edição realizada com sucesso!",
          type: "success",
        },
        requesting: false,
        isSuccess: true,
      });
      // localStorage.setItem("gridos", "");
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Erro",
          text: "Erro ao alterar O.S",
          type: "error",
        },
        selectedOs: null,
      });
      //throw Error(body.message);
    }

    return body;
  };

  render() {
    return (
      <>
        <UserHeader />
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });
            if (this.state.isSuccess) {
              this.props.history.push("/admin/ticket",{
                isVariableTrue: true,
              });
               
            }
          }}
        />
        {console.log(this.props.location.state.selectedOs.chats)}
        {/* Page content */}
        <Container className="main-zoo" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        O.S:{" "}
                        {this.state.selectedOs !== undefined &&
                        this.state.selectedOs.id != null
                          ? this.state.selectedOs.id
                          : "Nova O.S"}
                      </h3>
                      <h4
                        style={{ marginTop: "5px", color: "darkGrey" }}
                        className="mb-0"
                      >
                        Tipo:{" "}
                        <strong>
                        {this.state.selectedOs !== undefined
                          ? Util.capitalize(
                              this.state.selectedOs.tipo === "suspeita"
                                ? this.state.selectedOs.tipo
                                : (() => {
                                    const selectedValue = this.state.selectedOs.tipo_de_imovel;
                                    let displayedText = "";

                                    switch (selectedValue) {
                                      case "via_publica":
                                        displayedText = "Via Pública";
                                        break;
                                      case "recolhimento":
                                        displayedText = "Recolhimento";
                                        break;
                                      case "peixes":
                                        displayedText = "Tratamento Biológico";
                                        break;
                                      case "obras":
                                        displayedText = "Canteiros de Obra";
                                        break;
                                      case "imovel_abandonado":
                                        displayedText = "Imóvel Abandonado";
                                        break;
                                      case "imobiliarias":
                                        displayedText = "Imobiliárias";
                                        break;
                                      case "focos":
                                        displayedText = "Focos do Mosquito";
                                        break;
                                      case "piscinas":
                                        displayedText = "Cadastrar Piscina";
                                        break;
                                      case "caixa_dagua":
                                        displayedText = "Caixas d'Água";
                                        break;
                                        case "solicitacao":
                                        displayedText = "Solicitação";
                                        break;
                                      default:
                                        break;
                                    }

                                    return Util.capitalize(displayedText);
                                  })()
                            )
                          : ""}
                      </strong>
                      </h4>
                      <h4
                        style={{ marginTop: "5px", color: "darkGrey" }}
                        className="mb-0"
                      >
                        Agendamento:{" "}
                        <strong>
                          {this.state.selectedOs !== undefined &&
                          this.state.selectedOs.agendamento !== undefined &&
                          this.state.selectedOs.agendamento != null
                            ? moment(
                                moment(this.state.selectedOs.agendamento)
                              ).format("DD/MM/YYYY HH:mm")
                            : "N/A"}
                        </strong>
                      </h4>
                      <h4
                        style={{ marginTop: "5px", color: "darkGrey" }}
                        className="mb-0"
                      >
                        Dt. Abertura:{" "}
                        <strong>
                          {this.state.selectedOs !== undefined &&
                          this.state.selectedOs.data_abertura !== undefined &&
                          this.state.selectedOs.data_abertura != null
                            ? moment(moment(this.state.data_abertura)).format(
                                "DD/MM/YYYY HH:mm"
                              )
                            : ""}
                        </strong>
                      </h4>
                      <h4
                        style={{ marginTop: "5px", color: "darkGrey" }}
                        className="mb-0"
                      >
                        Dt. Fechamento:{" "}
                        <strong>
                          {this.state.selectedOs !== undefined &&
                          this.state.selectedOs.data_fechamento !== undefined &&
                          this.state.selectedOs.data_fechamento != null
                            ? moment(
                                moment(this.state.selectedOs.data_fechamento)
                              ).format("DD/MM/YYYY HH:mm")
                            : "N/A"}
                        </strong>
                      </h4>
                      <h4
                        style={{ marginTop: "5px", color: "darkGrey" }}
                        className="mb-0"
                      >
                        Origem:{" "}
                        <strong>
                          {this.state.selectedOs !== undefined &&
                          this.state.selectedOs.origem !== null
                            ? Util.snakeToPascal(this.state.selectedOs.origem)
                            : "Aplicativo"}
                        </strong>
                      </h4>
                      <h4
                        style={{ marginTop: "5px", color: "darkGrey" }}
                        className="mb-0"
                      >
                        Solicitante:{" "}
                        <strong>
                          {this.state.selectedOs !== undefined &&
                          this.state.selectedOs.solicitante !== null &&
                          this.state.selectedOs.solicitante.nome_solicitante !==
                            null
                            ? Util.snakeToPascal(
                                this.state.selectedOs.solicitante
                                  .nome_solicitante
                              )
                            : "Cidadão"}
                        </strong>
                      </h4>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    {
                     this.state.selectedOs.status !== "fechado" && 
                      localStorage.getItem("perm") !== "agente" && (
                        <Widget
                          title="Chat"
                          subtitle="Deixe uma mensagem para o Cidadão"
                          senderPlaceHolder="Sua mensagem"
                          badge={this.state.chatCount}
                          handleNewUserMessage={this.handleNewUserMessage}
                        />
                      )}
                    {this.state.selectedOs.cidadao.cpf !== undefined && (
                      <div>
                        <h6 className="heading-small text-muted mb-4">
                          Dados do Cidadão
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            {this.state.selectedOs.cidadao.cpf !==
                              undefined && (
                              <Col lg="6">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-cpf"
                                  >
                                    CPF
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-cpf"
                                    placeholder=""
                                    type="text"
                                    value={cpfMask(
                                      this.state.selectedOs.cidadao.cpf
                                    )}
                                    disabled
                                    onChange={(e) =>
                                      this.setState({ cpf: e.target.value })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            )}
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-nome"
                                >
                                  Nome
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-nome"
                                  placeholder=""
                                  type="nome"
                                  disabled
                                  value={this.state.selectedOs.cidadao.nome}
                                  onChange={(e) =>
                                    this.setState({ nome: e.target.value })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-cel"
                                >
                                  Celular
                                </label>
                                <InputMask
                                  mask="(99) 99999-9999"
                                  type="text"
                                  name="cel"
                                  id="cel"
                                  disabled
                                  className="form-control"
                                  value={this.state.selectedOs.cidadao.tel}
                                  onChange={(e) =>
                                    this.setState({ tel: e.target.value })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-dtnascimento"
                                >
                                  Data Nascimento
                                </label>
                                <InputMask
                                  mask="99/99/9999"
                                  type="text"
                                  name="cel"
                                  id="cel"
                                  className="form-control"
                                  disabled
                                  value={moment(
                                    moment(
                                      this.state.selectedOs.cidadao
                                        .data_nascimento
                                    )
                                  ).format("DD/MM/YYYY")}
                                  onChange={(e) =>
                                    this.setState({
                                      data_nascimento: e.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-dtnascimento"
                                >
                                  Status
                                </label>

                                <select
                                  value={this.state.selectedOs.cidadao.status}
                                  disabled
                                  onChange={(e) =>
                                    this.setState({
                                      selectedStatus: e.target.value,
                                    })
                                  }
                                  className="form-control "
                                >
                                  <option value="">Todos</option>
                                  <option value="ativo">Ativo</option>
                                  <option value="suspenso">Inativo</option>
                                </select>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    )}

                    {this.state.selectedOs.procurou_medico && (
                      <div>
                        <hr className="my-4" />
                        {/* Address */}
                        <h6 className="heading-small text-muted mb-4">
                          Atendimento pra outra pessoa
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-nome"
                                >
                                  Nome
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-nome"
                                  placeholder=""
                                  type="nome"
                                  disabled
                                  value={this.state.selectedOs.cidadao.nome}
                                  onChange={(e) =>
                                    this.setState({ nome: e.target.value })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-cel"
                                >
                                  Celular
                                </label>
                                <InputMask
                                  mask="(99) 99999-9999"
                                  type="text"
                                  name="cel"
                                  id="cel"
                                  disabled
                                  className="form-control"
                                  value={this.state.selectedOs.cidadao.tel}
                                  onChange={(e) =>
                                    this.setState({ tel: e.target.value })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-dtnascimento"
                                >
                                  Data Nascimento
                                </label>
                                <InputMask
                                  mask="99/99/9999"
                                  type="text"
                                  name="cel"
                                  id="cel"
                                  className="form-control"
                                  disabled
                                  value={moment(
                                    moment(
                                      this.state.selectedOs.cidadao
                                        .data_nascimento
                                    )
                                  ).format("DD/MM/YYYY")}
                                  onChange={(e) =>
                                    this.setState({
                                      data_nascimento: e.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="8">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-address"
                                >
                                  Logradouro
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-address"
                                  placeholder=""
                                  disabled
                                  type="text"
                                  value={this.state.selectedOs.logradouro}
                                  onChange={(e) =>
                                    this.setState({
                                      logradouro: e.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-city"
                                >
                                  Número
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-number"
                                  placeholder=""
                                  disabled
                                  type="text"
                                  value={
                                    this.state.selectedOs.numero
                                      ? this.state.selectedOs.numero
                                      : "-"
                                  }
                                  onChange={(e) =>
                                    this.setState({ numero: e.target.value })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-country"
                                >
                                  Bairro
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-district"
                                  placeholder=""
                                  type="text"
                                  disabled
                                  value={
                                    this.state.selectedOs.bairro
                                      ? this.state.selectedOs.bairro
                                      : "-"
                                  }
                                  onChange={(e) =>
                                    this.setState({ bairro: e.target.value })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-complement"
                                >
                                  Complemento
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-complement"
                                  placeholder=""
                                  type="text"
                                  disabled={
                                    this.state.selectedOs.status === "fechado"
                                  }
                                  value={
                                    this.state.complemento
                                      ? this.state.complemento
                                      : "-"
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      complemento: e.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-nome"
                                >
                                  Local Trabalho
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-nome"
                                  placeholder=""
                                  type="nome"
                                  disabled
                                  value={this.state.selectedOs.cidadao.nome}
                                  onChange={(e) =>
                                    this.setState({ nome: e.target.value })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-cel"
                                >
                                  Local Estudo
                                </label>
                                <InputMask
                                  mask="(99) 99999-9999"
                                  type="text"
                                  name="cel"
                                  id="cel"
                                  disabled
                                  className="form-control"
                                  value={this.state.selectedOs.cidadao.tel}
                                  onChange={(e) =>
                                    this.setState({ tel: e.target.value })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    )}

                    {this.state.selectedOs.viagem_inicio !== null &&
                      this.state.selectedOs.viagem_inicio !== undefined && (
                        <div>
                          <hr className="my-4" />
                          {/* Address */}
                          <h6 className="heading-small text-muted mb-4">
                            Viajou nos Últimos dias?
                          </h6>
                          <div className="pl-lg-4">
                            <Row>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-nome"
                                  >
                                    Cidade
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-nome"
                                    placeholder=""
                                    type="nome"
                                    disabled
                                    value={this.state.selectedOs.cidadao.nome}
                                    onChange={(e) =>
                                      this.setState({ nome: e.target.value })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-last-dtnascimento"
                                  >
                                    Data de Ida
                                  </label>
                                  <InputMask
                                    mask="99/99/9999"
                                    type="text"
                                    name="cel"
                                    id="cel"
                                    className="form-control"
                                    disabled
                                    value={moment(
                                      moment(
                                        this.state.selectedOs.cidadao
                                          .data_nascimento
                                      )
                                    ).format("DD/MM/YYYY")}
                                    onChange={(e) =>
                                      this.setState({
                                        data_nascimento: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-last-dtnascimento"
                                  >
                                    Data de Volta
                                  </label>
                                  <InputMask
                                    mask="99/99/9999"
                                    type="text"
                                    name="cel"
                                    id="cel"
                                    className="form-control"
                                    disabled
                                    value={moment(
                                      moment(
                                        this.state.selectedOs.cidadao
                                          .data_nascimento
                                      )
                                    ).format("DD/MM/YYYY")}
                                    onChange={(e) =>
                                      this.setState({
                                        data_nascimento: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      )}
                    {this.state.selectedOs.tipo === "solicitacao" && (
                      <div>
                        <hr className="my-4" />
                        {/* Address */}
                        <h6 className="heading-small text-muted mb-4">
                          Endereço da O.S
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-address"
                                >
                                  Logradouro *
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-address"
                                  disabled={this.state.selectedAddress !== ""}
                                  placeholder=""
                                  type="text"
                                  value={this.state.logradouro}
                                  onChange={(e) =>
                                    this.setState({
                                      logradouro: e.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-bairro"
                                >
                                  Bairro *
                                </label>

                                <Input
                                  className="form-control-alternative"
                                  id="input-lado"
                                  type="text"
                                  maxLength="30"
                                  disabled={this.state.selectedAddress !== ""}
                                  value={this.state.bairro}
                                  onChange={(e) =>
                                    this.setState({ bairro: e.target.value })
                                  }
                                />
                              </FormGroup>
                            </Col>

                            <Col md="2">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-city"
                                >
                                  Número *
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-number"
                                  placeholder=""
                                  type="text"
                                  value={this.state.numero}
                                  onChange={(e) =>
                                    this.setState({ numero: e.target.value })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-complement"
                                >
                                  Complemento
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-complement"
                                  placeholder=""
                                  type="text"
                                  maxLength={20}
                                  value={this.state.complemento}
                                  onChange={(e) =>
                                    this.setState({
                                      complemento: e.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-comp"
                                >
                                  Quarteirão
                                </label>

                                <InputNumber
                                  onlydigits
                                  className="form-control"
                                  id="input-quarteirao"
                                  inputMode="numeric"
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseInt(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 4);
                                  }}
                                  placeholder=""
                                  value={this.state.quarteirao}
                                  onChange={(e) =>
                                    this.setState({
                                      quarteirao: e.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-comp"
                                >
                                  Lado
                                </label>

                                <Input
                                  className="form-control-alternative"
                                  id="input-comp"
                                  type="text"
                                  required
                                  maxLength={4}
                                  value={this.state.lado}
                                  onChange={(e) =>
                                    this.setState({ lado: e.target.value })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md="3">
                              <FormGroup>
                                <div className="text-left mt-4 pt-2">
                                  <Button
                                    className={
                                      this.state.selectedAddress === ""
                                        ? "btn-zoo-alt"
                                        : "btn-zoo"
                                    }
                                    color="primary"
                                    type="button"
                                    onClick={this.handleSiat}
                                  >
                                    {this.state.selectedAddress === ""
                                      ? "Consultar Endereço"
                                      : "Alterar Endereço"}
                                  </Button>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>

                          {this.state.responseAddress &&
                            !this.state.requesting &&
                            this.state.selectedAddress === "" && (
                              <DataTable
                                noHeader={true}
                                pagination={true}
                                striped={true}
                                columns={this.columns}
                                sortable={true}
                                responsive={true}
                                highlightOnHover={true}
                                data={this.state.responseAddress}
                                noDataComponent="Nenhum registro encontrado."
                                paginationComponentOptions={
                                  this.defaultComponentOptions
                                }
                                customStyles={this.customStyles}
                              />
                            )}
                        </div>
                        {this.state.requesting && (
                          <div className="text-center">
                            <Spinner
                              style={{ width: "3rem", height: "3rem" }}
                              color="primary"
                            />
                          </div>
                        )}
                      </div>

                      // <div>
                      //   <hr className="my-4" />
                      //   {/* Address */}
                      //   <h6 className="heading-small text-muted mb-4">
                      //     Informações da O.S
                      //   </h6>
                      //   <div className="pl-lg-4">
                      //     <Row>
                      //       <Col lg="4">
                      //         <FormGroup>
                      //           <label
                      //             className="form-control-label"
                      //             htmlFor="input-address"
                      //           >
                      //             Tipo de Solicitação
                      //           </label>
                      //           <Input
                      //             className="form-control-alternative"
                      //             id="input-address"
                      //             placeholder=""
                      //             disabled
                      //             type="text"
                      //             value={Util.snakeToPascal(
                      //               this.state.selectedOs.tipo_de_imovel
                      //             )}
                      //             onChange={(e) =>
                      //               this.setState({
                      //                 tipo_de_imovel: e.target.value,
                      //               })
                      //             }
                      //           />
                      //         </FormGroup>
                      //       </Col>
                      //       <Col lg="6">
                      //         <FormGroup>
                      //           <label
                      //             className="form-control-label"
                      //             htmlFor="input-address"
                      //           >
                      //             Logradouro
                      //           </label>
                      //           <Input
                      //             className="form-control-alternative"
                      //             id="input-address"
                      //             placeholder=""
                      //             disabled={this.state.selectedOs.status === 'fechado'}
                      //             type="text"
                      //             value={this.state.logradouro}
                      //             onChange={(e) =>
                      //               this.setState({
                      //                 logradouro: e.target.value,
                      //               })
                      //             }
                      //           />
                      //         </FormGroup>
                      //       </Col>
                      //       <Col lg="2">
                      //         <FormGroup>
                      //           <label
                      //             className="form-control-label"
                      //             htmlFor="input-city"
                      //           >
                      //             Número
                      //           </label>
                      //           <Input
                      //             className="form-control-alternative"
                      //             id="input-number"
                      //             placeholder=""
                      //             disabled={this.state.selectedOs.status === 'fechado'}
                      //             type="text"
                      //             value={
                      //               this.state.numero
                      //                 ? this.state.numero
                      //                 : "-"
                      //             }
                      //             onChange={(e) =>
                      //               this.setState({ numero: e.target.value })
                      //             }
                      //           />
                      //         </FormGroup>
                      //       </Col>
                      //     </Row>
                      //     <Row>
                      //       <Col lg="4">
                      //         <FormGroup>
                      //           <label
                      //             className="form-control-label"
                      //             htmlFor="input-bairro"
                      //           >
                      //             Bairro
                      //           </label>
                      //           <Input
                      //             className="form-control-alternative"
                      //             id="input-bairro"
                      //             type="text"
                      //             disabled={this.state.selectedOs.status === 'fechado'}
                      //             value={
                      //               this.state.selectedOs.bairro
                      //                 ? this.state.selectedOs.bairro
                      //                 : "-"
                      //             }
                      //             onChange={(e) =>
                      //               this.setState({ bairro: e.target.value })
                      //             }
                      //           />
                      //         </FormGroup>
                      //       </Col>
                      //       <Col lg="4">
                      //         <FormGroup>
                      //           <label
                      //             className="form-control-label"
                      //             htmlFor="input-complement"
                      //           >
                      //             Complemento
                      //           </label>
                      //           <Input
                      //             className="form-control-alternative"
                      //             id="input-complement"
                      //             placeholder=""
                      //             type="text"
                      //             disabled={this.state.selectedOs.status === 'fechado'}
                      //             value={
                      //               this.state.complemento
                      //                 ? this.state.complemento
                      //                 : "-"
                      //             }
                      //             onChange={(e) =>
                      //               this.setState({
                      //                 complemento: e.target.value,
                      //               })
                      //             }
                      //           />
                      //         </FormGroup>
                      //       </Col>

                      //       <Col lg="2">
                      //         <FormGroup>
                      //           <label
                      //             className="form-control-label"
                      //             htmlFor="input-comp"
                      //           >
                      //             Quarteirão
                      //           </label>

                      //           <InputNumber
                      //             onlydigits
                      //             className="form-control"
                      //             id="input-quarteirao"
                      //             disabled={this.state.selectedOs.status === 'fechado'}
                      //             inputMode="numeric"
                      //             onInput={(e) => {
                      //               e.target.value = Math.max(
                      //                 0,
                      //                 parseInt(e.target.value)
                      //               )
                      //                 .toString()
                      //                 .slice(0, 4);
                      //             }}
                      //             placeholder=""
                      //             value={this.state.quarteirao}
                      //             onChange={(e) =>
                      //               this.setState({
                      //                 quarteirao: e.target.value,
                      //               })
                      //             }
                      //           />
                      //         </FormGroup>
                      //       </Col>

                      //       <Col lg="2">
                      //         <FormGroup>
                      //           <label
                      //             className="form-control-label"
                      //             htmlFor="input-comp"
                      //           >
                      //             Lado
                      //           </label>

                      //           <Input
                      //             className="form-control-alternative"
                      //             id="input-comp"
                      //             type="text"
                      //             disabled={this.state.selectedOs.status === 'fechado'}
                      //             required
                      //             maxLength={4}
                      //             value={this.state.lado}
                      //             onChange={(e) =>
                      //               this.setState({ lado: e.target.value })
                      //             }
                      //           />
                      //         </FormGroup>
                      //       </Col>

                      //       <Col lg="12">
                      //         <FormGroup>
                      //           <label
                      //             className="form-control-label"
                      //             htmlFor="input-last-dtnascimento"
                      //           >
                      //             Descrição
                      //           </label>
                      //           <Input
                      //             id="input-conteudo"
                      //             maxLength={1000}
                      //             value={this.state.descricao}
                      //             onChange={(e) =>
                      //               this.setState({
                      //                 descricao: e.target.value,
                      //               })
                      //             }
                      //             rows="6"
                      //             type="textarea"
                      //             className="form-control-alternative"
                      //           />
                      //         </FormGroup>
                      //       </Col>
                      //     </Row>
                      //   </div>
                      // </div>
                    )}

                    {this.state.selectedOs.tipo === "solicitacao" &&
                      this.state.selectedOs.tipo_de_imovel === "pneu" &&
                      this.handleShowPneu() && (
                        <div>
                          <hr className="my-4" />

                          <h6 className="heading-small text-muted mb-4">
                            Recolhimento - Pneus
                          </h6>
                          <div className="pl-lg-4">
                            <Row>
                              <Col lg="2">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-nome"
                                  >
                                    Bicicleta
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="pneu_bicicleta"
                                    placeholder=""
                                    type="nome"
                                    disabled
                                    value={this.state.selectedOs.pneu_bicicleta}
                                    onChange={(e) =>
                                      this.setState({
                                        pneu_bicicleta: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="2">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-nome"
                                  >
                                    Moto
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="pneu_moto"
                                    placeholder=""
                                    type="nome"
                                    disabled
                                    value={this.state.selectedOs.pneu_moto}
                                    onChange={(e) =>
                                      this.setState({
                                        pneu_moto: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="2">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-nome"
                                  >
                                    Carro
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-carro"
                                    placeholder=""
                                    type="carro"
                                    disabled
                                    value={this.state.selectedOs.pneu_carro}
                                    onChange={(e) =>
                                      this.setState({
                                        pneu_carro: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="3">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-nome"
                                  >
                                    Caminhão
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-nome"
                                    placeholder=""
                                    type="nome"
                                    disabled
                                    value={this.state.selectedOs.pneu_caminhao}
                                    onChange={(e) =>
                                      this.setState({
                                        pneu_caminhao: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="3">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-nome"
                                  >
                                    Caminhonete
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-pneu_camionete"
                                    placeholder=""
                                    type="pneu_camionete"
                                    disabled
                                    value={this.state.selectedOs.pneu_camionete}
                                    onChange={(e) =>
                                      this.setState({
                                        pneu_camionete: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      )}
                    {this.state.selectedOs.tipo === "solicitacao" &&
                      this.state.selectedOs.tipo_de_imovel === "objetos" &&
                      this.handleShowObjeto() && (
                        <div>
                          <hr className="my-4" />
                          {/* Address */}
                          <h6 className="heading-small text-muted mb-4">
                            Recolhimento - Objetos
                          </h6>
                          <div className="pl-lg-4">
                            <Row>
                              <Col lg="2">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-nome"
                                  >
                                    Pratos
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="prato"
                                    placeholder=""
                                    type="prato"
                                    disabled
                                    value={this.state.selectedOs.prato}
                                    onChange={(e) =>
                                      this.setState({ prato: e.target.value })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="2">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-garrafa"
                                  >
                                    Garrafa
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="garrafa"
                                    placeholder=""
                                    type="garrafa"
                                    disabled
                                    value={this.state.selectedOs.garrafa}
                                    onChange={(e) =>
                                      this.setState({ garrafa: e.target.value })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="2">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-nome"
                                  >
                                    Lata
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="lata"
                                    placeholder=""
                                    type="lata"
                                    disabled
                                    value={this.state.selectedOs.lata}
                                    onChange={(e) =>
                                      this.setState({ lata: e.target.value })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="3">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="lona"
                                  >
                                    Lona
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="lona"
                                    placeholder=""
                                    type="lona"
                                    disabled
                                    value={this.state.selectedOs.lona}
                                    onChange={(e) =>
                                      this.setState({ lona: e.target.value })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="3">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="eletro"
                                  >
                                    Eletrodomésticos
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="eletro"
                                    placeholder=""
                                    type="eletro"
                                    disabled
                                    value={this.state.selectedOs.eletro}
                                    onChange={(e) =>
                                      this.setState({ eletro: e.target.value })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      )}
                    {this.state.selectedOs.tipo_de_imovel === "via_publica" && (
                      <div>
                        <hr className="my-4" />
                        <h6 className="heading-small text-muted mb-4">
                          Via Pública
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <div className="custom-control custom-checkbox mb-3">
                                  <input
                                    className="custom-control-input"
                                    checked={
                                      this.state.selectedOs.via_publica_bueiro
                                    }
                                    disabled
                                    id="customCheck4"
                                    type="checkbox"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customCheck4"
                                  >
                                    Bueiro
                                  </label>
                                </div>
                                <div className="custom-control custom-checkbox mb-3">
                                  <input
                                    className="custom-control-input"
                                    checked={
                                      this.state.selectedOs.via_publica_desnivel
                                    }
                                    disabled
                                    id="customCheck4"
                                    type="checkbox"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customCheck4"
                                  >
                                    Desnivel
                                  </label>
                                </div>
                                <div className="custom-control custom-checkbox mb-3">
                                  <input
                                    className="custom-control-input"
                                    checked={
                                      this.state.selectedOs.via_publica_canteiro
                                    }
                                    disabled
                                    id="customCheck4"
                                    type="checkbox"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customCheck4"
                                  >
                                    Canteiro
                                  </label>
                                </div>
                                <div className="custom-control custom-checkbox mb-3">
                                  <input
                                    className="custom-control-input"
                                    checked={
                                      this.state.selectedOs.via_publica_canaleta
                                    }
                                    disabled
                                    id="customCheck4"
                                    type="checkbox"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customCheck4"
                                  >
                                    Canaleta
                                  </label>
                                </div>
                                <div className="custom-control custom-checkbox mb-3">
                                  <input
                                    className="custom-control-input"
                                    checked={
                                      this.state.selectedOs
                                        .via_publica_ponto_de_onibus
                                    }
                                    disabled
                                    id="customCheck4"
                                    type="checkbox"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customCheck4"
                                  >
                                    Ponto de ônibus
                                  </label>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    )}

                    {this.handleShowExame() && (
                      <div>
                        <hr className="my-4" />
                        <h6 className="heading-small text-muted mb-4">
                          Foi feito exame?
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <div className="custom-control custom-checkbox mb-3">
                                  <input
                                    className="custom-control-input"
                                    checked={this.state.selectedOs.exame_dengue}
                                    disabled
                                    id="customCheck4"
                                    type="checkbox"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customCheck4"
                                  >
                                    Dengue
                                  </label>
                                </div>
                                <div className="custom-control custom-checkbox mb-3">
                                  <input
                                    className="custom-control-input"
                                    checked={this.state.selectedOs.exame_zika}
                                    disabled
                                    id="customCheck4"
                                    type="checkbox"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customCheck4"
                                  >
                                    Zica
                                  </label>
                                </div>
                                <div className="custom-control custom-checkbox mb-3">
                                  <input
                                    className="custom-control-input"
                                    checked={
                                      this.state.selectedOs.exame_chicungunha
                                    }
                                    disabled
                                    id="customCheck4"
                                    type="checkbox"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customCheck4"
                                  >
                                    Chikungunya
                                  </label>
                                </div>
                                <div className="custom-control custom-checkbox mb-3">
                                  <input
                                    className="custom-control-input"
                                    checked={
                                      this.state.selectedOs.exame_febre_amarela
                                    }
                                    disabled
                                    id="customCheck4"
                                    type="checkbox"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customCheck4"
                                  >
                                    Febre Amarela
                                  </label>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                        <hr className="my-4" />
                      </div>
                    )}

                    {this.state.selectedOs.vistoria &&
                      this.state.selectedOs.tipo_de_imovel !==
                        "via_publica" && (
                        <div>
                          <hr className="my-4" />
                          <h6 className="heading-small text-muted mb-4">
                            Vistoria
                          </h6>
                          <div className="pl-lg-4">
                            <Row>
                              <Col md="12">
                                <FormGroup>
                                  <div className="custom-control custom-checkbox mb-3">
                                    <input
                                      className="custom-control-input"
                                      checked={this.state.selectedOs.vistoria}
                                      disabled
                                      id="customCheck4"
                                      type="checkbox"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="customCheck4"
                                    >
                                      Realizada
                                    </label>
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      )}

                    {this.state.selectedOs.avaliacao !== null &&
                      this.state.selectedOs.avaliacao !== undefined && (
                        <div>
                          <hr className="my-4" />
                          <h6 className="heading-small text-muted mb-4">
                            Avaliação
                          </h6>
                          <div className="pl-lg-4">
                            <Row>
                              <Col md="12">
                                <FormGroup>
                                  <div className="custom-control text-left">
                                    <BeautyStars
                                      value={
                                        this.state.selectedOs.avaliacao.nota
                                      }
                                      size={"30px"}
                                      maxStars={5}
                                      inactiveColor="Black"
                                      activeColor="#fdbe37"
                                      editable="false"
                                    />
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      )}

                    {this.state.tipo === "solicitacao" && (
                      <div>
                        <hr className="my-4" />
                        {/* Address */}
                        <h6 className="heading-small text-muted mb-4">
                          Informações da O.S
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-address"
                                >
                                  Tipo de Solicitação *
                                </label>

                                <select
                                  value={this.state.selectedOs.tipo_de_imovel}
                                  disabled
                                  onChange={(e) =>
                                    this.setState({
                                      tipo_de_imovel: e.target.value,
                                    })
                                  }
                                  className="form-control "
                                  id="select-tipo"
                                >
                                  <option defaultValue="" value="">
                                    {" "}
                                    Selecione...{" "}
                                  </option>
                                  <option value="caixa_dagua">
                                    Caixas d'água
                                  </option>
                                  <option value="piscinas">
                                    Cadastrar Piscina
                                  </option>
                                  <option value="focos">Focos do Mosquito</option>
                                  <option value="imobiliarias">
                                    Imobiliárias
                                  </option>
                                  <option value="imovel_abandonado">
                                    Imóvel abandonado
                                  </option>
                                  <option value="obras">Canteiros de Obra</option>
                                  <option value="peixes">Tratamento Biológico</option>
                                  <option value="recolhimento">
                                    Recolhimento
                                  </option>
                                  <option value="via_publica">
                                    Via Publica
                                  </option>
                                </select>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-dtnascimento"
                                >
                                  Descrição
                                </label>
                                <Input
                                  id="input-conteudo"
                                  maxLength={1000}
                                  value={this.state.descricao}
                                  onChange={(e) =>
                                    this.setState({
                                      descricao: e.target.value,
                                    })
                                  }
                                  rows="6"
                                  type="textarea"
                                  className="form-control-alternative"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    )}

                    {this.state.tipo === "solicitacao" &&
                    this.state.selectedOs.origem === "drones" && (
                      <div>
                        <hr className="my-4" />
                        {/* Address */}
                        <h6 className="heading-small text-muted mb-4">
                          Descrição POI
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                          <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-numero"
                            >
                              Código do ponto
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-numero"
                              type="text"
                              disabled
                              value={this.state.ds_poi}
                              onChange={(e) =>
                                this.setState({ ds_poi: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="5">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-numero"
                            >
                              Descrição volume estimado
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-numero"
                              type="text"
                              disabled
                              value={this.state.ds_volume_estimado_litros}
                              onChange={(e) =>
                                this.setState({ ds_volume_estimado_litros: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="5">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-numero"
                            >
                              Descrição das unidades de pastilhas
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-numero"
                              type="text"
                              disabled
                              value={this.state.ds_pastilhas_naturalar_dt}
                              onChange={(e) =>
                                this.setState({ ds_pastilhas_naturalar_dt: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                          </Row>
                          <Row>
                            <Col lg="5">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-numero"
                            >
                              Descrição da quantidade de gramas de utilizacão
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-numero"
                              type="text"
                              disabled
                              value={this.state.ds_granulado_bti}
                              onChange={(e) =>
                                this.setState({ ds_granulado_bti: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                          </Row>
                        </div>
                      </div>
                    )}

                     {this.state.tipo === "solicitacao" &&
                    this.state.selectedOs.origem === "drones" && (
                      <div>
                        <hr className="my-4" />
                        {/* Address */}
                        <h6 className="heading-small text-muted mb-4">
                          Informações atividade de campo
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                          <Col lg="2">
                                <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-numero"
                                >
                                 Data da Visita
                                </label>
                                <br></br>
                                
                                  <DatePicker
                                    showTimeSelect
                                    locale={pt}
                                    timeCaption="Hora"
                                    className="form-control"
                                    selected={this.state.dt_iac_visita}
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    style={{ width: "100%" }}
                                    onChange={(date) =>
                                      this.setState({
                                        dt_iac_visita: date,
                                      })
                                    }
                                  />
                                
                                </FormGroup>
                              </Col>
                        <Col lg="5">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-numero"
                            >
                              Foi removido ou solucionado?
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-numero"
                              type="text"
                              
                              value={this.state.ds_iac_removido_solucionado}
                              onChange={(e) =>
                                this.setState({ ds_iac_removido_solucionado: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="5">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-numero"
                            >
                              Foi descaracterizado?
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-numero"
                              type="text"
                              
                              value={this.state.ds_iac_descaracterizado}
                              onChange={(e) =>
                                this.setState({ ds_iac_descaracterizado: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                          </Row>
                          <Row>
                            {/* <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-numero"
                            >
                              O POI foi tratado ?
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-numero"
                              type="text"
                              
                              value={this.state.ds_iac_tratado}
                              onChange={(e) =>
                                this.setState({ ds_iac_tratado: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col> */}
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-numero"
                            >
                              O POI foi tratado ?
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-numero"
                              type="text"
                              
                              value={this.state.ds_iac_tratado}
                              onChange={(e) =>
                                this.setState({ ds_iac_tratado: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-numero"
                            >
                              Se o POI não foi tratado - morador
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-numero"
                              type="text"
                              
                              value={this.state.ds_iac_nao_tratado_morador}
                              onChange={(e) =>
                                this.setState({ ds_iac_nao_tratado_morador: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>

                          </Row>
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-dtnascimento"
                                >
                                  Observação relativa ao POI e sua atividade
                                </label>
                                <Input
                                  id="exampleFormControlTextarea1"
                                  placeholder=""
                                  maxLength={1000}
                                  value={this.state.ds_iac_observacao}
                                  onChange={(e) =>
                                    this.setState({
                                      ds_iac_observacao: e.target.value,
                                    })
                                  }
                                  rows="3"
                                  type="textarea"
                                  className="form-control-alternative"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    )}


                    {this.state.selectedOs.media !== null &&
                      this.state.selectedOs.media.foto1 != null && (
                        <div>
                          <hr className="my-4" />
                          <hr className="my-4" />
                          <h6 className="heading-small text-muted mb-4">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                this.setState({
                                  showFoto: !this.state.showFoto,
                                })
                              }
                            >
                              Fotos
                            </a>
                          </h6>
                          <div className="pl-lg-4">
                            <Collapse isOpen={this.state.showFoto}>
                              <Row>
                                {this.state.selectedOs.media.foto1 !== null && (
                                  <Col lg="4">
                                    <FormGroup>
                                      <img
                                        src={this.state.selectedOs.media.foto1}
                                        class="img-fluid"
                                        alt="Responsive"
                                      />
                                    </FormGroup>
                                  </Col>
                                )}
                                {this.state.selectedOs.media.foto2 !== null && (
                                  <Col lg="4">
                                    <FormGroup>
                                      <img
                                        src={this.state.selectedOs.media.foto2}
                                        class="img-fluid"
                                        alt="Responsive"
                                      />
                                    </FormGroup>
                                  </Col>
                                )}
                                {this.state.selectedOs.media.foto3 !== null && (
                                  <Col lg="4">
                                    <FormGroup>
                                      <img
                                        src={this.state.selectedOs.media.foto3}
                                        class="img-fluid"
                                        alt="Responsive"
                                      />
                                    </FormGroup>
                                  </Col>
                                )}
                                {this.state.selectedOs.media.foto4 !== null && (
                                  <Col lg="4">
                                    <FormGroup>
                                      <img
                                        src={this.state.selectedOs.media.foto4}
                                        class="img-fluid"
                                        alt="Responsive"
                                      />
                                    </FormGroup>
                                  </Col>
                                )}
                                {this.state.selectedOs.media.foto5 !== null && (
                                  <Col lg="4">
                                    <FormGroup>
                                      <img
                                        src={this.state.selectedOs.media.foto5}
                                        class="img-fluid"
                                        alt="Responsive"
                                      />
                                    </FormGroup>
                                  </Col>
                                )}
                              </Row>
                            </Collapse>
                          </div>
                        </div>
                      )}

                    {this.state.selectedOs.media !== null &&
                      this.state.selectedOs.media.video !== null && (
                        <div>
                          <hr className="my-4" />

                          <h6 className="heading-small text-muted mb-4">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                this.setState({
                                  showVideo: !this.state.showVideo,
                                })
                              }
                            >
                              Vídeo
                            </a>
                          </h6>
                          <div className="pl-lg-4">
                            <Collapse isOpen={this.state.showVideo}>
                              <Row>
                                {this.state.selectedOs.media.video !== "" && (
                                  <Col lg="12">
                                    <FormGroup>
                                      <div>
                                        <Video
                                          style={{ height: "400px" }}
                                          loop
                                          muted={true}
                                          controls={[
                                            "PlayPause",
                                            "Seek",
                                            "Time",
                                            "Volume",
                                            "Fullscreen",
                                          ]}
                                          onCanPlayThrough={() => {
                                            // Do stuff
                                          }}
                                        >
                                          <source
                                            src={
                                              this.state.selectedOs.media.video
                                            }
                                            type="video/webm"
                                          />
                                        </Video>
                                      </div>
                                    </FormGroup>
                                  </Col>
                                )}
                              </Row>
                            </Collapse>
                          </div>
                        </div>
                      )}

                    {this.state.selectedOs.media !== null &&
                      this.state.selectedOs.media.audio !== null && (
                        <div>
                          <hr className="my-4" />

                          <h6 className="heading-small text-muted mb-4">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                this.setState({
                                  showAudio: !this.state.showAudio,
                                })
                              }
                            >
                              Audio
                            </a>
                          </h6>
                          <div className="pl-lg-4">
                            <Collapse isOpen={this.state.showAudio}>
                              <Row>
                                <Col lg="12">
                                  <FormGroup>
                                    <ReactAudioPlayer
                                      src={this.state.selectedOs.media.audio}
                                      controls
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Collapse>
                          </div>
                        </div>
                      )}

                    {localStorage.getItem("perm") !== "agente" && (
                      <div>
                        <hr className="my-4" />

                        <h6 className="heading-small text-muted mb-4">Ações</h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-dtnascimento"
                                >
                                  Mudar Status
                                </label>

                                <select
                                  value={this.state.selectedStatus}
                                  onChange={(e) =>
                                    this.setState({
                                      selectedStatus: e.target.value,
                                    })
                                  }
                                  className="form-control "
                                >
                                  <option value="aberto">Aberto</option>
                                  <option value="andamento">Andamento</option>
                                  <option value="fechado">Fechado</option>
                                  {/* <option value="morador_aus">Morador Ausente</option>
                                  <option value="recusa">Recusa</option>
                                  <option value="vistoria_r">Vistoria Realizada</option> */}
                                </select>
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                {this.state.agents.length > 0 && (
                                  <div>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-last-dtnascimento"
                                    >
                                      Atribuir Agente
                                    </label>

                                    <select
                                      value={this.state.selectedAgent}
                                      onChange={(e) =>
                                        this.setState({
                                          selectedAgent: e.target.value,
                                        })
                                      }
                                      className="form-control "
                                    >
                                      {this.state.selectedAgent === 0 && (
                                        <option defaultValue="" value="">
                                          {" "}
                                          Selecione...{" "}
                                        </option>
                                      )}
                                      {this.state.agents.map((team) => (
                                        <option
                                          key={team.id}
                                          value={team.id}
                                          defaultValue={
                                            this.state.selectedAgent
                                          }
                                        >
                                          {team.nome}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                            {/* <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-nome"
                                >
                                  O.S Interna
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-nome"
                                  placeholder=""
                                  type="osinterna"
                                  disabled
                                  value={this.state.selectedOs.os_legada}
                                  onChange={(e) =>
                                    this.setState({ os_legada: e.target.value })
                                  }
                                />
                              </FormGroup>
                            </Col> */}
                          </Row>
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-dtnascimento"
                                >
                                  Observações
                                </label>
                                <Input
                                  id="exampleFormControlTextarea1"
                                  placeholder=""
                                  maxLength={1000}
                                  value={this.state.obs}
                                  onChange={(e) =>
                                    this.setState({
                                      obs: e.target.value,
                                    })
                                  }
                                  rows="3"
                                  type="textarea"
                                  className="form-control-alternative"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    )}
                  {this.state.requesting && (
                    <div className="text-center">
                      <Spinner
                        style={{ width: "3rem", height: "3rem" }}
                        color="primary"
                      />
                    </div>
                  )}

                    {localStorage.getItem("perm") !== "agente" && localStorage.getItem("perm") !== "Operador" &&
                      !this.state.requesting && (
                        <div className="text-center">
                          <Button
                            className="mt-4 btn-zoo"
                            color="primary"
                            type="button"
                            onClick={this.handleSave}
                          >
                            Salvar Alterações
                          </Button>
                        </div>
                      )}
                  </Form>


           {this.state.response2 && (
           <CardHeader className="bg-white border-0" style={{ marginTop: "4%"}}>
           <Row>
           <div className="table-responsive">
             <h3>Histórico de Movimentação: </h3>
 
             <table className="table align-middle table-nowrap mb-0">
               <tbody>
                 <tr>
                   <td>Data de Envio</td>
                   
                   <td></td>
                   <td>Servidor</td>
                 </tr>
                 
                 {this.state.response2.length &&
                   this.state.response2?.map((acoes,key) => (
                    <tr key={"_tr_" + key}>
                       <td>
                         {acoes.dt_criacao !== null
                           ? new Date(acoes.dt_criacao).toLocaleString('pt-BR', {
                            timeZone: 'America/Sao_Paulo',
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false
                          })
                           : ""}
                       </td>
                       
                       <td>
                         {acoes.tp_status ? (
                           <span className="text-muted" style={{ padding:"0 0 0 10px"}}>
                             Status atualizado: {acoes.tp_status}
                           </span>
                         ) : (
                           <></>
                         )}
                         {acoes.agente? (
                           <span className="text-muted" style={{ padding:"0 0 0 10px"}}>
                             Agente: {acoes.agente}
                           </span>
                         ) : (
                           <></>
                         )}
                         
                         
                       </td>
                       <td>

                       {acoes.usuario  ? (
                            <span className="text-muted" style={{ padding:"0 0 0 10px"}}>
                             Usuario: {acoes.usuario}
                           </span>
                         ) : (
                           ""
                         )}

                       </td>
                       
                     </tr>
                   ))}
               </tbody>
             </table>
           </div>
         </Row>
         </CardHeader>
          )}
                </CardBody>
              </Card>
            </Col>
          </Row>
         
         
        </Container>
      </>
    );
  }
}

export default TicketEdit;
